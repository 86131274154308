import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';

import 'chartjs-chart-geo';
import { AuthService } from './modules/auth/services';

@Component({
  selector: 'app-root',
  template: `
    <ngx-spinner [fullScreen]="true" bdColor="rgba(255,255,255,0.8)" size="medium" color="#000000" size="medium">
    </ngx-spinner>
    <router-outlet></router-outlet>
  `,
})
export class AppComponent {
  private idleTimeInSecs = 30 * 60; // 30 minutes

  constructor(private idle: Idle, private router: Router, private authService: AuthService) {
    this.setupIdle();
  }

  setupIdle(): void {
    this.idle.setIdle(this.idleTimeInSecs);
    this.idle.setTimeout(1);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.idle.onTimeout.subscribe(() => {
      const user = this.authService.getCurrentUser();

      if (user) {
        this.authService.logout();
        this.router.navigate(['/auth/login']);
      }

      this.idle.watch();
    });
    // this.idle.onIdleStart.subscribe(() => console.log('AppComponent :: onIdleStart: Gone idle'));
    // this.idle.onIdleEnd.subscribe(() => console.log('AppComponent :: onIdleEnd: No longer idle.'));
    // this.idle.onTimeoutWarning.subscribe((countdown) =>
    //   console.log(`AppComponent :: onTimeoutWarning: You will time out in ' + ${countdown} + ' seconds!`),
    // );

    this.idle.watch();
  }
}
