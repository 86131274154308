/* eslint-disable no-unused-vars */
import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';

import { HttpClient } from '@angular/common/http';

import { map } from 'rxjs/operators';
import {
  Account,
  AccountDTO,
  PlanSpendingPlan,
  ScheduledTxDTO,
  SpendingPlanTypes,
  SplitdTransaction,
  SplitdTransactionDTO,
  Transaction,
  TransactionDTO,
} from '../models';
import { SCHEDULED_TRANSACTIONS_ALL, TRANSACTIONS_ALL } from '../mocks';
import { TransactionFilterI, TransactionResponseI } from '../interfaces/transaction.interfaces';
import { InfoResponseAPI } from '../interfaces';
import { PromiseQueue, QueueErrorPromise } from '../utils';
import { CompeltedPromise, PayloadPromise } from '../utils/PromiseQueue';
import { AccountService } from './account.service';

export interface CreateFileTransactionsI {
  message: string;
  total: number;
  transactionsDuplicated: Transaction[];
  transactionsInvalid: Transaction[];
  transactionsOk: Transaction[];
}

interface TransactionsFiltersI {
  dateFrom?: string;
  dateTo?: string;
  offset?: number;
  limit?: number;
  amountMax?: number;
  amountMin?: number;
  payee?: string;
  memo?: string;
  sortKey?: string;
  sortDir?: string;
  draftState?: string;
  count?: boolean;
  text?: string;
}

interface RequestDTOI {
  amount: number;
  categoryId: string;
  schedInitDate: string;
  schedDayOffset?: number;
  schedMonthOffset?: number;
  schedMonthDays?: number[];
  type?: string;
  payee?: string;
  memo?: string;
  action?: string;
  splittedTx?: SplitdTransactionDTO[];
  linkedBankAcct?: string;
  linkedCategoryId?: string;
  linkedPayee?: string;
  linkedMemo?: string;
  linkedAction?: string;
  linkedType?: string;
  approve?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class TransactionService {
  private readonly BANK_ACCTS_LIMIT = 25;
  private readonly apiUrl: string = environment.apiUrl;

  constructor(private http: HttpClient, private accountService: AccountService) {}

  private buildQuery(
    dateFrom?: string,
    dateTo?: string,
    offset?: number,
    limit?: number,
    amountMax?: number,
    amountMin?: number,
    payee?: string,
    memo?: string,
    sortKey?: string,
    sortDir?: string,
    draftState?: string,
    count?: boolean,
    text?: string,
  ): string {
    let url = '?';

    if (dateFrom && dateTo) {
      url += `dF=${dateFrom.toString()}&dT=${dateTo.toString()}`;
    }
    if (offset) {
      url += `&off=${offset.toString()}`;
    }
    if (limit) {
      url += `&lim=${limit.toString()}`;
    }
    if (amountMax) {
      url += `&aMax=${amountMax.toString()}`;
    }
    if (amountMin) {
      url += `&aMin=${amountMin.toString()}`;
    }
    if (payee) {
      url += `&pS=${payee.toString()}`;
    }
    if (memo) {
      url += `&mS=${memo.toString()}`;
    }
    if (sortKey) {
      url += `&sK=${sortKey.toString()}`;
    }
    if (sortDir) {
      url += `&sD=${sortDir.toString()}`;
    }
    if (draftState) {
      url += `&dS=${draftState.toString()}`;
    }
    if (count) {
      url += `&c=1`;
    }
    if (text) {
      url += `&tS=${text}`;
    }

    return url;
  }

  async getTransactions(accountId: string, filters?: TransactionsFiltersI): Promise<TransactionResponseI> {
    let transactions: Array<Transaction> = [];
    let info: InfoResponseAPI;
    if (environment.useMocks) {
      transactions = new Transaction().deserializeArray(TRANSACTIONS_ALL.transactions);
    } else {
      // let url = `${this.apiUrl}/client/accounting/me/transactions`;
      let url = `${this.apiUrl}/client/accounting/me/bankAccts/${accountId}/transactions`;

      if (filters) {
        url += this.buildQuery(
          filters.dateFrom,
          filters.dateTo,
          filters.offset,
          filters.limit,
          filters.amountMax,
          filters.amountMin,
          filters.payee,
          filters.memo,
          filters.sortKey,
          filters.sortDir,
          filters.draftState,
          filters.count,
          filters.text,
        );
      }

      const resp = await this.http.get<{ transactions: Array<TransactionDTO>; total: number }>(url).toPromise();

      transactions = new Transaction().deserializeArray(resp.transactions);
      info = { count: resp.total, offset: '', limit: '' };
    }
    return { transactions, info };
  }

  async getTransactionsByAccountsAndDate(
    bankAccts: string[],
    from: { day?: number; month?: number; year?: number },
    to: { day?: number; month?: number; year?: number },
  ): Promise<Array<Transaction>> {
    interface RequestDTO {
      bankAccts: string[];
      fromYear?: number;
      fromMonth?: number;
      fromDay?: number;
      toYear?: number;
      toMonth?: number;
      toDay?: number;
    }
    let transactions: Array<Transaction> = [];
    const transactionsAccepted: Array<Transaction> = [];
    if (environment.useMocks) {
      transactions = new Transaction().deserializeArray(TRANSACTIONS_ALL.transactions);
    } else {
      const url = `${this.apiUrl}/client/accounting/me/transactions/getFromDateRange`;
      const calls = Math.ceil(bankAccts.length / this.BANK_ACCTS_LIMIT);
      const newArray = [];
      for (let i = 0; i < calls; i += 1) {
        newArray.push(bankAccts.slice(i * this.BANK_ACCTS_LIMIT, (i + 1) * this.BANK_ACCTS_LIMIT));
      }
      await Promise.all(
        newArray.map(async (arrayAccts) => {
          const body: RequestDTO = {
            bankAccts: arrayAccts,
            fromYear: from.year,
            fromMonth: from.month,
            fromDay: from.day,
            toYear: to.year,
            toMonth: to.month,
            toDay: to.day,
          };
          const response = await this.http.post<{ transactions: Array<TransactionDTO> }>(url, body).toPromise();
          transactions.push(...new Transaction().deserializeArray(response.transactions));
        }),
      );
      transactions.forEach((transaction: Transaction) => {
        if (transaction.isAccepted) {
          transactionsAccepted.push(transaction);
        }
      });
    }
    return transactionsAccepted;
  }

  async getTransactionsByYear(accountsIds: string[], year: number): Promise<Array<Transaction>> {
    let transactionsSpendingPlan: Array<Transaction> = [];
    try {
      transactionsSpendingPlan = await this.getTransactionsByAccountsAndDate(accountsIds, { year }, { year: year + 1 });
    } catch (error) {
      // console.error(error);
    }
    return transactionsSpendingPlan;
  }

  async getTransactionsSpendingPlan(accountsIds: string[], plan: PlanSpendingPlan): Promise<Array<Transaction>> {
    let transactionsSpendingPlan: Array<Transaction> = [];
    if (accountsIds.length === 0) {
      return transactionsSpendingPlan;
    }
    let from: { day?: number; month?: number; year?: number };
    let to: { day?: number; month?: number; year?: number };
    if (plan.type === SpendingPlanTypes.ANNUAL) {
      from = {
        year: plan.yearValue,
      };
      to = {
        year: plan.yearValue + 1,
      };
    } else {
      const month = plan.monthlyValue ? plan.monthlyValue - 1 : 0;
      from = {
        year: plan.yearValue,
        month,
      };
      to = {
        year: plan.yearValue,
        month: month + 1,
      };
    }
    try {
      transactionsSpendingPlan = await this.getTransactionsByAccountsAndDate(accountsIds, from, to);
    } catch (error) {
      // console.error(error);
    }
    return transactionsSpendingPlan;
  }

  async disapproveTransaction(idTransaction: string): Promise<void> {
    const url = `${this.apiUrl}/client/accounting/me/transactions/${idTransaction}/disapprove/`;
    await this.http.post<{ bankAcct: AccountDTO }>(url, {}).toPromise();
  }

  async approveTransaction(idTransaction: string): Promise<void> {
    const url = `${this.apiUrl}/client/accounting/me/transactions/${idTransaction}/approve/`;
    await this.http.post<{ bankAcct: AccountDTO }>(url, {}).toPromise();
  }

  async approveTransactions(transactions: Array<Transaction>): Promise<Array<CompeltedPromise>> {
    const approvePromises: Array<PayloadPromise> = transactions.map((transaction: Transaction) => {
      return { promise: this.approveTransaction.bind(this, transaction.id), payload: transaction };
    });

    const queue = new PromiseQueue(approvePromises, 1);

    queue.run();

    const promisesResolved: Array<CompeltedPromise> = await queue.onComplete
      .pipe(
        map((result) => {
          const { completedPromises } = result;
          return completedPromises;
        }),
      )
      .toPromise();

    return promisesResolved;
  }

  async linkTransactions(transOrgId: string, transDstId: string): Promise<void> {
    const url = `${this.apiUrl}/client/accounting/me/transactions/link/`;
    await this.http
      .post<void>(url, { transOrgId, transDstId })
      .toPromise();
  }

  async linkSplitTransactions(transOrgId: string, transDstId: string): Promise<void> {
    const url = `${this.apiUrl}/client/accounting/me/transactions/splitted/link/`;
    await this.http
      .post<void>(url, { transOrgId, transDstId })
      .toPromise();
  }

  rejectTransaction(transaction: Transaction): Promise<void> {
    return this.deleteTransaction(transaction.id);
  }

  rejectTransactions(transactionsIds: Array<Transaction>): void {
    if (environment.useMocks) {
      // eslint-disable-next-line no-console
      console.log('TransactionService -> rejectTransactions -> TranactionsIDS ', transactionsIds);
    }
  }

  async matchTransactions(trans1: string, trans2: string): Promise<void> {
    const url = `${this.apiUrl}/client/accounting/me/transactions/match/`;
    await this.http
      .post<void>(url, { trans1, trans2 })
      .toPromise();
  }

  unMatchTransactions(transactionsIds: Array<Transaction>): void {
    if (environment.useMocks) {
      // eslint-disable-next-line no-console
      console.log('TransactionService -> unMatchTransactions -> TranactionsIDS ', transactionsIds);
    }
  }

  async deleteTransactions(
    transactions: Array<Transaction>,
    isSched: boolean = false,
  ): Promise<Array<QueueErrorPromise>> {
    const deletePromises: Array<PayloadPromise> = transactions.map((transaction) => {
      return {
        promise: this.deleteTransaction.bind(this, transaction.id, transaction.accountId, isSched),
        payload: transaction,
      };
    });

    const queue = new PromiseQueue(deletePromises, 1);

    queue.run();

    const { errors } = await queue.onComplete.toPromise();

    return errors;
  }

  async deleteTransaction(transactionId: string, accountId?: string, isSched?: boolean): Promise<void> {
    if (!environment.useMocks) {
      let url = `${this.apiUrl}/client/accounting/me/`;
      if (isSched) {
        url += `bankAccts/${accountId}/scheduledTx/${transactionId}/`;
      } else {
        url += `transactions/${transactionId}/`;
      }
      await this.http.delete<{ bankAcct: AccountDTO }>(url).toPromise();
    }
  }

  async moveTransactionsToAccount(transactions: Array<Transaction>, account: Account): Promise<void> {
    if (environment.useMocks) {
      // eslint-disable-next-line no-console
      console.log('TransactionService -> moveTransactionsToAccount -> TranactionsIDS + Account', transactions, account);
    } else {
      await Promise.all(
        transactions.map(async (transaction) => {
          try {
            await this.createNewTransaction(account.id, transaction);
            await this.deleteTransaction(transaction.id);
          } catch (error) {
            // eslint-disable-next-line no-console
            console.log(
              'ERROR -> TransactionService -> moveTransactionsToAccount -> TranactionsIDS + Account',
              transactions,
              account,
            );
          }
        }),
      );
    }
  }

  async updateTransaction(transaction: Transaction): Promise<Transaction> {
    const url = `${this.apiUrl}/client/accounting/me/transactions/${transaction.id}/`;
    const { transaction: transactionApi } = await this.http
      .put<{ transaction: TransactionDTO }>(url, transaction.serialize())
      .toPromise();

    return new Transaction().deserialize(transactionApi);
  }

  async createSplitsTransaction(
    transactionId: string,
    splits: SplitdTransaction[],
    totalAmount: number,
  ): Promise<Transaction> {
    return this.modifyTransactionSplits(transactionId, splits, totalAmount);
  }

  async deleteSplitsTransaction(transactionId: string): Promise<Transaction> {
    return this.modifyTransactionSplits(transactionId, []);
  }

  private async modifyTransactionSplits(
    transactionId: string,
    splits: SplitdTransaction[],
    totalAmount?: number,
  ): Promise<Transaction> {
    const url = `${this.apiUrl}/client/accounting/me/transactions/${transactionId}/split/`;
    const { transaction: transactionApi } = await this.http
      .post<{ transaction: TransactionDTO }>(url, { splits, totalAmount })
      .toPromise();

    return new Transaction().deserialize(transactionApi);
  }

  async createNewTransaction(accountId: string, transaction: Transaction): Promise<Transaction> {
    const url = `${this.apiUrl}/client/accounting/me/bankAccts/${accountId}/transactions/`;
    const { transaction: transactionApi } = await this.http
      .post<{ transaction: TransactionDTO }>(url, transaction.serialize())
      .toPromise();

    return new Transaction().deserialize(transactionApi);
  }

  async updateRules(accountId: string, categoryId: string, payee: string): Promise<Transaction> {
    const url = `${this.apiUrl}/client/accounting/me/bankAccts/${accountId}/rules/`;
    const { transaction: transactionApi } = await this.http
      .post<{ transaction: TransactionDTO }>(url, {
        categoryId,
        payee,
      })
      .toPromise();

    return new Transaction().deserialize(transactionApi);
  }

  async createTransactionsFromQFX(accountId: string, qfxFile: File): Promise<CreateFileTransactionsI> {
    const url = `${this.apiUrl}/client/accounting/me/bankAccts/${accountId}/transactions/upload/`;
    const formData = new FormData();

    formData.append('qfxFile', qfxFile);

    const { message, total, transactionsDuplicated, transactionsInvalid, transactionsOk } = await this.http
      .post<{
        message: string;
        total: number;
        transactionsDuplicated: TransactionDTO[];
        transactionsInvalid: TransactionDTO[];
        transactionsOk: TransactionDTO[];
      }>(url, formData)
      .toPromise();

    return {
      message,
      total,
      transactionsDuplicated: new Transaction().deserializeArray(transactionsDuplicated),
      transactionsInvalid: new Transaction().deserializeArray(transactionsInvalid),
      transactionsOk: new Transaction().deserializeArray(transactionsOk),
    };
  }

  async getStartBalanceByAccount(
    accountId: string,
    date: { year: string; month: string; day: string },
  ): Promise<number> {
    let startBalanceFetched: number;
    if (environment.useMocks) {
      startBalanceFetched = 0;
    } else {
      const url = `${this.apiUrl}/client/accounting/me/bankAccts/${accountId}/getStartBalance`;
      const { dateBalance } = await this.http.post<{ dateBalance }>(url, date).toPromise();
      startBalanceFetched = dateBalance;
    }
    return startBalanceFetched;
  }

  async getScheduledTransactions(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
    accountId: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
    offset: number,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
    limit: number,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
    TransactionFilter: TransactionFilterI,
  ): Promise<Array<Transaction>> {
    let scheduledTransactions: Array<Transaction> = [];
    if (environment.useMocks) {
      scheduledTransactions = new Transaction().deserializeArray(SCHEDULED_TRANSACTIONS_ALL.scheduledTransactions);
    } else {
      const url = `${this.apiUrl}/client/accounting/me/bankAccts/${accountId}/scheduledTx`;
      const { schedTxs } = await this.http.get<{ schedTxs: Array<ScheduledTxDTO> }>(url, {}).toPromise();
      schedTxs.forEach((schedTx: ScheduledTxDTO) => {
        scheduledTransactions.push(new Transaction().generateTransactionToScheduledTransaction(schedTx));
      });
    }
    return scheduledTransactions;
  }

  async saveScheduledTransaction(
    accountId: string,
    scheduledTx: Transaction,
    txToCreateAndLinkWith?: Transaction,
  ): Promise<Transaction> {
    const linkedPayee = scheduledTx.isInteraccount && !txToCreateAndLinkWith ? scheduledTx.payee : null;
    const schedSplittedTransactions: Array<SplitdTransactionDTO> = scheduledTx.splittedTransactions.map((input) =>
      input.serialize(),
    );
    const scheduledTxDTO: RequestDTOI = {
      amount: scheduledTx.amount,
      categoryId: scheduledTx.categoryId,
      schedInitDate: scheduledTx.date,
      action: scheduledTx.action,
      memo: scheduledTx.memo,
      payee: scheduledTx.payee,
      schedDayOffset: scheduledTx.schedDayOffset,
      schedMonthOffset: scheduledTx.schedMonthOffset,
      schedMonthDays: scheduledTx.schedMonthDays,
      type: scheduledTx.type,
      splittedTx: schedSplittedTransactions,
      approve: scheduledTx.id ? scheduledTx.isAccepted : false,
      linkedAction: txToCreateAndLinkWith?.action,
      linkedBankAcct: txToCreateAndLinkWith?.accountId,
      linkedCategoryId: txToCreateAndLinkWith?.categoryId,
      linkedMemo: txToCreateAndLinkWith?.memo,
      linkedPayee: txToCreateAndLinkWith?.payee || linkedPayee,
      linkedType: txToCreateAndLinkWith?.type,
    };

    if (scheduledTx.id) {
      return this.editScheduledTransaction(accountId, scheduledTxDTO, scheduledTx.id);
    }
    return this.createScheduledTransaction(accountId, scheduledTxDTO);
  }

  async createScheduledTransaction(accountId: string, scheduledTxDTO: RequestDTOI): Promise<Transaction> {
    const url = `${this.apiUrl}/client/accounting/me/bankAccts/${accountId}/scheduledTx/`;
    const { transaction: transactionApi } = await this.http
      .post<{ transaction: TransactionDTO }>(url, scheduledTxDTO)
      .toPromise();

    return new Transaction().deserialize(transactionApi);
  }

  async registerScheduledTransactionNow(accountId: string, scheduledTxId: string): Promise<void> {
    const url = `${this.apiUrl}/client/accounting/me/bankAccts/${accountId}/scheduledTx/${scheduledTxId}/register`;
    await this.http.post<{ transaction: TransactionDTO }>(url, {}).toPromise();
  }

  async editScheduledTransaction(
    accountId: string,
    scheduledTxDTO: RequestDTOI,
    scheduledTxId: string,
  ): Promise<Transaction> {
    const url = `${this.apiUrl}/client/accounting/me/bankAccts/${accountId}/scheduledTx/${scheduledTxId}`;
    const { transaction: transactionApi } = await this.http
      .put<{ transaction: TransactionDTO }>(url, scheduledTxDTO)
      .toPromise();

    return new Transaction().deserialize(transactionApi);
  }
}
