import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import moment from 'moment';
import { AssetLiability, AssetLiabilityDTO, AssetLiabilityView, TotalsNetWorth, TypeAssetLiability } from '../models';

@Injectable({
  providedIn: 'root',
})
export class NetWorthService {
  private readonly apiUrl: string = environment.apiUrl;
  private FORMAT_DATE = 'YYYY-MM-DD';

  constructor(private http: HttpClient) {}

  async getAssetLiabilities(): Promise<AssetLiabilityView> {
    const assets: AssetLiability[] = [];
    const liabilities: AssetLiability[] = [];
    const dates: string[] = [];

    let latestAsset = 0;
    let latestLiability = 0;
    const datesValuesAssets: Map<string, number> = new Map<string, number>();
    const datesValuesLiabilities: Map<string, number> = new Map<string, number>();
    const datesValuesNetWorth: Map<string, number> = new Map<string, number>();

    if (environment.useMocks) {
      // environment.useMocks
    } else {
      const url = `${this.apiUrl}/client/accounting/me/netWorths/`;
      const { netWorths } = await this.http.get<{ netWorths: AssetLiabilityDTO[] }>(url).toPromise();
      const setDates: Set<string> = new Set();

      netWorths.forEach((netWorth: AssetLiabilityDTO) => {
        const dateFormat = new AssetLiability().formatDate(netWorth.date);
        setDates.add(dateFormat);
        if (netWorth.type === TypeAssetLiability.ASSET) {
          const asset = assets.find((a: AssetLiability) => a.name === netWorth.name);
          if (datesValuesAssets.has(dateFormat)) {
            datesValuesAssets.set(dateFormat, datesValuesAssets.get(dateFormat) + netWorth.amount);
          } else {
            datesValuesAssets.set(dateFormat, netWorth.amount);
          }

          if (asset) {
            asset.datesValuesMap.set(dateFormat, { value: netWorth.amount, id: netWorth.id });
          } else {
            const newAsset = new AssetLiability().deserialize(netWorth);
            assets.push(newAsset);
          }
        } else {
          const liability = liabilities.find((l: AssetLiability) => l.name === netWorth.name);
          if (datesValuesLiabilities.has(dateFormat)) {
            datesValuesLiabilities.set(dateFormat, datesValuesLiabilities.get(dateFormat) + netWorth.amount);
          } else {
            datesValuesLiabilities.set(dateFormat, netWorth.amount);
          }

          if (liability) {
            liability.datesValuesMap.set(dateFormat, { value: netWorth.amount, id: netWorth.id });
          } else {
            const newLiability = new AssetLiability().deserialize(netWorth);
            liabilities.push(newLiability);
          }
        }
      });

      setDates.forEach((datesValue: string) => dates.push(datesValue));
      dates.sort((a, b) => (moment(b).isAfter(moment(a)) ? -1 : 1));
      dates.forEach((date, index: number) => {
        const getAsset = datesValuesAssets.has(date) ? datesValuesAssets.get(date) : 0;
        const getLiability = datesValuesLiabilities.has(date) ? datesValuesLiabilities.get(date) : 0;
        if (index === dates.length - 1) {
          latestAsset = getAsset;
          latestLiability = getLiability;
        }
        datesValuesNetWorth.set(date, getAsset - getLiability);
      });
    }

    const totals: TotalsNetWorth = {
      latestAsset,
      latestLiability,
      latestNetWorth: latestAsset - latestLiability,
      datesValuesAssets,
      datesValuesLiabilities,
      datesValuesNetWorth,
    };

    const assetLiabilityView: AssetLiabilityView = {
      assets,
      liabilities,
      datesValues: dates,
      totals,
    };

    return assetLiabilityView;
  }

  async saveAssetLiabilities(assetLiabilities: AssetLiability[]): Promise<any> {
    await Promise.all(
      assetLiabilities.map(async (assetLiability: AssetLiability) => {
        await this.saveAssetLiability(assetLiability);
      }),
    );
  }

  async saveAssetLiability(assetLiability: AssetLiability): Promise<AssetLiability> {
    const newAssetLiability: AssetLiabilityDTO = {
      ...assetLiability,
      archived: assetLiability.isArchived,
      type: assetLiability.type,
      date: new Date(assetLiability.datesValues[0].date).toString(),
      amount: assetLiability.datesValues[0].value,
      id: assetLiability.id || assetLiability.datesValues[0].id,
    };
    if (assetLiability.id) {
      return this.editAssetLiability(newAssetLiability);
    }
    return this.createAssetLiability(newAssetLiability);
  }

  async createAssetLiability(assetLiability: AssetLiabilityDTO): Promise<AssetLiability> {
    let newAssetLiability: AssetLiability;
    if (environment.useMocks) {
      // environment.useMocks
    } else {
      const url = `${this.apiUrl}/client/accounting/me/netWorths/`;
      const response = await this.http.post<any>(url, assetLiability).toPromise();
      newAssetLiability = new AssetLiability().deserialize(response);
    }

    return newAssetLiability;
  }

  async editAssetLiability(assetLiability: AssetLiabilityDTO): Promise<AssetLiability> {
    let newAssetLiability: AssetLiability;
    if (environment.useMocks) {
      // environment.useMocks
    } else {
      const url = `${this.apiUrl}/client/accounting/me/netWorths/${assetLiability.id}`;
      const response = await this.http.put<any>(url, assetLiability).toPromise();
      newAssetLiability = new AssetLiability().deserialize(response);
    }

    return newAssetLiability;
  }

  async archiveUnarchiveItems(archiveUnarchiveItems: { id: string; isArchived: boolean }[]): Promise<any> {
    await Promise.all(
      archiveUnarchiveItems.map(async (value: { id: string; isArchived: boolean }) => {
        await this.archiveUnarchive(value.id, value.isArchived);
      }),
    );
  }

  async archiveUnarchive(assetLiabilityId: string, archived: boolean): Promise<void> {
    if (environment.useMocks) {
      // environment.useMocks
    } else {
      const url = `${this.apiUrl}/client/accounting/me/netWorths/${assetLiabilityId}`;
      await this.http
        .put<any>(url, { archived })
        .toPromise();
    }
  }

  async deleteAssetLiabilities(assetLiabilitiesIds: string[]): Promise<any> {
    await Promise.all(
      assetLiabilitiesIds.map(async (assetLiabilitiesId: string) => {
        await this.deleteAssetLiability(assetLiabilitiesId);
      }),
    );
  }

  async deleteAssetLiability(assetLiabilityId: string): Promise<void> {
    if (environment.useMocks) {
      // environment.useMocks
    } else {
      const url = `${this.apiUrl}/client/accounting/me/netWorths/${assetLiabilityId}`;
      await this.http.delete<any>(url).toPromise();
    }
  }
}
