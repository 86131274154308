import { Subscription } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ClientRoleType, User } from 'app/shared/models';
import { AuthService } from 'app/modules/auth/services';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { faSignOutAlt, faAngleLeft, faAngleRight, faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { AccountService, CollapseMenuService } from 'app/shared/services';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import Variables from 'app/shared/styles/variables';

@Component({
  selector: 'app-sidebar',
  templateUrl: './app-sidebar.component.html',
  styleUrls: ['./app-sidebar.component.scss'],
})
export class AppSidebarComponent implements OnInit, OnDestroy {
  public user: User;
  private subscription: Subscription;
  public faQuestionCircle = faQuestionCircle;
  public faSignOutAlt = faSignOutAlt;
  public faAngleLeft = faAngleLeft;
  public faAngleRight = faAngleRight;
  public faBars = faBars;
  public faTimes = faTimes;

  roleSelector: ClientRoleType;
  ClientRoleType = ClientRoleType;
  isCollapsed = false;

  constructor(
    private authService: AuthService,
    private accountService: AccountService,
    private router: Router,
    private collapseMenuService: CollapseMenuService,
  ) {
    this.subscription = new Subscription();

    this.subscription.add(
      this.authService.session$.subscribe((session) => {
        if (session && session.user) {
          this.user = session.user;
          this.roleSelector = this.user.accountPlan;
        }
      }),
    );
  }

  ngOnInit(): void {
    this.subscription.add(
      this.collapseMenuService.collapseMenu$.subscribe((collapseMenu) => {
        this.isCollapsed = collapseMenu;
      }),
    );

    if (window.innerWidth <= Variables.mobileBreakWidth) {
      // responsive
      this.collapseMenuService.setCollapseMenuSubject(true);
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  onClickCollapseMenu(): void {
    this.isCollapsed = !this.isCollapsed;
    this.collapseMenuService.setCollapseMenuSubject(this.isCollapsed);
  }

  logOut(): Promise<boolean> {
    this.authService.logout();
    this.accountService.currentAccount = null;
    return this.router.navigate(['/auth']);
  }

  getUserAvatar(): string {
    return `${environment.apiUrl}/public/media/resources/${this.user.avatar}`;
  }
}
