import { Account } from './account';
import { Expectation } from './expectation';
import { SubCategory } from './subCategory';
import { Transaction } from './transaction';

export class Item {
  account?: Account;
  category?: SubCategory;
  type?: string;
  transactions: Transaction[];
  expectation: Expectation;
  amounts: Amounts;
}

export class GroupItems {
  items: Item[];
  amounts: Amounts;
}

export class GroupSections {
  items: ItemSection[];
  amounts: Amounts;
}

export class ItemSection {
  section: string;
  items: Item[];
  amounts: Amounts;
}

export class SummaryMoneyIn {
  sectionsMoneyIn: GroupSections;
  fromSavings: GroupItems;
  useOfDebt: GroupItems;
  amounts: Amounts;
}

export class SummaryMoneyOut {
  expenses: GroupSections;
  toSavings: GroupItems;
  creditCardPayments: GroupItems;
  amounts: Amounts;
}

export class Amounts {
  startingPlan?: number;
  adjustment?: number;
  currentPlan?: number;
  monthlyTotals?: number;
  remaining?: number;
}

export class PeriodOverrideBalances {
  amountToStartNextPeriod: number;
  id: string;
  overrideBalances: boolean;
  period: string;
  data: { [key: string]: number };
}
