import { Directive, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { AuthService } from 'app/modules/auth/services';
import { CurrencyService } from '../services';

@Directive({
  selector: '[inputNumber]',
})
export class InputNumberDirective implements OnInit {
  @Input() isPossibleNegative: boolean;
  @Input() nextId: string;
  @Input() isAcceptformat: boolean;
  @Output() public event: EventEmitter<void> = new EventEmitter<void>();
  @Output() public changeInput: EventEmitter<number> = new EventEmitter<number>();

  constructor(private el: ElementRef, private currencyService: CurrencyService, private authService: AuthService) {}

  private regex = new RegExp(/^[0-9.,]?$/);
  private regexTwoDecimals = new RegExp(/(\.|\\,)\d{3,}/);
  private specialKeys: Array<string> = ['Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight'];

  private keysToCopyPasteCut: Array<string> = ['Meta', 'Control', 'c', 'x', 'v'];
  private specialKeysDelete: Array<string> = ['Backspace', 'Delete'];
  // private specialKeyTab = 'Tab';
  private specialKeyEnter = 'Enter';
  private specialKeySlash = '-';
  private modified = false;
  private locale: string;

  ngOnInit(): void {
    this.locale = this.authService.getCurrentSession().user.localeLanguage || 'us';
    if (this.isAcceptformat) {
      this.el.nativeElement.value = this.currencyService.getFormat(this.el.nativeElement.value, this.locale);
    }
  }

  @HostListener('keydown', ['$event'])
  public onKeyDown(event: KeyboardEvent): void {
    // if (event.key === this.specialKeyTab) {
    //   const newInput = document.getElementById(this.nextId);

    //   if (newInput) {
    //     newInput.focus();
    //   }
    //   return;
    // }

    if (event.key === this.specialKeyEnter) {
      const newInput = document.getElementById(this.nextId);

      if (newInput) {
        newInput.focus();
      }
      return;
    }

    if (this.specialKeys.indexOf(event.key) !== -1 || this.keysToCopyPasteCut.indexOf(event.key) !== -1) {
      return;
    }
    if (
      this.regex.test(event.key) ||
      this.specialKeysDelete.indexOf(event.key) !== -1 ||
      (event.key === this.specialKeySlash && this.isPossibleNegative)
    ) {
      return;
    }

    event.preventDefault();
  }

  @HostListener('focus', ['$event'])
  public onFocus(): void {
    this.modified = false;
    const { value } = this.el.nativeElement;
    if (parseFloat(value) === 0) {
      this.el.nativeElement.value = null;
    } else if (this.isAcceptformat) {
      const parseFloatValue = parseFloat(value.replace(',', ''));
      this.el.nativeElement.value = parseFloatValue;
    }
  }

  @HostListener('blur', ['$event'])
  public onBlur(): void {
    let { value } = this.el.nativeElement;

    if (this.modified) {
      this.event.emit();
    }

    if (!value) {
      value = 0;
    }

    if (this.isAcceptformat) {
      this.el.nativeElement.value = this.currencyService.getFormat(value.toString().replace(',', ''), this.locale);
    } else {
      this.el.nativeElement.value = value;
    }
  }

  @HostListener('input', ['$event.target.value'])
  public onInput(value: string): void {
    if (value && this.regexTwoDecimals.test(value)) {
      this.el.nativeElement.value = value.slice(0, -1);
    }
    this.modified = true;
    let valueToEmit: number;
    if (this.el.nativeElement.value) {
      valueToEmit = parseFloat(this.el.nativeElement.value);
    } else {
      valueToEmit = null;
    }
    this.changeInput.emit(valueToEmit);
  }

  @HostListener('mousewheel', ['$event'])
  public onMouseWheel(event: WheelEvent): void {
    if (event && event.target && (event.target as Element).id === document.activeElement.id) {
      event.preventDefault();
    }
  }
}
