import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from 'app/modules/auth/services';
import { environment } from 'environments/environment';
import { PaymentIntent } from '@stripe/stripe-js';
import { PaymentInformation, PeriodType, Subscription } from '../models';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  private readonly apiUrl = environment.apiUrl;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private http: HttpClient, private authService: AuthService) {}

  async getClientSecret(): Promise<string> {
    let clientSecret = '';

    if (environment.useMocks) {
      // response = '';
    } else {
      const url = `${this.apiUrl}/client/payments/paymentMethods/setupIntent/`;

      const { setupIntent } = await this.http.get<{ setupIntent: { client_secret } }>(url).toPromise();
      clientSecret = setupIntent.client_secret;
    }

    return clientSecret;
  }

  async getPaymentsMethods(): Promise<PaymentInformation> {
    let response: PaymentInformation;

    if (environment.useMocks) {
      // response = '';
    } else {
      const url = `${this.apiUrl}/client/payments/paymentMethods/`;

      const { defaultPaymentMethod, paymentMethods } = await this.http
        .get<{ defaultPaymentMethod; paymentMethods }>(url)
        .toPromise();
      response = {
        defaultPaymentMethod,
        paymentMethods,
      };
    }

    return response;
  }

  async getPublishableKey(): Promise<string> {
    let response: string;

    if (environment.useMocks) {
      response = '';
    } else {
      const url = `${this.apiUrl}/client/payments/products/`;

      const { pK } = await this.http.get<{ pK }>(url).toPromise();
      response = pK;
    }

    return response;
  }

  async createSubscription(paymentMethodId: string, planSelected: PeriodType): Promise<Subscription> {
    let response: Subscription;

    if (environment.useMocks) {
      response = new Subscription();
    } else {
      const url = `${this.apiUrl}/client/payments/subscriptions/`;

      const { subscription } = await this.http
        .post<{ subscription }>(url, { paymentMethodId, subscriptionChoice: planSelected })
        .toPromise();

      response = new Subscription().deserialize(subscription);
      this.authService.updateUserSubscription(response);
    }

    return response;
  }

  async cancelSubscription(subscriptionId: string): Promise<Subscription> {
    let response: Subscription;

    if (environment.useMocks) {
      response = new Subscription();
    } else {
      const url = `${this.apiUrl}/client/payments/subscriptions/cancel`;

      const { subscription } = await this.http
        .post<any>(url, { subscriptionId })
        .toPromise();

      response = new Subscription().deserialize(subscription);
      this.authService.updateUserSubscription(response);
    }

    return response;
  }

  async reactivateSubscription(subscriptionId: string): Promise<Subscription> {
    let response: Subscription;

    if (environment.useMocks) {
      response = new Subscription();
    } else {
      const url = `${this.apiUrl}/client/payments/subscriptions/reactivate`;

      const { subscription } = await this.http
        .post<any>(url, { subscriptionId })
        .toPromise();

      response = new Subscription().deserialize(subscription);
      this.authService.updateUserSubscription(response);
    }

    return response;
  }

  async addPaymentMethodsDefault(paymentMethodId: string): Promise<any> {
    let response: any;

    if (environment.useMocks) {
      response = { message: '' };
    } else {
      const url = `${this.apiUrl}/client/payments/paymentMethods/default/`;

      response = await this.http
        .post<any>(url, { paymentMethodId })
        .toPromise();
    }

    return response;
  }

  async getPaymentIntent(paymentIntentId: string): Promise<PaymentIntent> {
    const url = `${this.apiUrl}/client/payments/paymentIntents/${paymentIntentId}/`;
    const { paymentIntent } = await this.http.get<{ paymentIntent: PaymentIntent }>(url).toPromise();

    return paymentIntent;
  }

  async deletePaymentMethod(paymentMethodId: string): Promise<any> {
    let response: any;

    if (environment.useMocks) {
      response = { message: '' };
    } else {
      const url = `${this.apiUrl}/client/payments/paymentMethods/${paymentMethodId}`;

      response = await this.http.delete<any>(url).toPromise();
    }

    return response;
  }
}
