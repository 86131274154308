import { Account, AccountType, Action, Transaction, TransactionCategoryInteraccount } from '../models';

export class InteraccountTx {
  convertTransactionToInteraccountTx(originalTx: Transaction, toAccount: Account, fromAccount: Account): Transaction {
    if (!toAccount || !fromAccount) {
      return null;
    }

    const interaccountTx = originalTx.clone();
    delete interaccountTx.id;
    interaccountTx.amount *= -1;
    interaccountTx.accountId = toAccount.id;
    interaccountTx.memo = `Transfer from - ${fromAccount.name}`;

    if (originalTx.categoryId === TransactionCategoryInteraccount.IN) {
      interaccountTx.categoryId = TransactionCategoryInteraccount.OUT;
    } else if (originalTx.categoryId === TransactionCategoryInteraccount.OUT) {
      interaccountTx.categoryId = TransactionCategoryInteraccount.IN;
    }

    let toAction = Action.GENERIC_RECEIVE;

    if (fromAccount.type === AccountType.DEBIT || fromAccount.type === AccountType.CASH) {
      if (toAccount.type === AccountType.DEBIT || toAccount.type === AccountType.CASH) {
        toAction = Action.DEBIT_CASH_RECEIVE_FROM_DEBIT_CASH;
      } else if (toAccount.type === AccountType.SAVINGS) {
        toAction = Action.SAVINGS_RECEIVE_ACCOUNT;
      } else if (toAccount.type === AccountType.CREDIT) {
        toAction = Action.RECEIVE_ACCOUNT;
      }
    } else if (fromAccount.type === AccountType.SAVINGS) {
      if (toAccount.type === AccountType.DEBIT || toAccount.type === AccountType.CASH) {
        toAction = Action.DEBIT_CASH_RECEIVE_FROM_SAVINGS;
      } else if (toAccount.type === AccountType.SAVINGS) {
        toAction = Action.SAVINGS_RECEIVE_FROM_SAVINGS;
      } else if (toAccount.type === AccountType.CREDIT) {
        toAction = Action.CARD_RECEIVE_FROM_SAVINGS;
      }
    } else if (fromAccount.type === AccountType.CREDIT) {
      if (toAccount.type === AccountType.DEBIT || toAccount.type === AccountType.CASH) {
        toAction = Action.RECEIVE_ACCOUNT;
      } else if (toAccount.type === AccountType.SAVINGS) {
        toAction = Action.SAVINGS_RECEIVE_CARD;
      } else if (toAccount.type === AccountType.CREDIT) {
        toAction = Action.RECEIVE_CARD;
      }
    } else {
      // THIS SHOULD NOT HAPPEN.
      // console.error('createInteraccountTx :: calc toAction error');
    }

    interaccountTx.action = toAction;
    return interaccountTx;
  }
}
