import { Serializable } from 'app/core/interfaces';
import moment from 'moment';
import { SubCategory } from './subCategory';
import { Transaction } from './transaction';
import { Account } from './account';

export enum TypeAssetLiability {
  ASSET = 'asset',
  LIABILITY = 'liability',
}

export interface DateValue {
  date: string;
  value: number;
  id: string;
}
export interface TotalsNetWorth {
  latestAsset: number;
  latestLiability: number;
  latestNetWorth: number;
  datesValuesAssets?: Map<string, number>;
  datesValuesLiabilities?: Map<string, number>;
  datesValuesNetWorth?: Map<string, number>;
}

export interface AssetLiabilityDTO {
  id?: string;
  name: string;
  type: 'asset' | 'liability';
  date: string;
  amount: number;
  archived: boolean;
}
export class AssetLiability implements Serializable<AssetLiability> {
  id?: string;
  name: string;
  type: TypeAssetLiability;
  connectToAccnt?: boolean;
  account?: Account;
  isArchived: boolean;
  datesValues: DateValue[];
  datesValuesMap?: Map<string, { value: number; id: string }>;

  deserialize(input: AssetLiabilityDTO): AssetLiability {
    if (!input) {
      return this;
    }
    this.id = input.id;
    this.name = input.name;
    this.type = input.type as TypeAssetLiability;
    this.isArchived = input.archived;
    this.datesValuesMap = new Map().set(this.formatDate(input.date), {
      value: input.amount,
      id: input.id,
    });

    return this;
  }

  deserializeArray(inputArray: AssetLiabilityDTO[]): AssetLiability[] {
    const assetLiabilities: Array<AssetLiability> = inputArray?.map((input) => new AssetLiability().deserialize(input));
    return assetLiabilities;
  }

  clone(): AssetLiability {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const clonedInstance: AssetLiability = Object.assign(Object.create(Object.getPrototypeOf(this)), this);

    return clonedInstance;
  }

  formatDate(date: string): string {
    return moment(date?.substring(0, 10)).format('YYYY-MM-DD');
  }
}

export class AssetLiabilityView {
  assets: AssetLiability[];
  liabilities: AssetLiability[];
  datesValues: string[];
  totals: TotalsNetWorth;
}

export interface MultiSelectItem {
  value: string;
  id: string;
  items: { name: string; id: string }[];
}
export interface MultiSelect {
  value: string;
  items: MultiSelectItem[];
}

export interface IncomeExpensesSections {
  name: string;
  total: number;
  percentage: number;
  categories: IncomeExpensesCategory[];
}

export interface IncomeExpensesCategory {
  category: SubCategory;
  transactions: Transaction[];
  total: number;
  percentage: number;
}

export interface IncomeExpensesReportsModelView {
  income: IncomeExpensesSections[];
  expenses: IncomeExpensesSections[];
  totalIncome: number;
  totalExpenses: number;
  totalDifference: number;
}

export enum SelectTime {
  YEARLY = 'Yearly',
  WEEKLY = 'Weekly',
  MONTHLY = 'Monthly',
  QUARTERLY = 'Quarterly',
}
export interface DateModelView {
  day: number;
  month: number;
  year: number;
}

export interface DatePeriodModelView {
  dateInit: DateModelView;
  dateEnd: DateModelView;
  from: DateModelView;
  to: DateModelView;
  type: SelectTime;
}
