<div
  class="app-sidebar-container d-flex flex-column no-printme"
  [ngClass]="{ collapsed: isCollapsed }"
>
  <div
    class="sidebar-header d-flex align-items-center pb-2 mt-3 mt-lg-2 px-4 px-lg-0 justify-content-between"
  >
    <a routerLink="/" routerLinkActive="active" class="mb-lg-2 d-block container-logo">
      <!-- <img *ngIf="isCollapsed" class="logo" src="/assets/images/logo.png" alt="MoneyGrit" /> -->
      <img class="logo" src="/assets/images/logo-moneygrit.png" alt="MoneyGrit" />
    </a>
    <button
      [class.pl-lg-2]="isCollapsed"
      (click)="onClickCollapseMenu()"
      class="btn-collapsed d-flex align-items-center justify-content-center p-0 mr-1"
    >
      <fa-icon
        [icon]="isCollapsed ? faAngleRight : faAngleLeft"
        class="mr-1 ml-1 icon-small angle-icon"
        [class.is-icon-right]="isCollapsed"
      ></fa-icon>
      <fa-icon class="menu-icon" [icon]="faBars"></fa-icon>
      <fa-icon class="close-icon" *ngIf="!isCollapsed" [icon]="faTimes"></fa-icon>
    </button>
  </div>
  <div class="app-user py-3 px-2">
    <a
      routerLink="/profile"
      routerLinkActive="active"
      class="d-flex flex-row px-2 font-size-lg font-primary-medium align-items-center"
    >
      <!-- TODO: Load users avatar -->
      <img *ngIf="user.avatar" [src]="getUserAvatar()" class="rounded-circle user-avatar" />
      <img
        *ngIf="!user.avatar"
        src="/assets/images/avatar.jpeg"
        class="rounded-circle user-avatar"
      />
      <div class="ml-2 d-flex flex-column user-avatar-text">
        <span class="mt-1">{{ user.firstName }} {{ user.lastName }}</span>
        <span class="font-size-sm" *ngIf="roleSelector">{{
          (roleSelector === ClientRoleType.PERSONAL ? 'Personal' : 'Business') | translate
        }}</span>
      </div>
    </a>
  </div>
  <app-sidebar-menu></app-sidebar-menu>

  <div class="app-help py-3 px-2 mt-auto">
    <a
      routerLink="/help"
      routerLinkActive="active"
      class="text-left px-2 font-size-lg font-primary-medium d-flex align-items-center justify-content-center"
    >
      <fa-icon [icon]="faQuestionCircle" class="font-size-xxl"></fa-icon>
      <span class="ml-2 mr-auto d-inline-block">{{ 'Support' | translate }}</span>
    </a>
  </div>
  <div class="app-logout py-3 px-2">
    <a
      (click)="logOut()"
      class="text-left px-2 font-size-lg font-primary-medium d-flex align-items-center justify-content-center"
    >
      <fa-icon class="font-size-xxl" [icon]="faSignOutAlt"></fa-icon>
      <span class="ml-2 mr-auto d-inline-block">{{ 'Log Out' | translate }}</span>
    </a>
  </div>
</div>
