export enum Currency {
  US_DOLLAR = 'us_dolard',
  EURO = 'euro',
}

export enum DecimalSeparator {
  DOT = 'dot',
  COMMA = 'comma',
}

export enum UnitPosition {
  FRONT = 'front',
  BEHIND = 'behind',
}

export class FormatMoney {
  private setCurrency(currency: Currency): string {
    if (currency === Currency.US_DOLLAR) {
      return '$';
    }
    if (currency === Currency.EURO) {
      return '€';
    }
    return 'error';
  }

  private setDecimalSeparator(numberToFormat: number, decimalSeparator: DecimalSeparator): string {
    if (decimalSeparator === DecimalSeparator.DOT) {
      return numberToFormat.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
    if (decimalSeparator === DecimalSeparator.COMMA) {
      return numberToFormat
        .toFixed(2)
        .replace('.', ',')
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    }
    return 'error';
  }

  public format(
    numberToFormat: number,
    currencyFormat: Currency,
    decimalSeparatorFormat: DecimalSeparator,
    unitPositionFormat: UnitPosition,
  ): string {
    const currency = this.setCurrency(currencyFormat);
    const numberFormatted = this.setDecimalSeparator(numberToFormat, decimalSeparatorFormat);

    if (unitPositionFormat === UnitPosition.FRONT) {
      return `${currency} ${numberFormatted}`;
    }
    return `${numberFormatted} ${currency}`;
  }
}
