import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Section, CET } from '../models';

@Injectable({
  providedIn: 'root',
})
export class OptionsSPService {
  private categoriesDeletedSubject = new BehaviorSubject<void>(null);

  private selectedSubCategoriesIdsSubject = new BehaviorSubject<string[]>([]);
  private selectedSectionSubject = new BehaviorSubject<Section[]>([]);
  private selectedCETSubject = new BehaviorSubject<CET[]>([]);

  private downloadXLSXSubject = new BehaviorSubject<boolean>(false);
  private downloadPDFSubject = new BehaviorSubject<boolean>(false);
  private loadPDFSubject = new BehaviorSubject<boolean>(false);

  constructor() {}

  cetChecked(cet: CET, forceCheck: boolean = false): void {
    const selectedSubCategoriesIds = this.selectedSubCategoriesIdsSubject.getValue();
    const selectedCET = this.selectedCETSubject.getValue();
    const indexCategoryId: number = selectedSubCategoriesIds.findIndex((catId: string) => {
      return catId === cet.category.id;
    });
    if (indexCategoryId === -1) {
      selectedSubCategoriesIds.push(cet.category.id);
      selectedCET.push(cet);
    } else if (!forceCheck) {
      selectedSubCategoriesIds.splice(indexCategoryId, 1);
      selectedCET.splice(indexCategoryId, 1);
      // uncheck CET -> uncheck CET.Section if checked
      const selectedSection = this.selectedSectionSubject.getValue();
      const indexSection: number = selectedSection.findIndex((catCET: Section) => catCET.name === cet.category.section);
      if (indexSection !== -1) {
        selectedSection.splice(indexSection, 1);
        this.selectedSectionSubject.next(selectedSection);
      }
    }
    this.selectedSubCategoriesIdsSubject.next(selectedSubCategoriesIds);
    this.selectedCETSubject.next(selectedCET);
  }

  categoriesChecked(section: Section): void {
    const selectedSection = this.selectedSectionSubject.getValue();
    let forceCheck = false;

    const indexSection: number = selectedSection.findIndex((catCET: Section) => catCET.name === section.name);
    if (indexSection === -1) {
      selectedSection.push(section);
      // check Section -> force check cets
      forceCheck = true;
    } else {
      selectedSection.splice(indexSection, 1);
    }
    section.cets.forEach((cet) => this.cetChecked(cet, forceCheck));
    this.selectedSectionSubject.next(selectedSection);
  }

  categoriesDeleted(): void {
    this.categoriesDeletedSubject.next();
  }

  getCategoriesDeletedSubject(): BehaviorSubject<void> {
    return this.categoriesDeletedSubject;
  }

  getSelectedSubCategoriesIds(): BehaviorSubject<string[]> {
    return this.selectedSubCategoriesIdsSubject;
  }

  getSelectedSection(): BehaviorSubject<Section[]> {
    return this.selectedSectionSubject;
  }

  getSelectedCET(): BehaviorSubject<CET[]> {
    return this.selectedCETSubject;
  }

  getDownloadXLSXSubject(): BehaviorSubject<boolean> {
    return this.downloadXLSXSubject;
  }

  getDownloadPDFSubject(): BehaviorSubject<boolean> {
    return this.downloadPDFSubject;
  }

  getLoadPDFSubject(): BehaviorSubject<boolean> {
    return this.loadPDFSubject;
  }

  resetSelectedItemsSubjects(): void {
    this.selectedSubCategoriesIdsSubject.next([]);
    this.selectedSectionSubject.next([]);
    this.selectedCETSubject.next([]);
  }

  downloadXLSX(download: boolean): void {
    this.downloadXLSXSubject.next(download);
  }

  downloadPDF(download: boolean): void {
    this.downloadPDFSubject.next(download);
  }

  loadPDF(load: boolean): void {
    this.loadPDFSubject.next(load);
  }
}
