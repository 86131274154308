import { Serializable } from 'app/core/interfaces';
import CurrencyCodes from 'currency-codes';
import { AccountDTO } from './account';
import { Expectation, ExpectationDTO } from './expectation';
import { SubCategory, SubCategoryDTO, CategoryType } from './subCategory';

export enum SpendingPlanTypes {
  MONTHLY = 'Monthly',
  ANNUAL = 'Annual',
}

export enum UncategorizedTypes {
  UNCATEGORIZED_IN = 'Uncategorized In',
  UNCATEGORIZED_OUT = 'Uncategorized Out',
}

export interface CategoryOrder {
  section: string;
  categoryIds: string[];
  type: CategoryType;
}
export interface PlanSpendingPlan {
  type?: SpendingPlanTypes;
  monthlyValue: number;
  yearValue: number;
}

export interface DateListSpendingPlan {
  isValidNext?: boolean;
  isValidPrevious?: boolean;
  dateList: Date[];
}
export interface SpendingPlanDTO {
  sPlanId: string;
  name: string;
  owner: string;
  categories: Array<SubCategoryDTO>;
  expectations: Array<ExpectationDTO>;
  // timeframe: string;
  isMain?: boolean;
  // expectations: Array<ExpectedAmountDTO>;
  currency?: string;
  numberFormat?: string;
  currencyPlacement?: string;
  dateFormat?: string;
  tiedAccts?: string[];
  tiedAccounts: Array<AccountDTO>;
  categoryOrder?: CategoryOrder[];
  // tiedAccounts?: {
  //   debit?: Array<string>;
  //   savings?: Array<string>;
  //   cash?: Array<string>;
  //   credit?: Array<string>;
  // };
}

export class SpendingPlan implements Serializable<SpendingPlan> {
  id: string;
  name: string;
  owner: string;
  categories: Array<SubCategory>;
  tiedAccounts: Array<string>;
  isMain?: boolean;
  // timeframe: string;
  // expectedAmounts: Array<ExpectedAmount>;
  tiedAccts?: string[];
  expectations: Array<Expectation>;
  // timeframe: string;
  currency?: string;
  numberFormat?: string;
  currencyPlacement?: string;
  dateFormat?: string;
  categoryOrder?: CategoryOrder[];
  // tiedAccounts?: {
  //   debit?: Array<string>;
  //   savings?: Array<string>;
  //   cash?: Array<string>;
  //   credit?: Array<string>;
  // };

  // constructor() {
  //   this.id = null;
  //   this.name = null;
  //   this.isMain = null;
  //   this.currency = null;
  //   this.numberFormat = null;
  //   this.currencyPlacement = null;
  //   this.dateFormat = null;
  // }

  deserialize(input: SpendingPlanDTO): SpendingPlan {
    if (!input) {
      return this;
    }

    this.id = input.sPlanId || '';
    this.name = input.name || '';
    this.isMain = input.isMain || false;
    this.currency = input.currency || CurrencyCodes.code('USD').code;
    this.numberFormat = input.numberFormat || '';
    this.currencyPlacement = input.currencyPlacement || '';
    this.dateFormat = input.dateFormat || '';
    // this.tiedAccounts = {
    //   debit: input.tiedAccounts.debit ? [...input.tiedAccounts.debit] : [],
    //   savings: input.tiedAccounts.savings ? [...input.tiedAccounts.savings] : [],
    //   cash: input.tiedAccounts.cash ? [...input.tiedAccounts.cash] : [],
    //   credit: input.tiedAccounts.credit ? [...input.tiedAccounts.credit] : [],
    // };

    this.tiedAccounts = input.tiedAccts || [];
    this.categories = new SubCategory().deserializeArray(input.categories) || [];
    this.expectations = new Expectation().deserializeArray(input.expectations) || [];
    this.tiedAccts = input.tiedAccts || [];
    this.categoryOrder = this.setCategoryOrderList(input.categoryOrder);

    return this;
  }

  setCategoryOrderList(categoryOrder: CategoryOrder[]): CategoryOrder[] {
    const { categories } = this;
    const categoryOrderArray: CategoryOrder[] = [];
    if (!categoryOrder || categoryOrder.length === 0) {
      categories.forEach((categoryItem: SubCategory) => {
        let categoryOrderFinded: CategoryOrder = categoryOrderArray.find(
          (cat: CategoryOrder) => cat.section === categoryItem.section && cat.type === categoryItem.type,
        );

        if (!categoryOrderFinded) {
          categoryOrderFinded = {
            section: categoryItem.section,
            categoryIds: [categoryItem.id],
            type: categoryItem.type,
          };

          categoryOrderArray.push(categoryOrderFinded);
        } else {
          categoryOrderFinded.categoryIds.push(categoryItem.id);
        }
      });
    } else {
      const categoryOrderWithType = categoryOrder.filter((catOrder: CategoryOrder) => !!catOrder.type);
      if (categoryOrder.length !== categoryOrderWithType.length) {
        categoryOrder.forEach((catOrder: CategoryOrder) => {
          const categoriesByIds = categories.filter(
            (category: SubCategory) => !!catOrder.categoryIds.includes(category.id),
          );
          const categoriesTypes: Set<CategoryType> = new Set(
            categoriesByIds.map((category: SubCategory) => category.type),
          );

          categoriesTypes.forEach((categoryType: CategoryType) => {
            categoryOrderArray.push({
              section: catOrder.section,
              categoryIds: categoriesByIds
                .filter((category: SubCategory) => category.type === categoryType)
                .map((category: SubCategory) => category.id),
              type: categoryType,
            });
          });
        });
      } else {
        categoryOrderArray.push(...categoryOrder);
      }
      const categoriesIn = categories.filter((category) => category.section && category.type === CategoryType.MONEY_IN);
      const categoriesOut = categories.filter(
        (category) => category.section && category.type === CategoryType.MONEY_OUT,
      );

      const categoryIdsCategoryOrderIn: string[] = categoryOrderArray
        .filter((cOrder: CategoryOrder) => cOrder.type === CategoryType.MONEY_IN)
        .map((cOrder: CategoryOrder) => cOrder.categoryIds)
        .reduce((prev: string[], curr: string[]) => prev.concat(curr));

      const categoryIdsCategoryOrderOut: string[] = categoryOrderArray
        .filter((cOrder: CategoryOrder) => cOrder.type === CategoryType.MONEY_OUT)
        .map((cOrder: CategoryOrder) => cOrder.categoryIds)
        .reduce((prev: string[], curr: string[]) => prev.concat(curr));

      if (categoriesIn.length !== categoryIdsCategoryOrderIn.length) {
        categoriesIn.forEach((cat: SubCategory) => {
          const existInCategoryOrder = categoryIdsCategoryOrderIn.includes(cat.id);
          if (!existInCategoryOrder) {
            const existCategoryOrder = categoryOrderArray.find(
              (cOrder: CategoryOrder) => cOrder.type === cat.type && cOrder.section === cat.section,
            );
            if (existCategoryOrder) {
              existCategoryOrder.categoryIds.push(cat.id);
            } else {
              categoryOrderArray.push({
                section: cat.section,
                categoryIds: [cat.id],
                type: cat.type,
              });
            }
          }
        });
      }

      if (categoriesOut.length !== categoryIdsCategoryOrderOut.length) {
        categoriesOut.forEach((cat: SubCategory) => {
          const existInCategoryOrder = categoryIdsCategoryOrderOut.includes(cat.id);
          if (!existInCategoryOrder) {
            const existCategoryOrder = categoryOrderArray.find(
              (cOrder: CategoryOrder) => cOrder.type === cat.type && cOrder.section === cat.section,
            );
            if (existCategoryOrder) {
              existCategoryOrder.categoryIds.push(cat.id);
            } else {
              categoryOrderArray.push({
                section: cat.section,
                categoryIds: [cat.id],
                type: cat.type,
              });
            }
          }
        });
      }
    }

    return categoryOrderArray;
  }

  get dictCategories(): Map<string, SubCategory> {
    return new Map(this.categories.map((category: SubCategory) => [category.id, category]));
  }

  deserializeArray(inputArray: Array<SpendingPlanDTO>): Array<SpendingPlan> {
    const spendingPlans: Array<SpendingPlan> = inputArray.map((input) => new SpendingPlan().deserialize(input));

    return spendingPlans;
  }

  clone(): SpendingPlan {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const clonedInstance: SpendingPlan = Object.assign(Object.create(Object.getPrototypeOf(this)), this);

    return clonedInstance;
  }
}
