import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject } from 'rxjs';
import { SPENDINGPLAN, SPENDINGPLANSLIST } from '../mocks';
import { CategoryOrder, PeriodOverrideBalances, SpendingPlan, SpendingPlanDTO } from '../models';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class SpendingPlanService {
  private readonly apiUrl: string = environment.apiUrl;

  private currencyCode: string;

  private readonly spendingPlanSubject = new BehaviorSubject<SpendingPlan>(null);
  private readonly spendingPlansListSubject = new BehaviorSubject<Array<SpendingPlan>>([]);
  readonly spendingPlansList$ = this.spendingPlansListSubject.asObservable();
  readonly spendingPlan$ = this.spendingPlanSubject.asObservable();

  get getCurrencyCode(): string {
    return this.currencyCode;
  }

  setCurrencyCode(currencyCode: string): void {
    this.currencyCode = currencyCode;
  }

  get spendingPlansList(): Array<SpendingPlan> {
    return this.spendingPlansListSubject.getValue();
  }

  get spendingPlan(): SpendingPlan {
    return this.spendingPlanSubject.getValue();
  }

  setSpendingPlanSubject(spendingPlan: SpendingPlan): void {
    this.spendingPlanSubject.next(spendingPlan);
  }

  getSpendingPlanByIdSubject(sPlanId: string): SpendingPlan {
    return this.spendingPlansList.find((spendingPlan: SpendingPlan) => spendingPlan.id === sPlanId);
  }

  setSpendingPlansListSubject(spendingPlansList: Array<SpendingPlan>): void {
    this.spendingPlansListSubject.next(spendingPlansList);
  }

  constructor(private http: HttpClient) {}

  async getSpendingPlansList(): Promise<Array<SpendingPlan>> {
    let spendingPlansFetched: Array<SpendingPlan> = [];
    if (environment.useMocks) {
      spendingPlansFetched = new SpendingPlan().deserializeArray(SPENDINGPLANSLIST);
    } else {
      const url = `${this.apiUrl}/client/spendingPlans/me/`;
      const response = await this.http.get<{ spendingPlans: SpendingPlanDTO[] }>(url, httpOptions).toPromise();

      spendingPlansFetched = response.spendingPlans.map((spDTO) => new SpendingPlan().deserialize(spDTO));

      this.setSpendingPlansListSubject(spendingPlansFetched);
    }
    return spendingPlansFetched;
  }

  async getSpendingPlanById(sPlanId: string): Promise<SpendingPlan> {
    let spendingPlanFetched: SpendingPlan = new SpendingPlan();
    if (environment.useMocks) {
      spendingPlanFetched = new SpendingPlan().deserialize(SPENDINGPLAN);
    } else {
      const url = `${this.apiUrl}/client/spendingPlans/${sPlanId}`;
      const { spendingPlan } = await this.http.get<{ spendingPlan: SpendingPlanDTO }>(url, httpOptions).toPromise();
      spendingPlanFetched = new SpendingPlan().deserialize(spendingPlan);
      this.setCurrencyCode(spendingPlanFetched.currency);
      this.setSpendingPlanSubject(spendingPlanFetched);
    }
    return spendingPlanFetched;
  }

  async getSpendingPlanSubject(spendingPlanId: string): Promise<SpendingPlan> {
    const spendingPlanSubject: SpendingPlan = this.getSpendingPlanByIdSubject(spendingPlanId);
    if (!spendingPlanSubject) {
      return this.getSpendingPlanById(spendingPlanId);
    }
    return spendingPlanSubject;
  }

  async getSpendingPlanByAccountId(accountId: string): Promise<SpendingPlan | null> {
    let spendingPlanFetched: SpendingPlan;
    if (environment.useMocks) {
      const spendingPlanList = [...SPENDINGPLANSLIST];
      const spDTO = spendingPlanList.find((sp: SpendingPlanDTO) => sp.sPlanId === accountId); // TODO
      spendingPlanFetched = new SpendingPlan().deserialize(spDTO);
    } else {
      const url = `${this.apiUrl}/client/spendingPlans/getByAcctId/`;
      const { spendingPlan } = await this.http
        .post<{ spendingPlan: SpendingPlanDTO }>(url, { accountId })
        .toPromise();
      if (spendingPlan === null) {
        return null;
      }
      spendingPlanFetched = new SpendingPlan().deserialize(spendingPlan);
    }
    return spendingPlanFetched;
  }

  // async getRightHolders(offset: number, limit: number, text: string): Promise<RightHoldersResponse> {
  //   const filters = `?offset=${offset}&limit=${limit}&text=${text}`;

  async createSpendingPlan(newSpendingPlan: SpendingPlan): Promise<SpendingPlan> {
    let spendingPlanFetched: SpendingPlan = null;

    if (environment.useMocks) {
      spendingPlanFetched = new SpendingPlan().deserialize(SPENDINGPLANSLIST[1]);
    } else {
      const url = `${this.apiUrl}/client/spendingPlans/me/`; // TODO
      const response = await this.http.post<{ spendingPlan: SpendingPlanDTO }>(url, newSpendingPlan).toPromise();
      spendingPlanFetched = new SpendingPlan().deserialize(response.spendingPlan);
    }

    return spendingPlanFetched;
  }

  async updateSpendingPlan(spendingPlan: SpendingPlan): Promise<SpendingPlan> {
    let spendingPlanFetched: SpendingPlan = null;

    if (environment.useMocks) {
      spendingPlanFetched = new SpendingPlan().deserialize(SPENDINGPLANSLIST[1]);
    } else {
      const url = `${this.apiUrl}/client/spendingPlans/${spendingPlan.id}/`;
      const response = await this.http.put<{ spendingPlan: SpendingPlanDTO }>(url, spendingPlan).toPromise();
      spendingPlanFetched = new SpendingPlan().deserialize(response.spendingPlan);
    }

    return spendingPlanFetched;
  }

  async changeSpendingPlanCurrency(spendingPlanId: string, currency: string): Promise<SpendingPlan> {
    let spendingPlanFetched: SpendingPlan = null;

    if (environment.useMocks) {
      spendingPlanFetched = new SpendingPlan().deserialize(SPENDINGPLANSLIST[1]);
    } else {
      const url = `${this.apiUrl}/client/spendingPlans/${spendingPlanId}/changeCurrency`;
      await this.http
        .post<{ spendingPlan: SpendingPlanDTO }>(url, { currency })
        .toPromise();

      this.reloadSpendingPlan(spendingPlanId);
    }

    return spendingPlanFetched;
  }

  async deleteSpendingPlan(spendingPlanId: string): Promise<void> {
    if (environment.useMocks) {
      // eslint-disable-next-line no-console
      console.log(':::Deleted SpendingPlan:::');
    } else {
      const url = `${this.apiUrl}/client/spendingPlans/${spendingPlanId}/`;
      await this.http.delete<{ spendingPlan: SpendingPlanDTO }>(url).toPromise();
    }
  }

  async getOverrideBalances(sPlanId: string, year: number, month: number): Promise<PeriodOverrideBalances> {
    let periodOverrideBalancesFetched: PeriodOverrideBalances;
    if (environment.useMocks) {
      periodOverrideBalancesFetched = new PeriodOverrideBalances();
    } else {
      const actualMonth = month ? month - 1 : -1;
      let url = `${this.apiUrl}/client/spendingPlans/${sPlanId}/periods`;
      url += `?y=${year.toString()}&m=${actualMonth.toString()}`;
      const response = await this.http.get<{ period: PeriodOverrideBalances }>(url, httpOptions).toPromise();
      periodOverrideBalancesFetched = response.period;
    }
    return periodOverrideBalancesFetched;
  }

  async createOverrideBalances(
    sPlanId: string,
    year: number,
    month: number,
    amountToStartNextPeriod: number,
    data: { [key: string]: number },
    overrideBalances: boolean,
  ): Promise<PeriodOverrideBalances> {
    let periodOverrideBalancesFetched: PeriodOverrideBalances;
    if (environment.useMocks) {
      periodOverrideBalancesFetched = new PeriodOverrideBalances();
    } else {
      const url = `${this.apiUrl}/client/spendingPlans/${sPlanId}/periods`;
      const body = {
        periodYear: year,
        periodMonth: month ? month - 1 : -1, // 0: January, 11: December, -1 or 12: All_Year
        amountToStartNextPeriod,
        overrideBalances,
        data,
      };
      const response = await this.http.post<{ period }>(url, body).toPromise();
      periodOverrideBalancesFetched = response.period;
    }
    return periodOverrideBalancesFetched;
  }

  async updateOverrideBalances(
    sPlanId: string,
    periodId: string,
    year: number,
    month: number,
    amountToStartNextPeriod: number,
    data: { [key: string]: number },
    overrideBalances: boolean,
  ): Promise<PeriodOverrideBalances> {
    let periodOverrideBalancesFetched: PeriodOverrideBalances;
    if (environment.useMocks) {
      periodOverrideBalancesFetched = new PeriodOverrideBalances();
    } else {
      const url = `${this.apiUrl}/client/spendingPlans/${sPlanId}/periods/${periodId}`;
      const body = {
        periodYear: year,
        periodMonth: month ? month - 1 : -1, // 0: January, 11: December, -1 or 12: All_Year
        amountToStartNextPeriod,
        overrideBalances,
        data,
      };
      const response = await this.http.put<{ period }>(url, body).toPromise();
      periodOverrideBalancesFetched = response.period;
    }
    return periodOverrideBalancesFetched;
  }

  async updateChangeCategoryOrder(sPlanId: string, categoryOrderToUpdate: CategoryOrder[]): Promise<void> {
    if (environment.useMocks) {
      // useMocks
    } else {
      const url = `${this.apiUrl}/client/spendingPlans/${sPlanId}/categories/changeOrder/`;
      await this.http
        .put<{ message: string }>(url, { categoryOrder: categoryOrderToUpdate })
        .toPromise();

      this.reloadSpendingPlan(sPlanId);
    }
  }

  async reloadSpendingPlan(sPlanId: string): Promise<void> {
    if (environment.useMocks) {
      // useMocks
    } else {
      await this.getSpendingPlanById(sPlanId);
    }
  }

  async deleteCategoriesOfCategoryOrder(categoriesId: string[]): Promise<void> {
    const { spendingPlan } = this;
    const updateCategoriesOrder: CategoryOrder[] = [];
    spendingPlan.categoryOrder.forEach((catOrder: CategoryOrder) => {
      const categoriesIdCatOrder: string[] = [];
      catOrder.categoryIds.forEach((catId: string) => {
        if (!categoriesId.includes(catId)) {
          categoriesIdCatOrder.push(catId);
        }
      });
      if (categoriesIdCatOrder.length > 0) {
        updateCategoriesOrder.push({
          section: catOrder.section,
          categoryIds: categoriesIdCatOrder,
          type: catOrder.type,
        });
      }
    });

    await this.updateChangeCategoryOrder(spendingPlan.id, updateCategoriesOrder);
  }
}
