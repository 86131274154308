import { Pipe, PipeTransform } from '@angular/core';
import { DateFormatService } from 'app/shared/services';

@Pipe({
  name: 'dateFormat',
})
export class DateFormatPipe implements PipeTransform {
  constructor(private dateFormatService: DateFormatService) {}

  transform(value: string): string | null {
    return this.dateFormatService.transformDate(value);
  }
}
