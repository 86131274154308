import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';

@Injectable()
export class ExportFileService {
  constructor() {}

  private saveAsFile(buffer: any, fileName: string, fileType: string): void {
    const data: Blob = new Blob([buffer], { type: fileType });
    saveAs(data, fileName);
  }

  convertToCSV(content: string, fileName: string): void {
    this.saveAsFile(content, `${fileName}.csv`, 'text/plain;charset=utf-8');
  }

  convertToPDF(content: Buffer, fileName: string): void {
    this.saveAsFile(content, `${fileName}.pdf`, 'application/pdf');
    // // eslint-disable-next-line new-cap
    // const doc = new jsPDF();

    // doc.text(content, 10, 10);
    // doc.save(`${fileName}.pdf`);
  }

  convertToXLSX(content: Buffer, fileName: string): void {
    this.saveAsFile(content, `${fileName}.xlsx`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
  }
}
