import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from 'environments/environment';
import { Profile, ProfileDTO, User, UserDTO } from 'app/shared/models';
import { AuthService } from 'app/modules/auth/services';
import { FormatTypes, FormatTypesDTO } from '../models/formatTypes';

interface UpdateUserI {
  user: UserDTO;
}

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private readonly apiUrl = environment.apiUrl;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  private formatTypes: FormatTypes;

  setFormatTypes(newFormatTypes: FormatTypesDTO): void {
    this.formatTypes = new FormatTypes().deserialize(newFormatTypes);
  }

  get getFormatTypes(): FormatTypes {
    return this.formatTypes;
  }

  constructor(private http: HttpClient, private authService: AuthService) {
    this.setFormatTypes(new FormatTypesDTO());
  }

  async updateUserInfo(userToUpdate: User): Promise<Profile> {
    let updatedProfile: Profile;
    if (!environment.useMocks) {
      // Update auth
      // const currentUser = await this.getUserAuth(userId);
      // if (currentUser.username !== userUpdated.username || currentUser.email !== userUpdated.email) {
      //   const authUrl = `${this.apiUrl}/admin/auth/${userId}/`;
      //   updatedAuthDTO = await this.http.put<AuthInfoDTO>(authUrl, userUpdated).toPromise();
      // }
      const url = `${this.apiUrl}/client/users/me/`;
      const { user: updatedProfileDTO } = await this.http.put<{ user: ProfileDTO }>(url, userToUpdate).toPromise();

      updatedProfile = new Profile().deserialize(updatedProfileDTO);
      this.authService.updateUserInfo(updatedProfile);
    }
    return updatedProfile;
  }

  async updateAvatar(avatar: File): Promise<void> {
    if (environment.useMocks) {
      return;
    }
    const url = `${this.apiUrl}/client/users/me/avatar/`;
    const formData: FormData = new FormData();
    formData.append('avatar', avatar);
    const { user } = await this.http.put<{ user }>(url, formData).toPromise();
    this.authService.updateUserAvatar(user.avatar);
  }

  async deleteAvatar(): Promise<void> {
    if (environment.useMocks) {
      return;
    }
    const url = `${this.apiUrl}/client/users/me/avatar/`;
    await this.http.delete(url).toPromise();
    this.authService.updateUserAvatar('');
  }
}
