import { Serializable } from 'app/core/interfaces';

export interface TransactionDetailDTO {
  id?: string;
  category?: number;
  subCategory?: string;
  memo?: string;
  amount?: number;
}

export class TransactionDetail implements Serializable<TransactionDetail> {
  id?: string;
  category?: number;
  subCategory?: string;
  memo?: string;
  amount?: number;

  deserialize(input: TransactionDetailDTO): TransactionDetail {
    if (!input) {
      return this;
    }

    this.id = input.id || '';
    this.category = input.category || 1;
    this.subCategory = input.subCategory || '';
    this.memo = input.memo || '';
    this.amount = input.amount || 0;

    return this;
  }

  deserializeArray(inputArray: Array<TransactionDetailDTO>): Array<TransactionDetail> {
    const transactionDetails: Array<TransactionDetail> = inputArray.map((input) =>
      new TransactionDetail().deserialize(input),
    );

    return transactionDetails;
  }
}
