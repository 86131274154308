import { Injectable, Injector } from '@angular/core';

import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { AuthService } from 'app/modules/auth/services';
import { catchError, throttleTime } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {
  private throttleLogout = new Subject();
  private throttleProfile = new Subject();

  constructor(private injector: Injector, private router: Router) {
    this.throttleLogout.pipe(throttleTime(5000)).subscribe(() => {
      const authService = this.injector.get(AuthService);
      authService.logout();

      return this.router.navigate(['/auth/login']);
    });

    this.throttleProfile.pipe(throttleTime(5000)).subscribe(() => {
      const authService = this.injector.get(AuthService);
      authService.refreshUserSubscription().then(() => this.router.navigate(['/profile']));
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authService = this.injector.get(AuthService);

    let request = req;

    if (authService.isAuthenticated()) {
      const token = authService.getCurrentToken();
      request = req.clone({
        setHeaders: {
          // 'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
    }

    return next.handle(request).pipe(
      catchError((response: HttpErrorResponse) => {
        if (response.status === 401) {
          this.throttleLogout.next();
        }

        if (response.status === 403) {
          this.throttleProfile.next();
        }

        return throwError(response);
      }),
    );
  }
}
