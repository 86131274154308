import { Serializable } from 'app/core/interfaces';

export enum TransactionStatusType {
  ACCEPTED = 'accepted',
  PENDING = 'pending',
  REJECTED = 'rejected', // ATM reject is not used (since reject = deleted). It could be used as "Hidden"
}

export enum Action {
  MONEY_OUT = 'Money Out',
  MONEY_IN = 'Money In',
  REFUND_RETURN_IN = 'Refund / Return (Money in)',
  REFUND_RETURN_OUT = 'Refund / Return (Money out)',
  PAY_CREDIT_CARD = 'Pay a credit card (Money out)', // D'una debit o cash a credit card -> RECEIVE_ACCOUNT
  TRANSFER_CHECK_CASH = 'Transfer to checking or cash', // From check (debit) cash to una altra del mateix estil -> SAVINGS_RECEIVE_ACCOUNT
  TRANSFER_A_CASH_ADVANCE = 'Transfer a cash advance', // From Credit card to checking, cash or savings
  TRANSFER_SAVINGS = 'Transfer to savings', // D'una debit o cash a savings -> SAVINGS_RECEIVE_ACCOUNT
  PAY_CARD = 'Pay another credit card', // D'una credit a credit card -> RECEIVE_CARD
  NEW_CHARGES = 'New Charges',
  NEW_INTEREST_FEES = 'New interest and fees',
  //
  RECEIVE_CARD = 'Receive from another credit card',
  RECEIVE_ACCOUNT = 'Receive from another account',
  SAVINGS_RECEIVE_CARD = 'Savings receive from another credit card',
  SAVINGS_RECEIVE_ACCOUNT = 'Savings receive from another account',
  DEBIT_CASH_RECEIVE_FROM_SAVINGS = 'Debit/cash receive from savings',
  DEBIT_CASH_RECEIVE_FROM_DEBIT_CASH = 'Debit/cash receive from Debit/cash',
  CARD_RECEIVE_FROM_SAVINGS = 'Credit Card receive from savings',
  SAVINGS_RECEIVE_FROM_SAVINGS = 'Savings receive from savings', // no es fa servir de moment
  GENERIC_RECEIVE = 'Receive from unknow',
  PAYMENT = 'Payment',
}

// interface ActionsDictI {
//   [key: string]: Action;
// }

// const ActionsDict: ActionsDictI = Action.

// export const ActionsDict = Action;

export const TransactionActionDebit = {
  MONEY_OUT: Action.MONEY_OUT,
  MONEY_IN: Action.MONEY_IN,
  REFUND_RETURN_IN: Action.REFUND_RETURN_IN,
  REFUND_RETURN_OUT: Action.REFUND_RETURN_OUT,
  PAY_CREDIT_CARD: Action.PAY_CREDIT_CARD,
  TRANSFER_CHECK_CASH: Action.TRANSFER_CHECK_CASH,
  TRANSFER_SAVINGS: Action.TRANSFER_SAVINGS,
};

export const TransactionActionCredit = {
  NEW_CHARGES: Action.NEW_CHARGES,
  REFUND_RETURN_IN: Action.REFUND_RETURN_IN,
  NEW_INTEREST_FEES: Action.NEW_INTEREST_FEES,
  TRANSFER_CHECK_CASH: Action.TRANSFER_CHECK_CASH,
  TRANSFER_A_CASH_ADVANCE: Action.TRANSFER_A_CASH_ADVANCE,
  PAY_CARD: Action.PAY_CARD,
  PAYMENT: Action.PAYMENT,
};

export const TransactionActionInteraccount = {
  RECEIVE_CARD: Action.RECEIVE_CARD,
  RECEIVE_ACCOUNT: Action.RECEIVE_ACCOUNT,
  SAVINGS_RECEIVE_CARD: Action.SAVINGS_RECEIVE_CARD, // unused
  SAVINGS_RECEIVE_ACCOUNT: Action.SAVINGS_RECEIVE_ACCOUNT,
  DEBIT_CASH_RECEIVE_FROM_SAVINGS: Action.DEBIT_CASH_RECEIVE_FROM_SAVINGS,
  DEBIT_CASH_RECEIVE_FROM_DEBIT_CASH: Action.DEBIT_CASH_RECEIVE_FROM_DEBIT_CASH,
  CARD_RECEIVE_FROM_SAVINGS: Action.CARD_RECEIVE_FROM_SAVINGS,
  SAVINGS_RECEIVE_FROM_SAVINGS: Action.SAVINGS_RECEIVE_FROM_SAVINGS, // unused
  GENERIC_RECEIVE: Action.GENERIC_RECEIVE, // added to match with Debit/Credit actions
  PAY_CREDIT_CARD: Action.PAY_CREDIT_CARD,
  TRANSFER_CHECK_CASH: Action.TRANSFER_CHECK_CASH,
  TRANSFER_A_CASH_ADVANCE: Action.TRANSFER_A_CASH_ADVANCE,
  TRANSFER_SAVINGS: Action.TRANSFER_SAVINGS,
  PAY_CARD: Action.PAY_CARD,
};

export const TransactionActionSP = {
  FROM_SAVINGS: [Action.PAY_CREDIT_CARD, Action.TRANSFER_CHECK_CASH, Action.MONEY_OUT, Action.REFUND_RETURN_IN],
  TO_SAVINGS: [Action.SAVINGS_RECEIVE_ACCOUNT, Action.MONEY_IN, Action.REFUND_RETURN_OUT, Action.SAVINGS_RECEIVE_CARD],
  USE_OF_DEBT: [
    Action.NEW_CHARGES,
    Action.NEW_INTEREST_FEES,
    Action.REFUND_RETURN_IN,
    Action.PAY_CARD,
    Action.TRANSFER_A_CASH_ADVANCE,
    Action.TRANSFER_CHECK_CASH,
  ],
  CC_PAYMENTS: [Action.RECEIVE_CARD, Action.RECEIVE_ACCOUNT, Action.PAYMENT, Action.CARD_RECEIVE_FROM_SAVINGS],
};

export enum TransactionCategoryInteraccount {
  IN = '_interaccountIN',
  OUT = '_interaccountOUT',
}

export enum TransactionUncategorizedCategory {
  IN = '_uncategorizedIN',
  OUT = '_uncategorizedOUT',
}

export enum FrequencyTypes {
  DAILY = 'Daily',
  WEEKLY = 'Weekly',
  MONTHLY = 'Monthly',
  EVERY_OTHER_WEEK = 'Every Other Week',
  TWICE_A_MONTH = 'Twice a Month',
  EVERY_4_WEEKS = 'Every 4 Weeks',
  EVERY_3_MONTHS = 'Every 3 Months',
  EVERY_4_MONTHS = 'Every 4 Months',
  TWICE_YEAR = 'Twice a year',
  YEARLY = 'Yearly',
  EVERY_OTHER_YEAR = 'Every Other Year',
}

export enum FilterDates {
  ALL_DATES = 'allDates',
  ACTUAL_YEAR = 'actualYear',
  ACTUAL_MONTH = 'actualMonth',
  CUSTOM = 'custom',
}

export enum TransactionType {
  CREDIT = 'CREDIT', // Generic credit
  DEBIT = 'DEBIT', // Generic debit
  INT = 'INT', // Interest earned or paid
  DIV = 'DIV', // Dividend
  FEE = 'FEE', // FI fee
  SRVCHG = 'SRVCHG', // Service charge
  DEP = 'DEP', // Deposit
  ATM = 'ATM', // ATM debit or credit
  POS = 'POS', // Point of sale debit or credit
  XFER = 'XFER', // Transfer
  CHECK = 'CHECK', // Check
  PAYMENT = 'PAYMENT', // Electronic payment
  CASH = 'CASH', // Cash withdrawal
  DIRECTDEP = 'DIRECTDEP', // Direct deposit
  DIRECTDEBIT = 'DIRECTDEBIT', // Merchant initiated debit
  REPEATPMT = 'REPEATPMT', // Repeating payment/standing order
  HOLD = 'HOLD', // Indicates the amount is under a hold
  OTHER = 'OTHER', // Other
}

export enum TransactionLinkDirection {
  ORIGIN = 'origin',
  DESTINATION = 'destination',
}

export enum TransactionCreatedBy {
  CLIENT = 'client',
  QFX = 'qfx',
  BANKSYNC = 'bankSync',
}

export interface MatchedTransacionDTO {
  payee: string;
  memo: string;
  date: string;
  categoryId?: string;
}

export class ActionFromTransaction {
  static getSplitActionFromTransactionAction(action: string): Action {
    if (this.getTransactionAmount(action, 1) < 0) {
      return Action.MONEY_OUT;
    }
    return Action.MONEY_IN;
  }

  static getTransactionAmount(action: string, amount: number): number {
    if (
      action === TransactionActionDebit.MONEY_OUT ||
      action === TransactionActionDebit.REFUND_RETURN_OUT ||
      action === TransactionActionDebit.PAY_CREDIT_CARD ||
      action === TransactionActionDebit.TRANSFER_CHECK_CASH ||
      action === TransactionActionCredit.TRANSFER_A_CASH_ADVANCE ||
      action === TransactionActionDebit.TRANSFER_SAVINGS ||
      action === TransactionActionCredit.NEW_CHARGES ||
      action === TransactionActionCredit.NEW_INTEREST_FEES ||
      action === TransactionActionCredit.PAY_CARD
    ) {
      return -amount;
    }

    return amount;
  }
}

export interface SplitdTransactionDTO {
  id: string;
  payee: string;
  memo: string;
  date: string;
  categoryId: string;
  amount: number;
  action?: string;
  bankAcctId?: string;
  linkedTransaction?: string | '';
  linkedBankAcct?: string | '';
  linkedDirection: TransactionLinkDirection | '';

  linkedCategoryId?: string;
  linkedPayee?: string;
  linkedMemo?: string;
  linkedAction?: string;
  linkedType?: TransactionType;
}

export interface TransactionDTO {
  id?: string;
  bankAcctId?: string;
  date?: string;
  payee?: string;
  action?: string;
  // transactionDetails?: Array<TransactionDetailDTO>;
  draftState?: TransactionStatusType;
  in?: number;
  out?: number;
  balance?: number;
  // API
  memo?: string;
  fitId?: string;
  categoryId?: string;
  amount?: number;
  type?: TransactionType;
  createdBy?: TransactionCreatedBy;
  linkedTransaction?: string | null;
  linkedBankAcct?: string | null;
  linkedDirection?: TransactionLinkDirection | null;
  matchedTransactions?: MatchedTransacionDTO[];
  splittedTransactions?: SplitdTransactionDTO[];

  frequency?: FrequencyTypes;
  accountName?: string;
}

export class SplitdTransaction implements Serializable<SplitdTransaction> {
  id: string;
  payee: string;
  memo: string;
  date: string;
  categoryId: string;
  amount: number;
  action?: string;

  bankAcctId?: string;
  accountId?: string;

  linkedTransactionId?: string | '';
  linkedBankAcctId?: string | '';
  linkedDirection: TransactionLinkDirection | '';

  linkedCategoryId?: string;
  linkedPayee?: string;
  linkedMemo?: string;
  linkedAction?: string;
  linkedType?: TransactionType;

  get isInteraccount(): boolean {
    return (this.categoryId && this.categoryId.includes('_interaccount')) || this.linkedBankAcctId !== '';
  }

  deserialize(input: SplitdTransactionDTO): SplitdTransaction {
    if (!input) {
      return this;
    }

    this.id = input.id;
    this.payee = input.payee;
    this.memo = input.memo;
    this.date = input.date;
    this.categoryId = input.categoryId;
    this.amount = input.amount;
    this.action = input.action;
    this.bankAcctId = input.bankAcctId;
    this.linkedTransactionId = input.linkedTransaction || '';
    this.linkedBankAcctId = input.linkedBankAcct || '';
    this.linkedDirection = input.linkedDirection || '';

    this.linkedCategoryId = input.linkedCategoryId || '';
    this.linkedPayee = input.linkedPayee || '';
    this.linkedMemo = input.linkedMemo || '';
    this.linkedAction = input.linkedAction || '';
    this.linkedType = input.linkedType;

    return this;
  }

  deserializeArray(inputArray: SplitdTransactionDTO[]): SplitdTransaction[] {
    const transactions: Array<SplitdTransaction> = inputArray.map((input) =>
      new SplitdTransaction().deserialize(input),
    );
    return transactions;
  }

  serialize(): SplitdTransactionDTO {
    return {
      id: this.id,
      payee: this.payee,
      memo: this.memo,
      date: this.date,
      categoryId: this.categoryId,
      amount: this.amount,
      action: this.action,
      bankAcctId: this.bankAcctId,
      linkedTransaction: this.linkedTransactionId || '',
      linkedBankAcct: this.linkedBankAcctId || this.bankAcctId || '',
      linkedDirection: this.linkedDirection || '',
      linkedCategoryId: this.linkedCategoryId || '',
      linkedPayee: this.linkedPayee || '',
      linkedMemo: this.linkedMemo || '',
      linkedAction: this.linkedAction || '',
      linkedType: this.linkedType,
    };
  }

  clone(): SplitdTransaction {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const clonedInstance: SplitdTransaction = Object.assign(Object.create(Object.getPrototypeOf(this)), this);

    return clonedInstance;
  }
}

export class Transaction implements Serializable<Transaction> {
  id?: string;
  accountId?: string;
  date?: string;
  payee?: string;
  action?: string;
  // transactionDetails?: Array<TransactionDetail>;
  status?: TransactionStatusType;
  in?: number;
  out?: number;
  balance?: number;
  memo?: string;
  fitId?: string;
  categoryId?: string;
  amount?: number;
  type?: TransactionType;
  linkedTransactionId: string | '';
  linkedBankAcctId: string | '';
  linkedDirection: TransactionLinkDirection | '';
  createdBy: TransactionCreatedBy;
  matchedTransactions: MatchedTransacionDTO[];
  splittedTransactions?: SplitdTransaction[];

  schedDayOffset?: number;
  schedMonthOffset?: number;
  schedMonthDays?: number[];
  frequency?: FrequencyTypes;
  accountName?: string;

  deserialize(input: TransactionDTO): Transaction {
    if (!input) {
      return this;
    }

    this.id = input.id || '';
    this.accountId = input.bankAcctId || '';
    this.date = input.date ? this.formatDate(input.date) : '';
    this.payee = input.payee || '';
    this.action = input.action || '';
    // this.transactionDetails = new TransactionDetail().deserializeArray(input.transactionDetails) || [];
    this.status = input.draftState || TransactionStatusType.PENDING;
    this.in = input.in || 0;
    this.out = input.out || 0;
    this.balance = input.balance ? input.balance : null;
    this.memo = input.memo || '';
    this.fitId = input.fitId || '';
    this.categoryId = input.categoryId || '';
    this.amount = input.amount || 0;
    this.type = input.type || TransactionType.OTHER;

    this.frequency = input.frequency;
    this.accountName = input.accountName || '';

    this.linkedTransactionId = input.linkedTransaction || '';
    this.linkedBankAcctId = input.linkedBankAcct || '';
    this.linkedDirection = input.linkedDirection || '';
    this.createdBy = input.createdBy || TransactionCreatedBy.CLIENT;

    this.matchedTransactions = input.matchedTransactions ? input.matchedTransactions : [];
    this.splittedTransactions = new SplitdTransaction().deserializeArray(
      input.splittedTransactions ? input.splittedTransactions : [],
    );

    return this;
  }

  serialize(): TransactionDTO {
    return {
      id: this.id,
      bankAcctId: this.accountId,
      date: this.date,
      payee: this.payee,
      action: this.action,
      draftState: this.status,
      in: this.in,
      out: this.out,
      balance: this.balance,
      memo: this.memo,
      fitId: this.fitId,
      categoryId: this.isSplitted ? undefined : this.categoryId,
      amount: this.amount,
      type: this.type,
      frequency: this.frequency,
      accountName: this.accountName,
      linkedTransaction: this.linkedTransactionId,
      linkedBankAcct: this.linkedBankAcctId,
      linkedDirection: this.linkedDirection as TransactionLinkDirection,
    };
  }

  formatDate(date: string): string {
    return date.substring(0, 10);
  }

  deserializeArray(inputArray: Array<TransactionDTO>): Array<Transaction> {
    const transactions: Array<Transaction> = inputArray.map((input) => new Transaction().deserialize(input));

    return transactions;
  }

  clone(): Transaction {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const clonedInstance: Transaction = Object.assign(Object.create(Object.getPrototypeOf(this)), this);

    return clonedInstance;
  }

  hasCategory(): boolean {
    return this.categoryId && !this.categoryId.includes('_uncategorized');
  }

  generateTransactionToScheduledTransaction(schedTxs: ScheduledTxDTO): Transaction {
    this.id = schedTxs.id || '';
    this.accountId = schedTxs.bankAcctId || '';
    this.date = schedTxs.schedNextDate ? this.formatDate(schedTxs.schedNextDate) : '';
    this.payee = schedTxs.payee || '';
    this.action = schedTxs.action || '';
    this.memo = schedTxs.memo || '';
    this.categoryId = schedTxs.categoryId || '';
    this.amount = schedTxs.amount || 0;
    this.type = schedTxs.type || TransactionType.OTHER;

    this.frequency = this.generateFrequencyBySchedOffset(
      schedTxs.schedDayOffset,
      schedTxs.schedMonthOffset,
      schedTxs.schedMonthDays,
    );

    // this.linkedTransactionId = schedTxs.linkedTransaction || '';
    this.linkedBankAcctId = schedTxs.linkedBankAcct || '';
    this.linkedDirection = schedTxs.linkedDirection || '';
    // this.isAccepted = schedTxs.approve;

    // this.matchedTransactions = schedTxs.matchedTransactions ? schedTxs.matchedTransactions : [];
    this.splittedTransactions = schedTxs.splittedTx
      ? new SplitdTransaction().deserializeArray(schedTxs.splittedTx)
      : [];
    this.status = schedTxs.approve ? TransactionStatusType.ACCEPTED : TransactionStatusType.PENDING;
    return this;
  }

  generateFrequencyBySchedOffset(
    schedDayOffset: number,
    schedMonthOffset: number,
    schedMonthDays: number[],
  ): FrequencyTypes {
    if (schedDayOffset) {
      switch (schedDayOffset) {
        case 1:
          return FrequencyTypes.DAILY;
        case 7:
          return FrequencyTypes.WEEKLY;
        case 14:
          return FrequencyTypes.EVERY_OTHER_WEEK;
        case 28:
          return FrequencyTypes.EVERY_4_WEEKS;
        default:
          return FrequencyTypes.DAILY;
      }
    } else if (schedMonthOffset) {
      switch (schedMonthOffset) {
        case 1:
          return FrequencyTypes.MONTHLY;
        case 3:
          return FrequencyTypes.EVERY_3_MONTHS;
        case 4:
          return FrequencyTypes.EVERY_4_MONTHS;
        case 6:
          return FrequencyTypes.TWICE_YEAR;
        case 12:
          return FrequencyTypes.YEARLY;
        case 24:
          return FrequencyTypes.EVERY_OTHER_YEAR;
        default:
          return FrequencyTypes.DAILY;
      }
    } else if (schedMonthDays) {
      return FrequencyTypes.TWICE_A_MONTH;
    }
    return FrequencyTypes.DAILY;
  }

  generateSchedOffsetByFrequency(
    frequency: FrequencyTypes,
  ): { schedDayOffset: number; schedMonthOffset: number; schedMonthDays: number[] } {
    let schedDayOffset;
    let schedMonthOffset;
    let schedMonthDays;
    switch (frequency) {
      case FrequencyTypes.DAILY:
        schedDayOffset = 1;
        break;
      case FrequencyTypes.WEEKLY:
        schedDayOffset = 7;
        break;
      case FrequencyTypes.EVERY_OTHER_WEEK:
        schedDayOffset = 14;
        break;
      case FrequencyTypes.TWICE_A_MONTH:
        schedMonthDays = [1, 15];
        break;
      case FrequencyTypes.EVERY_4_WEEKS:
        schedDayOffset = 28;
        break;
      case FrequencyTypes.EVERY_3_MONTHS:
        schedMonthOffset = 3;
        break;
      case FrequencyTypes.EVERY_4_MONTHS:
        schedMonthOffset = 4;
        break;
      case FrequencyTypes.MONTHLY:
        schedMonthOffset = 1;
        break;
      case FrequencyTypes.YEARLY:
        schedMonthOffset = 12;
        break;
      case FrequencyTypes.EVERY_OTHER_YEAR:
        schedMonthOffset = 24;
        break;
      case FrequencyTypes.TWICE_YEAR:
        schedMonthOffset = 6;
        break;
      default:
        break;
    }

    return { schedDayOffset, schedMonthOffset, schedMonthDays };
  }

  generateSplitActionFromTransactionAction(splitdTransactionAction: Action, transactionSplittedAction: Action): Action {
    if (splitdTransactionAction) {
      return splitdTransactionAction;
    }

    if (transactionSplittedAction) {
      return ActionFromTransaction.getSplitActionFromTransactionAction(transactionSplittedAction);
    }

    return Action.MONEY_OUT;
  }

  // TODO::not good practice
  generateTransactionFromSplitdTransaction(
    transactionSplitted: Transaction,
    splitdTransaction: SplitdTransaction,
  ): Transaction {
    this.id = splitdTransaction.id;
    this.categoryId = splitdTransaction.categoryId;
    this.payee = splitdTransaction.payee || transactionSplitted.payee || '';
    this.amount = splitdTransaction.amount;
    this.memo = splitdTransaction.memo || '';

    this.date = transactionSplitted.date ? this.formatDate(transactionSplitted.date) : '';
    this.action = this.generateSplitActionFromTransactionAction(
      splitdTransaction.action as Action,
      transactionSplitted.action as Action,
    );

    this.accountId = splitdTransaction.bankAcctId;
    // this.accountId = transactionSplitted.accountId;
    this.linkedBankAcctId = splitdTransaction.linkedBankAcctId;
    this.linkedTransactionId = splitdTransaction.linkedTransactionId;
    this.linkedDirection = splitdTransaction.linkedDirection;

    return this;
  }

  get isInteraccount(): boolean {
    return (this.categoryId && this.categoryId.includes('_interaccount')) || this.linkedBankAcctId !== '';
  }

  get isMatched(): boolean {
    return this.matchedTransactions && this.matchedTransactions.length > 0;
  }

  get isSplitted(): boolean {
    return (
      (this.splittedTransactions && this.splittedTransactions.length > 0) ||
      (this.categoryId && this.categoryId.includes('_splitted'))
    );
  }

  get isAccepted(): boolean {
    return this.status === TransactionStatusType.ACCEPTED;
  }

  get matchedTransaction(): MatchedTransacionDTO {
    if (!this.isMatched) {
      throw new Error('Transaction has no matched transactions');
    }

    const [matchedTransactions] = this.matchedTransactions;

    return matchedTransactions;
  }
}

export interface ScheduledTxDTO {
  id: string;
  authId: string;
  bankAcctId: string;
  categoryId: string;
  amount: number;
  type: TransactionType;
  payee: string;
  memo: string;
  action: string;
  schedInitDate: string;
  schedNextDate: string;
  schedDayOffset: number;
  schedMonthOffset: number;
  schedMonthDays: number[];
  linkedBankAcct: string | null;
  linkedDirection: TransactionLinkDirection;
  linkedCategoryId: string | null;
  linkedPayee: string | null;
  linkedMemo: string | null;
  linkedAction: string | null;
  linkedType: string | null;
  // splittedTx: SplitTxForAll[] | null;
  createdAt: Date;
  updatedAt: Date;
  splittedTx: [];
  approve?: boolean;
}
