import { Expectation } from './expectation';
import { SubCategory } from './subCategory';
import { Transaction } from './transaction';

export enum SpendingPlanModelViewEnum {
  MONEY_IN = 'MONEY_IN',
  SAVINGS_MONEY_IN = 'SAVINGS_MONEY_IN',
  CARD_MONEY_IN = 'CARD_MONEY_IN',
  SAVINGS_MONEY_OUT = 'SAVINGS_MONEY_OUT',
  CARD_MONEY_OUT = 'CARD_MONEY_OUT',
  MONEY_OUT = 'MONEY_OUT',
}
export interface CET {
  category: SubCategory;
  expectation: Expectation;
  transactions: Array<Transaction>;
  totalTransactionsByCategory?: number;
  remainderByCategory?: number;
  valueDate: { [key: string]: { valueCell: number; descriptionCell: string } };
}

export interface Section {
  name: string;
  cets: Array<CET>;
  totalRemainder?: number;
  totalExpectationsAmount?: number;
  totalExpectationsAdjustment?: number;
  totalTransactionsBySection?: number;
}

export class ObjectInOut<T> {
  in: Array<T>;
  out: Array<T>;
}

export type SpendingPlanModelView = {
  [typeMoney in SpendingPlanModelViewEnum]: Array<Section>;
};
