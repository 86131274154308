import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'escapeSlash',
})
export class EscapeSlashPipe implements PipeTransform {
  transform(text: string): string {
    return text.replace(/\s*\/\s*/g, '/ ');
  }
}
