import { SpendingPlanDTO } from 'app/shared/models';
import { TIED_ACCOUNTS } from 'app/shared/mocks/accounts-mocks';
import CurrencyCodes from 'currency-codes';
import { USER_MOCK } from './user-mock';
import { CATEGORIES_ALL_MOCK } from './categories-mocks';

export const SPENDINGPLAN: SpendingPlanDTO = {
  sPlanId: 'sp-01',
  name: 'Jane`s Life',
  tiedAccts: TIED_ACCOUNTS.map((tiedAcct) => tiedAcct.bankAcctId),
  owner: USER_MOCK.profile.authId,
  categories: CATEGORIES_ALL_MOCK,
  tiedAccounts: TIED_ACCOUNTS,
  expectations: [],
};

export const SPENDINGPLANSLIST: SpendingPlanDTO[] = [
  {
    sPlanId: 'sp-01',
    name: 'Jane`s Life',
    isMain: true,
    currency: CurrencyCodes.code('USD').code,
    numberFormat: 'us',
    currencyPlacement: 'before',
    dateFormat: 'us',
    owner: USER_MOCK.profile.authId,
    // tiedAccts: {
    //   debit: ['Bank of America', 'Wells Fargo', 'Chase'],
    //   savings: ['Savings 1', 'Savings 2'],
    //   cash: ['Wallet Money'],
    //   credit: ['Credit Card 1'],
    // },
    tiedAccts: TIED_ACCOUNTS.map((tiedAcct) => tiedAcct.bankAcctId),
    categories: CATEGORIES_ALL_MOCK,
    tiedAccounts: TIED_ACCOUNTS,
    expectations: [],
  },
  {
    sPlanId: 'sp-02',
    name: 'Jane`s Rental Property',
    isMain: false,
    currency: CurrencyCodes.code('USD').code,
    numberFormat: 'us',
    currencyPlacement: 'before',
    dateFormat: 'us',
    owner: USER_MOCK.profile.authId,

    // tiedAccts: {
    //   debit: ['Wells Fargo'],
    //   savings: ['Savings 1'],
    //   cash: ['Wallet Money'],
    //   credit: ['Credit Card 1'],
    // },
    tiedAccts: TIED_ACCOUNTS.map((tiedAcct) => tiedAcct.bankAcctId),
    categories: CATEGORIES_ALL_MOCK,
    tiedAccounts: TIED_ACCOUNTS,
    expectations: [],
  },
];
