import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';

import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { SharedModule } from 'app/shared/shared.module';
import { NgIdleModule } from '@ng-idle/core'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgIdleModule.forRoot(),
    SharedModule.forRoot(),
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private translate: TranslateService) {
    this.initTranslateService();
  }

  private initTranslateService(): void {
    // this.translate.addLangs(['es', 'en']);
    this.translate.addLangs(['en']);
    this.translate.setDefaultLang('en');

    // user browser language
    const browserLang = this.translate.getBrowserLang();
    this.translate.use(browserLang.match(/en/) ? browserLang : 'en');
  }
}
