import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject } from 'rxjs';
import {
  Category,
  CategoryHiddenMapType,
  CategoryHideShowModelView,
  CET,
  HiddenMapType,
  SubCategory,
  SubCategoryDTO,
} from '../models';
import { SpendingPlanService } from './spending-plan.service';

// eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class CategoriesService {
  private readonly apiUrl: string = environment.apiUrl;
  private newSectionSubject = new BehaviorSubject<Category>(null);

  constructor(private http: HttpClient, private spendingPlanService: SpendingPlanService) {}

  createNewSection(newSection: Category): void {
    this.newSectionSubject.next(newSection);
  }

  getNewSection(): BehaviorSubject<Category> {
    return this.newSectionSubject;
  }

  // TODO  Category Class
  async createNewCategoryOnSpendingPlan(newCategory: string, spendingPlanId: string): Promise<string> {
    let newCategoryFetched: string = null;

    if (environment.useMocks) {
      newCategoryFetched = newCategory;
    } else {
      const url = `${this.apiUrl}/client/spendingPlans/${spendingPlanId}/categories`;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
      const response = await this.http.post<{ newCategory: string }>(url, newCategory).toPromise();
      newCategoryFetched = response.newCategory;
    }

    return newCategoryFetched;
  }

  async saveCategoryOnSpendingPlan(subCategory: SubCategory, spendingPlanId: string): Promise<SubCategory> {
    if (subCategory.id) {
      return this.editCategoryOnSpendingPlan(subCategory, spendingPlanId);
    }
    return this.addSubCategoryOnSpendingPlan(subCategory, spendingPlanId);
  }

  async editCategoryOnSpendingPlan(subCategory: SubCategory, spendingPlanId: string): Promise<SubCategory> {
    let subCategoryFetched: SubCategory = null;

    if (environment.useMocks) {
      subCategoryFetched = subCategory;
    } else {
      const url = `${this.apiUrl}/client/spendingPlans/${spendingPlanId}/categories/${subCategory.id}`;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
      const response = await this.http.put<{ category: SubCategoryDTO }>(url, subCategory).toPromise();
      subCategoryFetched = new SubCategory().deserialize(response.category);
    }

    return subCategoryFetched;
  }

  async editSectionOnSpendingPlan(cets: Array<CET>, section: string, spendingPlanId: string): Promise<SubCategory[]> {
    const categoriesEdited: SubCategory[] = [];
    await Promise.all(
      cets.map(async (cet: CET) => {
        const newSubCategory: SubCategory = cet.category.clone();
        newSubCategory.section = section;
        const categoryEdited = await this.editCategoryOnSpendingPlan(newSubCategory, spendingPlanId);
        categoriesEdited.push(categoryEdited);
      }),
    );
    return categoriesEdited;
  }

  async addSubCategoryOnSpendingPlan(subCategory: SubCategory, spendingPlanId: string): Promise<SubCategory> {
    let subCategoryFetched: SubCategory = null;

    if (environment.useMocks) {
      subCategoryFetched = subCategory;
    } else {
      const url = `${this.apiUrl}/client/spendingPlans/${spendingPlanId}/categories`;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
      const { category } = await this.http.post<{ category: SubCategoryDTO }>(url, subCategory).toPromise();
      subCategoryFetched = new SubCategory().deserialize(category);
    }

    return subCategoryFetched;
  }

  async deleteSubCategoriesOnSpendingPlan(
    selectedSubCategoriesIds: Array<string>,
    spendingPlanId: string,
  ): Promise<void> {
    await Promise.all(
      selectedSubCategoriesIds.map(async (categoryId: string) => {
        await this.deleteSubCategoryOnSpendingPlan(categoryId, spendingPlanId);
      }),
    );
    await this.spendingPlanService.deleteCategoriesOfCategoryOrder(selectedSubCategoriesIds);
  }

  async deleteSubCategoryOnSpendingPlan(subCategoryId: string, spendingPlanId: string): Promise<void> {
    if (environment.useMocks) {
      // eslint-disable-next-line no-console
      console.log(':::Deleted SubCategory:::');
    } else {
      const url = `${this.apiUrl}/client/spendingPlans/${spendingPlanId}/categories/${subCategoryId}`;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
      const response = await this.http.delete(url).toPromise();
    }
  }

  async getCategoriesHidden(sPlanId: string, year: number): Promise<HiddenMapType> {
    let categoryMap: CategoryHiddenMapType;
    if (environment.useMocks) {
      //
    } else {
      const url = `${this.apiUrl}/client/spendingPlans/${sPlanId}/categories/hidden?y=${year.toString()}`;
      ({ categoryMap } = await this.http.get<{ categoryMap: CategoryHiddenMapType }>(url).toPromise());
    }
    return categoryMap[year];
  }

  async putCategoryHidden(sPlanId: string, catId: string, year: number, hidden: boolean): Promise<void> {
    if (environment.useMocks) {
      //
    } else {
      const url = `${this.apiUrl}/client/spendingPlans/${sPlanId}/categories/${catId}/hidden`;
      await this.http.put(url, { year, value: hidden }).toPromise();
    }
  }

  async putCategoriesHidden(sPlanId: string, year: number, categories: CategoryHideShowModelView[]): Promise<void> {
    await Promise.all(
      categories.map(async (category: CategoryHideShowModelView) => {
        await this.putCategoryHidden(sPlanId, category.category.id, year, category.hidden);
      }),
    );
  }
}
