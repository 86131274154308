import { Observable, Subject } from 'rxjs';

export interface PayloadPromise {
  promise: () => Promise<any>;
  payload?: any;
}
export interface QueueErrorPromise extends PayloadPromise {
  error: any;
}

export interface CompeltedPromise extends PayloadPromise {
  response: any;
}

export interface OnQueueCompleted {
  completedPromises: Array<CompeltedPromise>;
  errors: Array<QueueErrorPromise>;
}

export class PromiseQueue {
  private completed: Subject<OnQueueCompleted>;
  private runningPromises: Array<PayloadPromise>;
  private completedPromises: Array<CompeltedPromise>;
  private todoPromises: Array<PayloadPromise>;
  private errors: Array<QueueErrorPromise>;

  /**
   * @param {Array<PayloadPromise>} promises - Promises array to handle simultaniously
   * @param {number} [maxConcurrent=1] - Max Concurrent Requests (1 by default)
   */
  constructor(promises: Array<PayloadPromise>, maxConcurrent = 1) {
    this.completed = new Subject<OnQueueCompleted>();

    this.completedPromises = [];
    this.errors = [];
    this.runningPromises = [];
    this.todoPromises = promises;
    this.runningPromises = new Array<PayloadPromise>(maxConcurrent);
  }

  get onComplete(): Observable<OnQueueCompleted> {
    return this.completed.asObservable();
  }

  run(): void {
    if (this.todoPromises.length === 0) {
      this.finish();
    } else {
      for (let i = 0; i < this.runningPromises.length; i += 1) {
        const promise = this.todoPromises.shift();

        if (promise !== undefined) {
          this.handleNewPromise(promise, i);
        }
      }
    }
  }

  private handleNewPromise(payloadPromise: PayloadPromise, index: number): void {
    const { promise, payload } = payloadPromise;

    this.runningPromises[index] = payloadPromise;

    promise()
      .then((response) => {
        this.completedPromises.push({ response, promise, payload });
      })
      .catch((error) => {
        this.errors.push({ error, promise, payload });
      })
      .finally(() => this.handleNextPromise(index));
  }

  private handleNextPromise(index: number): void {
    this.runningPromises[index] = null;
    const promise = this.todoPromises.shift();

    if (promise !== undefined) {
      this.handleNewPromise(promise, index);
      return;
    }

    if (this.runningPromisesIsEmpty()) {
      this.finish();
    }
  }

  private finish(): void {
    const response = {
      completedPromises: this.completedPromises,
      errors: this.errors,
    };

    this.completed.next(response);
    setTimeout(() => {
      this.completed.complete();
    }, 50);
  }

  private runningPromisesIsEmpty(): boolean {
    return this.runningPromises.every((element) => element === null);
  }
}

// const queue = new PromiseQueue([]);

// queue.onComplete.subscribe((result) => {
//   const { completedPromises, errors } = result;

//   // eslint-disable-next-line no-console
//   console.log({ completedPromises, errors });
// });

// queue.run();
