import { Serializable } from 'app/core/interfaces';
import { AuthInfoDTO, UserType } from 'app/modules/auth/models';
import { ClientRoleType, ProfileDTO } from './profile';

export interface PaymentInformation {
  defaultPaymentMethod: string;
  paymentMethods: PaymentMethod[];
}
export interface PaymentMethod {
  brand: string;
  country: string;
  funding: string;
  id: string;
  last4: string;
  expMonth: number;
  expYear: number;
  cardHolderName: string;
}

export interface UserTypesSelectI {
  name: string;
  type: ClientRoleType | UserType;
}

export interface UserDTO {
  auth: AuthInfoDTO;
  profile: ProfileDTO;
}

export class User implements Serializable<User> {
  id: string;
  username: string;
  email: string;
  isEmailValidated: boolean;
  password?: string;
  role: UserType;

  localeLanguage: string;
  avatar: string;
  firstName: string;
  lastName: string;
  telephone?: string;
  hasSubscription: boolean;
  accountPlan: ClientRoleType;

  country: string;
  state: string;
  city: string;
  zipCode: string;

  serialize(): UserDTO {
    return {
      auth: {
        username: this.username,
        email: this.email,
        role: this.role,
        password: this.password,
      },
      profile: {
        authId: this.id,
        username: this.username,
        userPlan: this.accountPlan,

        avatar: this.avatar,
        localeLanguage: this.localeLanguage,
        firstName: this.firstName,
        lastName: this.lastName,
        telephone: this.telephone,

        country: this.country,
        state: this.state,
        city: this.city,
        zipCode: this.zipCode,
      },
    };
  }

  deserialize(input: UserDTO): User {
    if (!input) {
      return this;
    }

    this.id = input.auth.id || '';
    this.username = input.auth.username || '';
    this.email = input.auth.email || '';
    this.isEmailValidated = input.auth.isEmailValidated || false;
    this.password = input.auth.password;
    this.role = input.auth.role;

    if (input.profile) {
      this.avatar = input.profile.avatar || '';
      this.firstName = input.profile.firstName || '';
      this.localeLanguage = input.profile.localeLanguage || '';
      this.lastName = input.profile.lastName || '';
      this.telephone = input.profile.telephone || '';
      this.accountPlan = input.profile.userPlan;

      this.country = input.profile.country || '';
      this.state = input.profile.state || '';
      this.city = input.profile.city || '';
      this.zipCode = input.profile.zipCode || '';
    }

    return this;
  }

  deserializeArray(inputArray: Array<UserDTO>): Array<User> {
    const usersArray: Array<User> = inputArray.map((input) => new User().deserialize(input));

    return usersArray;
  }
}
