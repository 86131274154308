import { ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { TranslateModule } from '@ngx-translate/core';
import { NgxSpinnerModule } from 'ngx-spinner';
import { DndModule } from 'ngx-drag-drop';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ChartsModule } from 'ng2-charts';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgSelectModule } from '@ng-select/ng-select';
import { LoggedInGuard, IsAdminLoggedGuard } from 'app/modules/auth/guards';
import { MaintenanceGuard } from 'app/modules/maintenance/guards';
import { AuthService } from 'app/modules/auth/services';

import { NgbModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

import localeUs from '@angular/common/locales/es-US'; // format 1.000,00
import localeEu from '@angular/common/locales/eu'; // format 1.000,00

import {
  UserService,
  TransactionService,
  AccountService,
  SpendingPlanService,
  CategoriesService,
  ExpectationsService,
  DateFormatService,
  OptionsSPService,
  ExportFileService,
  WorksheetsService,
  CurrencyService,
  CollapseMenuService,
} from './services';

import {
  AppSidebarComponent,
  AppFooterComponent,
  AppBreadcrumbsComponent,
  APP_SIDEBAR_NAV,
  ComingSoonComponent,
  SubscriptionComponent,
  AdminBarComponent,
} from './components';
import { SidebarToggleDirective, NAV_DROPDOWN_DIRECTIVES, InputNumberDirective, CollapseDirective } from './directives';
import { P404PageComponent } from './pages';
import { AppHeaderComponent } from './components/app-header/app-header.component';
import { TokenInterceptorService } from './services/token-interceptor.service';
import { TruncatePipe, NumericFormatPipe, DateFormatPipe, CurrencySymbolPipe, EscapeSlashPipe } from './pipes';
import { SentryErrorHandlerService } from './services/sentry-error-handler.service';

registerLocaleData(localeUs, 'us');
registerLocaleData(localeEu, 'eu');

const PAGES: any[] = [P404PageComponent];

const COMPONENTS: any[] = [
  AppSidebarComponent,
  AppFooterComponent,
  AppBreadcrumbsComponent,
  AppHeaderComponent,
  ...APP_SIDEBAR_NAV,
  ComingSoonComponent,
  SubscriptionComponent,
  AdminBarComponent,
];

const PROVIDERS: Provider[] = [
  AuthService,
  LoggedInGuard,
  IsAdminLoggedGuard,
  MaintenanceGuard,
  UserService,
  TransactionService,
  AccountService,
  SpendingPlanService,
  CategoriesService,
  ExpectationsService,
  DateFormatService,
  OptionsSPService,
  ExportFileService,
  WorksheetsService,
  CurrencyService,
  CollapseMenuService,
  SentryErrorHandlerService,
  // { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true },
];

const MODULES: any[] = [
  RouterModule,
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  FontAwesomeModule,
  NgxSpinnerModule,
  ChartsModule,
  NgxPaginationModule,
  NgSelectModule,
  NgbModule,
  NgbDropdownModule,
  TranslateModule,
  DndModule,
];

const DIRECTIVES: Provider[] = [
  SidebarToggleDirective,
  InputNumberDirective,
  ...NAV_DROPDOWN_DIRECTIVES,
  CollapseDirective,
];

const PIPES: any[] = [TruncatePipe, NumericFormatPipe, DateFormatPipe, CurrencySymbolPipe, EscapeSlashPipe];

@NgModule({
  imports: [...(MODULES as []), HttpClientModule, TranslateModule],
  declarations: [...(PAGES as []), ...(COMPONENTS as []), ...(DIRECTIVES as []), ...(PIPES as [])],
  exports: [...(PAGES as []), ...(COMPONENTS as []), ...(DIRECTIVES as []), ...(MODULES as []), ...(PIPES as [])],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [...PROVIDERS, ...DIRECTIVES],
    } as ModuleWithProviders<SharedModule>;
  }
}
