import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class WindowAlertService {
  confirm(text: string): boolean {
    // eslint-disable-next-line no-alert
    return window.confirm(text);
  }
}
