import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appCollapse]',
})
export class CollapseDirective {
  @Input('appCollapse') typeToCollapse: string;
  @Input() elementParent: HTMLElement;
  @Input() dropdownAll = true;

  private separator = ':*:';

  constructor(private el: ElementRef<HTMLElement>) {}

  @HostListener('click', ['$event'])
  onClick(): void {
    if (!this.el.nativeElement) {
      return;
    }
    const isCollapsed = this.el.nativeElement.classList.contains('btn-collapsed');
    this.el.nativeElement.classList.toggle('btn-collapsed');
    this.elementParent.childNodes.forEach((child: HTMLElement) => {
      if (child && child.hasAttribute && child.getAttribute('aria-controls')) {
        const ariaControlsSplit = child.getAttribute('aria-controls').split(this.separator);
        const index = ariaControlsSplit.indexOf(this.typeToCollapse);

        if (isCollapsed && !this.dropdownAll) {
          // if isCollapsed we want open but we don't want to open all (!dropdownAll)
          if (ariaControlsSplit.length > 1) {
            return;
          }
        }

        if (index !== -1) {
          if (child.classList.contains('collapsed') && this.el.nativeElement.classList.contains('btn-collapsed')) {
            return;
          }

          const childBtn = child.querySelector('button.allow-collapse');
          const childBtnCollapsed = child.querySelector('button.btn-collapsed');
          if (childBtnCollapsed && this.dropdownAll) {
            childBtnCollapsed.classList.toggle('btn-collapsed');
          }
          if (childBtn && !childBtnCollapsed && !isCollapsed) {
            // if !isCollapsed we want to close, we close all
            childBtn.classList.toggle('btn-collapsed');
          }
          child.classList.toggle('collapsed');
        }
      }
    });
  }
}
