import { AccountDTO } from '../models';

export const TIED_ACCOUNTS: AccountDTO[] = [
  {
    bankAcctId: 'tied-1',
    name: 'Bank of america',
    acctType: 'debit',
    status: 'tied',
    balanceAccumulated: 3000,
    balanceDate: '2020-03-12',
    balanceStart: 600,
    postedBalance: 250,
    pendingAmount: 350,
    transactions: [],
    scheduledTransactions: [],
    hasPendingTransactions: true,
    accountIsClosed: false,
  },
  {
    bankAcctId: 'tied-2',
    name: 'Wells Fargo',
    acctType: 'debit',
    status: 'tied',
    balanceAccumulated: 3000,
    balanceDate: '2020-03-12',
    balanceStart: 400,
    postedBalance: 350,
    pendingAmount: 450,
    transactions: [],
    scheduledTransactions: [],
    hasPendingTransactions: false,
    accountIsClosed: false,
  },
  {
    bankAcctId: 'tied-3',
    name: 'Chase',
    acctType: 'debit',
    status: 'tied',
    balanceAccumulated: 3000,
    balanceDate: '2020-03-12',
    balanceStart: 800,
    postedBalance: 150,
    pendingAmount: 650,
    transactions: [],
    scheduledTransactions: [],
    hasPendingTransactions: false,
    accountIsClosed: false,
  },
  {
    bankAcctId: 'tied-4',
    name: 'Savings 1',
    acctType: 'savings',
    status: 'tied',
    balanceAccumulated: 3000,
    balanceDate: '2020-03-12',
    balanceStart: 800,
    postedBalance: 150,
    pendingAmount: 650,
    transactions: [],
    scheduledTransactions: [],
    hasPendingTransactions: true,
    accountIsClosed: false,
  },
  {
    bankAcctId: 'tied-5',
    name: 'Savings 2',
    acctType: 'savings',
    status: 'tied',
    balanceAccumulated: 3000,
    balanceDate: '2020-03-12',
    balanceStart: 800,
    postedBalance: 150,
    pendingAmount: 650,
    transactions: [],
    scheduledTransactions: [],
    hasPendingTransactions: true,
    accountIsClosed: false,
  },
  {
    bankAcctId: 'tied-6',
    name: 'Wallet money',
    acctType: 'cash',
    status: 'tied',
    balanceAccumulated: 3000,
    balanceDate: '2020-03-12',
    balanceStart: 800,
    postedBalance: 150,
    pendingAmount: 650,
    transactions: [],
    scheduledTransactions: [],
    hasPendingTransactions: true,
    accountIsClosed: false,
  },
  {
    bankAcctId: 'tied-7',
    name: 'Credit Card 1',
    acctType: 'credit',
    status: 'tied',
    balanceAccumulated: 3000,
    balanceDate: '2020-03-12',
    balanceStart: 800,
    postedBalance: 150,
    pendingAmount: 650,
    transactions: [],
    scheduledTransactions: [],
    hasPendingTransactions: true,
    accountIsClosed: false,
  },
];
export const UNTIED_ACCOUNTS: AccountDTO[] = [
  // {
  //   bankAcctId: 'untied-1',
  //   name: 'Bank of america',
  //   acctType: 'debit',
  //   status: 'untied',
  //   balanceAccumulated: 3000,
  //   balanceDate: '2020-03-12',
  //   balanceStart: 600,
  //   postedBalance: 250,
  //   pendingAmount: 350,
  //   transactions: [],
  //   scheduledTransactions: [],
  //   hasPendingTransactions: true,
  //   accountIsClosed: false,
  // },
  // {
  //   bankAcctId: 'untied-2',
  //   name: 'Wells Fargo',
  //   acctType: 'debit',
  //   status: 'untied',
  //   balanceAccumulated: 3000,
  //   balanceDate: '2020-03-12',
  //   balanceStart: 400,
  //   postedBalance: 350,
  //   pendingAmount: 450,
  //   transactions: [],
  //   scheduledTransactions: [],
  //   hasPendingTransactions: false,
  //   accountIsClosed: false,
  // },
  // {
  //   bankAcctId: 'untied-3',
  //   name: 'Chase',
  //   acctType: 'debit',
  //   status: 'untied',
  //   balanceAccumulated: 3000,
  //   balanceDate: '2020-03-12',
  //   balanceStart: 800,
  //   postedBalance: 150,
  //   pendingAmount: 650,
  //   transactions: [],
  //   scheduledTransactions: [],
  //   hasPendingTransactions: false,
  //   accountIsClosed: false,
  // },
  // {
  //   bankAcctId: 'untied-4',
  //   name: 'Savings 1',
  //   acctType: 'savings',
  //   status: 'untied',
  //   balanceAccumulated: 3000,
  //   balanceDate: '2020-03-12',
  //   balanceStart: 800,
  //   postedBalance: 150,
  //   pendingAmount: 650,
  //   transactions: [],
  //   scheduledTransactions: [],
  //   hasPendingTransactions: true,
  //   accountIsClosed: false,
  // },
  // {
  //   bankAcctId: 'untied-5',
  //   name: 'Savings 2',
  //   acctType: 'savings',
  //   status: 'untied',
  //   balanceAccumulated: 3000,
  //   balanceDate: '2020-03-12',
  //   balanceStart: 800,
  //   postedBalance: 150,
  //   pendingAmount: 650,
  //   transactions: [],
  //   scheduledTransactions: [],
  //   hasPendingTransactions: true,
  //   accountIsClosed: false,
  // },
  // {
  //   bankAcctId: 'untied-6',
  //   name: 'Wallet money',
  //   acctType: 'cash',
  //   status: 'untied',
  //   balanceAccumulated: 3000,
  //   balanceDate: '2020-03-12',
  //   balanceStart: 800,
  //   postedBalance: 150,
  //   pendingAmount: 650,
  //   transactions: [],
  //   scheduledTransactions: [],
  //   hasPendingTransactions: true,
  //   accountIsClosed: false,
  // },
  // {
  //   bankAcctId: 'untied-7',
  //   name: 'Credit Card 1',
  //   acctType: 'credit',
  //   status: 'untied',
  //   balanceAccumulated: 3000,
  //   balanceDate: '2020-03-12',
  //   balanceStart: 800,
  //   postedBalance: 150,
  //   pendingAmount: 650,
  //   transactions: [],
  //   scheduledTransactions: [],
  //   hasPendingTransactions: true,
  //   accountIsClosed: false,
  // },
];
export const CLOSED_ACCOUNTS: AccountDTO[] = [
  // {
  //   bankAcctId: 'closed-1',
  //   name: 'Bank of america',
  //   acctType: 'debit',
  //   status: 'closed',
  //   balanceAccumulated: 3000,
  //   balanceDate: '2020-03-12',
  //   balanceStart: 600,
  //   postedBalance: 250,
  //   pendingAmount: 350,
  //   transactions: [],
  //   scheduledTransactions: [],
  //   hasPendingTransactions: true,
  //   accountIsClosed: false,
  // },
  // {
  //   bankAcctId: 'closed-2',
  //   name: 'Wells Fargo',
  //   acctType: 'debit',
  //   status: 'closed',
  //   balanceAccumulated: 3000,
  //   balanceDate: '2020-03-12',
  //   balanceStart: 400,
  //   postedBalance: 350,
  //   pendingAmount: 450,
  //   transactions: [],
  //   scheduledTransactions: [],
  //   hasPendingTransactions: false,
  //   accountIsClosed: false,
  // },
  // {
  //   bankAcctId: 'closed-3',
  //   name: 'Chase',
  //   acctType: 'debit',
  //   status: 'closed',
  //   balanceAccumulated: 3000,
  //   balanceDate: '2020-03-12',
  //   balanceStart: 800,
  //   postedBalance: 150,
  //   pendingAmount: 650,
  //   transactions: [],
  //   scheduledTransactions: [],
  //   hasPendingTransactions: false,
  //   accountIsClosed: false,
  // },
  // {
  //   bankAcctId: 'closed-4',
  //   name: 'Savings 1',
  //   acctType: 'savings',
  //   status: 'closed',
  //   balanceAccumulated: 3000,
  //   balanceDate: '2020-03-12',
  //   balanceStart: 800,
  //   postedBalance: 150,
  //   pendingAmount: 650,
  //   transactions: [],
  //   scheduledTransactions: [],
  //   hasPendingTransactions: true,
  //   accountIsClosed: false,
  // },
  // {
  //   bankAcctId: 'closed-5',
  //   name: 'Savings 2',
  //   acctType: 'savings',
  //   status: 'closed',
  //   balanceAccumulated: 3000,
  //   balanceDate: '2020-03-12',
  //   balanceStart: 800,
  //   postedBalance: 150,
  //   pendingAmount: 650,
  //   transactions: [],
  //   scheduledTransactions: [],
  //   hasPendingTransactions: true,
  //   accountIsClosed: false,
  // },
  // {
  //   bankAcctId: 'closed-6',
  //   name: 'Wallet money',
  //   acctType: 'cash',
  //   status: 'closed',
  //   balanceAccumulated: 3000,
  //   balanceDate: '2020-03-12',
  //   balanceStart: 800,
  //   postedBalance: 150,
  //   pendingAmount: 650,
  //   transactions: [],
  //   scheduledTransactions: [],
  //   hasPendingTransactions: true,
  //   accountIsClosed: false,
  // },
  // {
  //   bankAcctId: 'closed-7',
  //   name: 'Credit Card 1',
  //   acctType: 'credit',
  //   status: 'closed',
  //   balanceAccumulated: 3000,
  //   balanceDate: '2020-03-12',
  //   balanceStart: 800,
  //   postedBalance: 150,
  //   pendingAmount: 650,
  //   transactions: [],
  //   scheduledTransactions: [],
  //   hasPendingTransactions: true,
  //   accountIsClosed: false,
  // },
];
