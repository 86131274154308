import { Serializable } from 'app/core/interfaces';

export enum TypeTasks {
  NEED = 'need',
  WANT = 'want',
  ACTIONPLAN = 'actionplan',
}

export enum TypeNeedsWants {
  NEED = 'Need',
  WANT = 'Want',
}

export class Worksheets {
  needs: NeedsWants[];
  wants: NeedsWants[];
  actionPlan: ActionPlan[];
}

export class NeedsWants implements Serializable<NeedsWants> {
  id: string;
  dateEntered: string;
  dateCompleted: string;
  name: string;
  cost: number;
  isArchived: boolean;
  type: TypeNeedsWants;

  constructor() {
    this.id = '';
    this.dateEntered = '';
    this.name = '';
    this.cost = 0;
    this.dateCompleted = null;
    this.isArchived = false;

    return this;
  }

  deserialize(input: TaskDTO): NeedsWants {
    if (!input) {
      return this;
    }

    this.id = input.id;
    this.dateEntered = input.startDate ? this.formatDate(input.startDate) : '';
    this.name = input.name;
    this.cost = input.cost;
    this.dateCompleted = input.completedAt ? this.formatDate(input.completedAt) : '';
    this.isArchived = input.completed;
    this.type = input.type === TypeTasks.WANT ? TypeNeedsWants.WANT : TypeNeedsWants.NEED;

    return this;
  }

  deserializeArray(inputArray: Array<TaskDTO>): Array<NeedsWants> {
    const needsWants: Array<NeedsWants> = inputArray.map((input) => new NeedsWants().deserialize(input));

    return needsWants;
  }

  formatDate(date: string): string {
    return date.substring(0, 10);
  }
}

export class ActionPlan {
  id: string;
  dateEntered: string;
  dateCompleted: string;
  name: string;
  note: string;
  isArchived: boolean;

  constructor() {
    this.id = '';
    this.dateEntered = '';
    this.name = '';
    this.note = '';
    this.dateCompleted = null;
    this.isArchived = false;

    return this;
  }

  deserialize(input: TaskDTO): ActionPlan {
    if (!input) {
      return this;
    }

    this.id = input.id;
    this.dateEntered = input.startDate ? this.formatDate(input.startDate) : '';
    this.name = input.name;
    this.note = input.description;
    this.dateCompleted = input.completedAt ? this.formatDate(input.completedAt) : '';
    this.isArchived = input.completed;
    // this.type = input.type;

    return this;
  }

  deserializeArray(inputArray: Array<TaskDTO>): Array<ActionPlan> {
    const actionPlan: Array<ActionPlan> = inputArray.map((input) => new ActionPlan().deserialize(input));

    return actionPlan;
  }

  formatDate(date: string): string {
    return date.substring(0, 10);
  }
}

export class Task {
  id: string;
  type: TypeTasks;
  name: string;
  startDate: string;
  description?: string;
  cost?: number;
  completed?: boolean;
  createdAt?: string;
  completedAt: string | null;
}

export class TaskDTO {
  id: string;
  authId: string;
  type: TypeTasks;
  startDate: string;
  name: string;
  description: string;
  cost: number;
  completed: boolean;
  completedAt: string | null;
  createdAt: string;
  updatedAt: string;
}
