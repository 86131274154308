import { ExpectationDTO } from '../models/expectation';

export const EXPECTED_AMOUNT_MOCK: ExpectationDTO = {
  amount: 10,
  adjustment: 1,
  categoryId: '',
  id: '',
  // periodYear: 0,
  // periodMonth: 0,
};

export const EXPECTED_AMOUNTS_MOCK: Array<ExpectationDTO> = [
  EXPECTED_AMOUNT_MOCK,
  EXPECTED_AMOUNT_MOCK,
  EXPECTED_AMOUNT_MOCK,
  EXPECTED_AMOUNT_MOCK,
  EXPECTED_AMOUNT_MOCK,
  EXPECTED_AMOUNT_MOCK,
];
