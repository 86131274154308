<div class="coming-soon mt-5 px-5">
  <div class="coming-soon-container text-center">
    <div class="w-80 m-auto">
      <div class="image-container">
        <img src="/assets/images/logo-eslogan.png" alt="Moneygrit Logo">
      </div>

      <h2 class="color-primary-purple-blue font-primary-bold font-size-xxxl my-4">
        {{ functionality }} {{ 'functionality will be available soon' | translate }}
      </h2>

      <p class="font-size-xl mt-5">
        {{ 'Description_of_coming_soon' | translate }}.
      </p>
      <p class="font-size-xl">
        {{ 'In the meantime, please visit our social networks to receive the latest news from the platform' | translate }}.
      </p>
      <p class="font-size-xl">
        {{ 'Sincerely' | translate }},
      </p>
      <p class="font-size-xl color-primary-purple-blue font-primary-bold mt-5">
        {{ 'The MoneyGrit Team' | translate }}.
      </p>
    </div>
  </div>
</div>
