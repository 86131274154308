import { Serializable } from 'app/core/interfaces';
import { CategoryType } from './subCategory';

// export enum CategoryType {
//   MONEY_IN = 'IN',
//   MONEY_OUT = 'OUT',
// }
export interface CategoryDTO {
  id: string;
  name?: string;
  // type: CategoryType;
  // section: string;
  description?: string;
}

export class Category implements Serializable<Category> {
  id?: string;
  name?: string;
  type?: CategoryType;
  // section: string;
  description?: string;

  deserialize(input: CategoryDTO): Category {
    if (!input) {
      return this;
    }

    this.id = input.id || '';
    this.name = input.name || '';
    this.description = input.description || '';
    // this.type = input.type;
    // this.section = input.section || '';

    return this;
  }

  deserializeArray(inputArray: Array<CategoryDTO>): Array<Category> {
    const categories: Array<Category> = inputArray.map((input) => new Category().deserialize(input));

    return categories;
  }
}
