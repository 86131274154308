import { faChartBar } from '@fortawesome/free-regular-svg-icons';
import {
  faFileInvoiceDollar,
  faFileMedicalAlt,
  faMoneyCheck,
  faTable,
  faGraduationCap,
} from '@fortawesome/free-solid-svg-icons';
import { NavItemI } from 'app/core/interfaces';

import { environment } from 'environments/environment';

export const navigation: Array<NavItemI> = [
  // Admin nav items
  {
    name: 'Accounts',
    url: '/accounts',
    icon: faMoneyCheck,
  },
  {
    name: 'Spending Plans',
    url: '/spending-plans',
    icon: faTable,
    responsive: true,
  },
  {
    name: 'Needs & Wants',
    url: '/worksheets',
    icon: faFileInvoiceDollar,
  },
  {
    name: 'Reports',
    url: '/reports',
    icon: faChartBar,
  },
  {
    name: 'MG. Academy',
    url: 'https://app.searchie.io/hub/xzRGXzgV70',
    icon: faGraduationCap,
    target: '_blank',
  },
  {
    name: 'Test',
    url: '/test',
    hidden: !environment.development,
    icon: faFileMedicalAlt,
  },
];
