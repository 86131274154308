import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyService } from '../services';

@Pipe({
  name: 'currencySymbol',
})
export class CurrencySymbolPipe implements PipeTransform {
  constructor(private currencyService: CurrencyService) {}

  transform(code: string, format: 'wide' | 'narrow' = 'narrow', locale?: string): any {
    return this.currencyService.getSymbol(code, format, locale);
  }
}
