import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import moment from 'moment';
import { ActionPlan, NeedsWants, Task, TaskDTO, TypeNeedsWants, TypeTasks, Worksheets } from '../models';

@Injectable({
  providedIn: 'root',
})
export class WorksheetsService {
  private readonly apiUrl = environment.apiUrl;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private http: HttpClient) {}

  async getWorksheets(): Promise<Worksheets> {
    let worksheetsFetched = new Worksheets();
    if (environment.useMocks) {
      // environment.useMocks
    } else {
      const url = `${this.apiUrl}/client/tasks/`;
      const { tasks } = await this.http.get<{ tasks: TaskDTO[] }>(url, this.httpOptions).toPromise();
      const needs: NeedsWants[] = [];
      const wants: NeedsWants[] = [];
      const actionPlan: ActionPlan[] = [];
      tasks.forEach((task: TaskDTO) => {
        switch (task.type) {
          case TypeTasks.NEED:
            needs.push(new NeedsWants().deserialize(task));
            break;
          case TypeTasks.WANT:
            wants.push(new NeedsWants().deserialize(task));
            break;
          case TypeTasks.ACTIONPLAN:
            actionPlan.push(new ActionPlan().deserialize(task));
            break;
          default:
            break;
        }
      });
      const worksheets: Worksheets = {
        needs,
        wants,
        actionPlan,
      };
      worksheetsFetched = worksheets;
    }
    return worksheetsFetched;
  }

  async saveNeedWants(newNeedWants: NeedsWants, type: TypeTasks, isArchived: boolean = false): Promise<NeedsWants> {
    let needsWantsFetched: NeedsWants = null;

    if (environment.useMocks) {
      // environment.useMocks
    } else {
      const completedAt = this.dateCompletedValid(newNeedWants.dateCompleted);

      const task: Task = {
        id: newNeedWants.id,
        name: newNeedWants.name,
        type,
        cost: newNeedWants.cost,
        startDate: newNeedWants.dateEntered.toString(),
        completed: isArchived,
        completedAt,
      };
      if (task.id) {
        needsWantsFetched = new NeedsWants().deserialize(await this.updateTask(task));
      } else {
        needsWantsFetched = new NeedsWants().deserialize(await this.createTask(task));
      }
    }

    return needsWantsFetched;
  }

  async saveActionPlan(newActionPlan: ActionPlan, isArchived: boolean = false): Promise<ActionPlan> {
    let actionPlanFetched: ActionPlan = null;

    if (environment.useMocks) {
      // environment.useMocks
    } else {
      const completedAt = this.dateCompletedValid(newActionPlan.dateCompleted);

      const task: Task = {
        id: newActionPlan.id,
        name: newActionPlan.name,
        type: TypeTasks.ACTIONPLAN,
        description: newActionPlan.note,
        startDate: newActionPlan.dateEntered.toString(),
        completed: isArchived,
        completedAt,
      };
      if (task.id) {
        actionPlanFetched = new ActionPlan().deserialize(await this.updateTask(task));
      } else {
        actionPlanFetched = new ActionPlan().deserialize(await this.createTask(task));
      }
    }

    return actionPlanFetched;
  }

  dateCompletedValid(dateCompleted: string): string {
    let completedAt = null;
    const date = moment(dateCompleted, 'YYYY-MM-DD', true);
    if (date.isValid()) {
      completedAt = dateCompleted.toString();
    }

    return completedAt;
  }

  async createTask(task: Task): Promise<TaskDTO> {
    const url = `${this.apiUrl}/client/tasks/`;
    // eslint-disable-next-line no-param-reassign
    delete task.completedAt;
    const { task: newTask } = await this.http.post<{ task: TaskDTO }>(url, task).toPromise();
    return newTask;
  }

  async updateTask(task: Task): Promise<TaskDTO> {
    const url = `${this.apiUrl}/client/tasks/${task.id}`;
    const { task: updateTask } = await this.http.put<{ task: TaskDTO }>(url, task).toPromise();
    return updateTask;
  }

  async archiveUnarchiveNeedsWants(arrayNeedWants: NeedsWants[]): Promise<void> {
    await Promise.all(
      arrayNeedWants.map(async (needWants: NeedsWants) => {
        const type = needWants.type === TypeNeedsWants.NEED ? TypeTasks.NEED : TypeTasks.WANT;
        await this.saveNeedWants(needWants, type, !needWants.isArchived);
      }),
    );
  }

  async archiveUnarchiveActionPlan(arrayActionPlan: ActionPlan[]): Promise<void> {
    await Promise.all(
      arrayActionPlan.map(async (actionPlan: ActionPlan) => {
        await this.saveActionPlan(actionPlan, !actionPlan.isArchived);
      }),
    );
  }

  async deleteTask(taskId: string): Promise<void> {
    const url = `${this.apiUrl}/client/tasks/${taskId}`;
    await this.http.delete<any>(url).toPromise();
  }

  async deleteNeedsWants(needsWants: NeedsWants[]): Promise<void> {
    await Promise.all(
      needsWants.map(async (needWant: NeedsWants) => {
        await this.deleteTask(needWant.id);
      }),
    );
  }

  async deleteActionPlans(actionPlans: ActionPlan[]): Promise<void> {
    await Promise.all(
      actionPlans.map(async (actionPlan: ActionPlan) => {
        await this.deleteTask(actionPlan.id);
      }),
    );
  }
}
