import { Serializable } from 'app/core/interfaces';
import type { SpendingPlan } from './spendingPlan';
import { Transaction, TransactionDTO } from './transaction';

export interface AccountSelectedI {
  selectedAccount: string;
  accountGroup: string;
}

export enum AccountType {
  DEBIT = 'CHECKING',
  SAVINGS = 'SAVINGS',
  CASH = 'CASH',
  CREDIT = 'CREDITLINE',
  INVESTMENT = 'MONEYMRKT',
}

export enum AccountGroup {
  TIED = 'tied',
  UNTIED = 'untied',
  CLOSED = 'closed',
}

export interface AccountDTO {
  bankAcctId?: string;
  name?: string;
  acctType?: string;
  status?: string;
  balanceStart?: number;
  balanceDate?: string;
  balanceAccumulated?: number;
  postedBalance?: number;
  pendingAmount?: number;
  transactions?: TransactionDTO[];
  scheduledTransactions?: TransactionDTO[];
  hasPendingTransactions?: boolean;
  accountIsClosed?: boolean;
  spendingPlan?: string;
  currency?: string;
  isLocked?: boolean;
}

export class Account implements Serializable<Account> {
  id: string;
  name?: string;
  type?: string;
  status?: string;
  openingBalance?: number;
  openingBalanceDate?: string;
  currentBalance?: number;
  postedBalance?: number;
  pendingTransactionsBalance?: number;
  transactions?: Transaction[];
  scheduledTransactions?: Transaction[];
  hasPendingTransactions?: boolean;
  accountIsClosed?: boolean;
  spendingPlan?: SpendingPlan;
  spendingPlanId?: string;
  currency?: string;
  isLocked: boolean;
  group: AccountGroup | '';

  constructor() {
    this.id = '';
    this.name = '';
    this.type = '';
    this.status = '';
    this.openingBalance = null;
    this.openingBalanceDate = '';
    this.currentBalance = null;
    this.postedBalance = null;
    this.pendingTransactionsBalance = null;
    this.transactions = [];
    this.scheduledTransactions = [];
    this.hasPendingTransactions = false;
    this.accountIsClosed = false;
    this.spendingPlan = null;
    this.currency = '';
  }

  serialize(): AccountDTO {
    return {
      bankAcctId: this.id,
      name: this.name,
      acctType: this.type,
      status: this.status,
      balanceStart: this.openingBalance,
      balanceDate: this.openingBalanceDate,
      balanceAccumulated: this.currentBalance,
      postedBalance: this.postedBalance,
      pendingAmount: this.pendingTransactionsBalance,
      // transactions: this.transactions,
      // scheduledTransactions: this.scheduledTransactions,
      hasPendingTransactions: this.hasPendingTransactions,
      accountIsClosed: this.accountIsClosed,
      spendingPlan: this.spendingPlanId,
      currency: this.currency,
    };
  }

  deserialize(input: AccountDTO): Account {
    if (!input) {
      return this;
    }
    this.id = input.bankAcctId || '';
    this.name = input.name || '';
    this.type = input.acctType || '';
    this.status = input.status || AccountGroup.TIED;
    this.openingBalance = input.balanceStart || 0;
    this.openingBalanceDate = input.balanceDate ? this.formatDate(input.balanceDate) : '';
    this.currentBalance = input.balanceAccumulated || 0;
    this.postedBalance = input.postedBalance || 0;
    this.pendingTransactionsBalance = input.pendingAmount || 0;
    this.scheduledTransactions = input.scheduledTransactions
      ? new Transaction().deserializeArray(input.scheduledTransactions)
      : [];
    this.hasPendingTransactions = input.hasPendingTransactions;
    this.accountIsClosed = input.accountIsClosed;
    this.spendingPlanId = input.spendingPlan || '';
    this.currency = input.currency || '';
    this.isLocked = input.isLocked || false;
    this.group = '';

    return this;
  }

  formatDate(date: string): string {
    return date.substring(0, 10);
  }

  deserializeArray(inputArray: Array<AccountDTO>): Array<Account> {
    const Accounts: Array<Account> = inputArray?.map((input) => new Account().deserialize(input));
    return Accounts;
  }
}
