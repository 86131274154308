<ng-container [formGroup]="form">
  <div class="container-plan p-3 mb-2" *ngIf="showSubscription(PeriodType.ANNUAL)">
    <input
      *ngIf="chooseSubscription"
      class="form-check-input ml-2"
      type="radio"
      name="planPerYear"
      id="year"
      [value]="true"
      formControlName="planPerYear"
    />
    <div class="pl-2">
      <p class="font-size-xl font-primary-bold">
        {{ 'Price_per_year' | translate: paramPricePerYear }}
      </p>
      <p *ngIf="chooseSubscription; else textStatusSubscription" class="m-0">
        {{ 'Best Value' | translate }}
      </p>
    </div>
  </div>
  <div class="container-plan p-3 mb-2" *ngIf="showSubscription(PeriodType.MONTHLY)">
    <input
      *ngIf="chooseSubscription"
      class="form-check-input ml-2"
      type="radio"
      name="planPerYear"
      id="month"
      [value]="false"
      formControlName="planPerYear"
    />
    <div class="pl-2">
      <p class="font-size-xl font-primary-bold">
        {{ 'Price_per_month' | translate: paramPricePerMonth }}
      </p>
      <p *ngIf="chooseSubscription; else textStatusSubscription" class="m-0">
        {{ 'Billed Monthly' | translate }}
      </p>
    </div>
  </div>
  <div class="container-plan p-3 mb-2" *ngIf="showSubscription(PeriodType.CUSTOM)">
    <input
      *ngIf="chooseSubscription"
      class="form-check-input ml-2"
      type="radio"
      name="planPerYear"
      id="month"
      [value]="false"
      formControlName="planPerYear"
    />
    <div class="pl-2">
      <p class="font-size-xl font-primary-bold">
        {{ 'Your Subscription is Set to Expire' | translate }}
      </p>
      <ng-container [ngTemplateOutlet]="textStatusSubscription"></ng-container>
    </div>
  </div>
</ng-container>

<ng-template #textStatusSubscription>
  <p class="m-0 mb-3">
    {{ 'Your subscription is: ' | translate }}
    <span class="status-subscription" [ngClass]="{ active: subscriptionActive }">{{
      stateSubscription | translate
    }}</span>
  </p>
  <p>{{ descriptionPlan | translate: paramDate }}</p>
</ng-template>
