import { Serializable } from 'app/core/interfaces';
import moment from 'moment';

export enum PeriodType {
  ANNUAL = 'annual',
  MONTHLY = 'monthly',
  CUSTOM = 'custom',
}

export enum PlanType {
  PERSONAL = 'personal',
  BUSINESS = 'business',
}

/*
 * StateType:
 *   - active: active.
 *   - canceled: canceled.
 *   - incomplete: if the initial payment attempt fails.
 *   - incomplete_expired: if the first invoice is not paid within 23 hours.
 *   - past_due: when its invoice is not paid by the due date.
 *   - trialing: a subscription that is currently in a trial period.
 *   - unpaid: when still not paid by an additional deadline after due date.
 */
export enum StateType {
  INCOMPLETE = 'incomplete', // INCOMPLETE
  ACTIVE = 'active', // ACTIVE
  EXPIRED = 'expired', // INACTIVE
  CANCELED = 'canceled', // INACTIVE
  INCOMPLETE_EXPIRED = 'incomplete_expired', // INACTIVE
  PAST_DUE = 'past_due', // ACTIVE
  TRIALING = 'trialing', // ACTIVE
  UNPAID = 'unpaid', // INACTIVE
}

export enum PaymentIntentStatus {
  REQUIRES_PAYMENT_METHOD = 'requires_payment_method',
  REQUIRES_CONFIRMATION = 'requires_confirmation',
  REQUIRES_ACTION = 'requires_action',
  PROCESSING = 'processing',
  REQUIRES_CAPTURE = 'requires_capture',
  CANCELED = 'canceled',
  SUCCEEDED = 'succeeded',
}

export enum CancelAtPerdioEndRequestType {
  CANCEL = 'cancel',
  REACTIVATE = 'reactivate',
}

export interface PaymentAccountDTO {
  authId: string;
  lastSubscription: SubscriptionDTO;
  stripeCustomerId: string;
  stripeDefaultPaymentMethodId: string;
}

export interface SubscriptionDTO {
  subId: string;
  owner: string;
  plan: PlanType;
  interval: PeriodType;
  status: StateType;
  periodStart: string;
  periodEnd: string;
  cancelAtPeriodEnd: boolean;
  cancelAtPeriodEndRequest?: CancelAtPerdioEndRequestType;
  cancelAtPeriodEndDate?: string;
  paymentIntentId?: string;
  paymentIntentStatus?: PaymentIntentStatus;
  paymentIntentNextActionType?: string;
  paymentIntentClientSecret?: string;
  // invoiceId?: string;
  // invoiceStatus?: string;
}

export class Subscription implements Serializable<Subscription> {
  subId: string;
  owner: string;
  plan: PlanType;
  period: PeriodType;
  status: StateType;
  periodStart: Date;
  periodEnd: Date;
  cancelAtPeriodEnd: boolean;
  paymentIntentId: string;
  paymentIntentStatus: PaymentIntentStatus;
  paymentIntentNextActionType: string;
  paymentIntentClientSecret: string;
  cancelAtPeriodEndRequest?: string;
  cancelAtPeriodEndDate?: Date;

  constructor(subscription?: Subscription) {
    if (!subscription) {
      return;
    }

    this.subId = subscription.subId;
    this.owner = subscription.owner;
    this.plan = subscription.plan;
    this.period = subscription.period;
    this.status = subscription.status;
    this.periodStart = subscription.periodStart;
    this.periodEnd = subscription.periodEnd;
    this.cancelAtPeriodEnd = subscription.cancelAtPeriodEnd;
    this.paymentIntentId = subscription.paymentIntentId;
    this.paymentIntentStatus = subscription.paymentIntentStatus;
    this.paymentIntentNextActionType = subscription.paymentIntentNextActionType;
    this.paymentIntentClientSecret = subscription.paymentIntentClientSecret;
    this.cancelAtPeriodEndRequest = subscription.cancelAtPeriodEndRequest;
  }

  deserialize(input: SubscriptionDTO): Subscription {
    if (!input) {
      return this;
    }

    this.subId = input.subId || '';
    this.owner = input.owner || '';
    this.plan = input.plan || PlanType.PERSONAL;
    this.period = input.interval || PeriodType.CUSTOM;
    this.status = input.status || StateType.ACTIVE;
    this.periodStart = input.periodStart ? new Date(input.periodStart) : null;
    this.periodEnd = input.periodEnd ? new Date(input.periodEnd) : null;
    this.cancelAtPeriodEnd = input.cancelAtPeriodEnd || false;
    this.paymentIntentId = input.paymentIntentId || '';
    this.paymentIntentStatus = input.paymentIntentStatus || PaymentIntentStatus.SUCCEEDED;
    this.paymentIntentNextActionType = input.paymentIntentNextActionType || '';
    this.paymentIntentClientSecret = input.paymentIntentClientSecret || '';
    this.cancelAtPeriodEndRequest = input.cancelAtPeriodEndRequest || CancelAtPerdioEndRequestType.REACTIVATE;

    return this;
  }

  deserializeArray(inputArray: Array<SubscriptionDTO>): Array<Subscription> {
    const SubscriptionsArray: Array<Subscription> = inputArray.map((input) => new Subscription().deserialize(input));

    return SubscriptionsArray;
  }

  // #### NEW TESTED GETS ######################################################

  get hasSubscription(): boolean {
    return !!this.subId && this.isActive;
  }

  get subscriptionStarted(): boolean {
    const isSameOrAfter = moment().isSameOrAfter(this.periodStart);

    return this.hasSubscription && isSameOrAfter;
  }

  get subscriptionExpired(): boolean {
    const isAfter = moment().isAfter(this.periodEnd);
    return this.hasSubscription && isAfter;
  }

  get renewDate(): string {
    let renewDate = moment(this.periodEnd);

    if (this.isPayment && !this.isInactive && !this.cancelAtPeriodEnd) {
      renewDate = moment(this.periodStart);

      if (this.period === PeriodType.MONTHLY) {
        do {
          renewDate.add(1, 'months');
        } while (renewDate < moment());
      } else if (this.period === PeriodType.ANNUAL) {
        do {
          renewDate.add(1, 'years');
        } while (renewDate < moment());
      }
    }

    return renewDate.format('YYYY-MM-DD');
  }

  // #### TYPE #################################################################

  get isCustom(): boolean {
    return this.period === PeriodType.CUSTOM;
  }

  get isPayment(): boolean {
    return this.period === PeriodType.MONTHLY || this.period === PeriodType.ANNUAL;
  }

  // #### STATUS #################################################################

  get isActive(): boolean {
    return this.status === StateType.ACTIVE || this.status === StateType.TRIALING;
  }

  get isValid(): boolean {
    let result = false;

    if (this.isCustom) {
      result = this.isActive && this.subscriptionStarted && !this.subscriptionExpired;
    } else if (this.isPayment) {
      result = this.isActive;
    }

    return result;
  }

  get isInactive(): boolean {
    if (this.isPayment) {
      return (
        this.status === StateType.CANCELED ||
        this.status === StateType.EXPIRED ||
        this.status === StateType.INCOMPLETE_EXPIRED ||
        this.status === StateType.UNPAID ||
        this.status === StateType.PAST_DUE
      );
    }

    if (this.isCustom) {
      return !this.isValid;
    }

    return true;
  }

  get isIncomplete(): boolean {
    return this.status === StateType.INCOMPLETE;
  }

  get isPastDue(): boolean {
    return this.status === StateType.PAST_DUE;
  }

  get isProcessingCancelAtPeriodEnd(): boolean {
    return this.cancelAtPeriodEndRequest === CancelAtPerdioEndRequestType.CANCEL && this.cancelAtPeriodEnd === false;
  }
  // ###########################################################################
}

export class PaymentAccount implements Serializable<PaymentAccount> {
  authId: string;
  stripeCustomerId: string;
  stripeDefaultPaymentMethodId: string;

  lastSubscription: Subscription;

  deserialize(input: PaymentAccountDTO): PaymentAccount {
    if (!input) {
      return this;
    }

    this.authId = input.authId || '';
    this.stripeCustomerId = input.stripeCustomerId || '';
    this.stripeDefaultPaymentMethodId = input.stripeDefaultPaymentMethodId || '';
    this.lastSubscription = new Subscription().deserialize(input.lastSubscription);

    return this;
  }

  formatDate(date: string): string {
    return date.substring(0, 10);
  }

  deserializeArray(inputArray: Array<PaymentAccountDTO>): Array<PaymentAccount> {
    const SubscriptionsArray: Array<PaymentAccount> = inputArray.map((input) =>
      new PaymentAccount().deserialize(input),
    );

    return SubscriptionsArray;
  }
}
