import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CollapseMenuService {
  private readonly collapseMenuSubject = new BehaviorSubject<boolean>(false);
  readonly collapseMenu$ = this.collapseMenuSubject.asObservable();

  get collapseMenu(): boolean {
    return this.collapseMenuSubject.getValue();
  }

  setCollapseMenuSubject(collapseMenu: boolean): void {
    this.collapseMenuSubject.next(collapseMenu);
  }
}
