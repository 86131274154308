import { Serializable } from 'app/core/interfaces';

export enum UserType {
  ADMIN = 'admin',
  CLIENT = 'client',
}

export class AuthInfoDTO {
  id?: string;
  username: string;
  email: string;
  role: UserType;
  isEmailValidated?: boolean;
  password?: string;
}

export class AuthInfo implements Serializable<AuthInfo> {
  id: string;
  email: string;
  username: string;
  isEmailValidated: boolean;
  role: UserType;
  password?: string;

  serialize(): AuthInfoDTO {
    return {
      id: this.id,
      username: this.username,
      email: this.email,
      role: this.role,
      isEmailValidated: this.isEmailValidated,
      password: this.password,
    };
  }

  deserialize(input: AuthInfoDTO): AuthInfo {
    if (!input) {
      return this;
    }

    this.id = input.id || '';
    this.email = input.email || '';
    this.username = input.username || input.email || '';
    this.isEmailValidated = input.isEmailValidated || false;
    this.role = input.role;

    return this;
  }

  deserializeArray(inputArray: Array<AuthInfoDTO>): Array<AuthInfo> {
    const usersArray: Array<AuthInfo> = inputArray.map((input) => new AuthInfo().deserialize(input));

    return usersArray;
  }
}
