import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from 'app/modules/auth/services';

import { CurrencyService } from '../services';

@Pipe({
  name: 'numericFormat',
})
export class NumericFormatPipe implements PipeTransform {
  constructor(private authService: AuthService, private currencyService: CurrencyService) {}

  transform(value: number, currencyCode: string, acceptZero: boolean = false): string {
    const locale = this.authService.getCurrentSession().user.localeLanguage || 'us';
    const currencySymbol = this.currencyService.getSymbol(currencyCode);

    if ((!acceptZero && !value) || (acceptZero && !value && value !== 0)) {
      return '';
    }
    if (!currencyCode) {
      return this.currencyService.getFormat(value, locale);
    }
    return this.currencyService.getFormat(value, locale, currencySymbol, currencyCode);
  }
}
