export const environment = {
  production: false,
  demo: false,
  staging: true,
  development: false,
  useMocks: false,
  maintenance: false,
  sessionKey: 'MONEYGRIT_BO_SESSION',
  apiUrl: 'https://api.moneygrit.proj.nakimasolutions.com/api/v1',
  sentry: {
    dsn: 'https://34fe7e7991ca40f0b23b792cfbf91699@o64931.ingest.sentry.io/5742866',
  },
};
