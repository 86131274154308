import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SentryErrorHandlerService {
  constructor() {
    const { sentry } = environment;

    if (sentry?.dsn) {
      Sentry.init({
        dsn: sentry?.dsn,
      });
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  captureError(error: any, ...payload: any): void {
    Sentry.captureException({ error, payload: JSON.stringify(payload) });
  }
}
