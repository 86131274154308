import { Serializable } from 'app/core/interfaces';

export class ExpectationDTO {
  id: string;
  period?: string;
  categoryId: string;
  amount: number;
  adjustment: number;
  annualImpact?: boolean; // new
  isScheduled?: boolean; // new
  isFixed?: boolean; // new
  description?: string;
}

export class Expectation implements Serializable<Expectation> {
  id?: string;
  periodYear: number;
  periodMonth: number;
  categoryId: string;
  amount: number;
  adjustment?: number;
  previousAmount?: number;
  previousAdjustment?: number;
  hasErrorAmount?: boolean;
  hasErrorAdjustment?: boolean;
  annualImpact?: boolean;
  isScheduled?: boolean;
  isFixed?: boolean;
  description?: string;

  constructor(expectation?: Expectation) {
    if (!expectation) {
      return;
    }

    this.id = expectation.id;
    this.periodYear = expectation.periodYear;
    this.periodMonth = expectation.periodMonth;
    this.categoryId = expectation.categoryId;
    this.amount = expectation.amount;
    this.adjustment = expectation.adjustment;
    this.previousAmount = expectation.previousAmount;
    this.previousAdjustment = expectation.previousAdjustment;
    this.hasErrorAmount = expectation.hasErrorAmount;
    this.hasErrorAdjustment = expectation.hasErrorAdjustment;
    this.annualImpact = expectation.annualImpact;
    this.isScheduled = expectation.isScheduled;
    this.isFixed = expectation.isFixed;
    this.description = expectation.description;
  }

  deserialize(input: ExpectationDTO): Expectation {
    if (!input) {
      return this;
    }

    this.id = input.id || '';
    const periodSplit = input.period.split('_');
    this.periodYear = parseInt(periodSplit[0], 10) || 0;
    this.periodMonth = periodSplit[1] === 'all' ? -1 : parseInt(periodSplit[1], 10) || 0; // _all month -1 || 13
    this.categoryId = input.categoryId || '';
    this.amount = input.amount || 0;
    this.adjustment = input.adjustment || 0;
    this.previousAmount = this.amount;
    this.previousAdjustment = this.adjustment;
    this.hasErrorAmount = false;
    this.hasErrorAdjustment = false;
    this.annualImpact = input.annualImpact || false;
    this.isScheduled = input.isScheduled;
    this.isFixed = input.isFixed;
    this.description = input.description;

    return this;
  }

  deserializeArray(inputArray: ExpectationDTO[]): Expectation[] {
    const expectations: Array<Expectation> = inputArray.map((input) => new Expectation().deserialize(input));

    return expectations;
  }

  clone(): Expectation {
    const clonedInstance: Expectation = Object.assign(Object.create(Object.getPrototypeOf(this)), this);

    return clonedInstance;
  }
}
