import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PeriodType, StateType, Subscription } from 'app/shared/models';

@Component({
  selector: 'app-subscription',
  templateUrl: './app-subscription.component.html',
  styleUrls: ['./app-subscription.component.scss'],
})
export class SubscriptionComponent implements OnInit, OnChanges {
  public PRICE_PER_YEAR = 127;
  public PRICE_PER_MONTH = 17;

  @Input() typePlan: PeriodType;
  @Input() paymentSubscriptionType: PeriodType;
  @Input() subscription: Subscription;
  @Output() planSelected: EventEmitter<PeriodType> = new EventEmitter<PeriodType>();

  public chooseSubscription = true;

  public paramPricePerYear = { value: this.PRICE_PER_YEAR };
  public paramPricePerMonth = { value: this.PRICE_PER_MONTH };
  public paramDate;

  form: FormGroup;
  planPerYear: FormControl;

  PeriodType = PeriodType;
  StateType = StateType;

  subscriptionActive: boolean;

  descriptionPlan: string;
  stateSubscription: string;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.subscription && changes.subscription.currentValue) {
      this.generateDescriptionPlan(this.subscription);
    }
  }

  generateDescriptionPlan(subscription: Subscription): void {
    if (subscription.isCustom) {
      if (subscription.subscriptionExpired) {
        this.subscriptionActive = false;
        this.stateSubscription = 'Expired';
        this.descriptionPlan = 'Description_subscription_expired';
      } else if (subscription.isValid) {
        this.subscriptionActive = true;
        this.stateSubscription = 'Active';
        this.descriptionPlan = 'Description_invitation_active';
      } else if (subscription.isInactive) {
        this.subscriptionActive = false;
        this.stateSubscription = 'Canceled';
      }
    } else if (subscription.isPayment) {
      if (subscription.isInactive) {
        this.subscriptionActive = false;
        this.paramDate = { date: subscription.periodEnd.toLocaleDateString() };
        switch (subscription.status) {
          case StateType.CANCELED:
            this.stateSubscription = 'Canceled';
            this.descriptionPlan = 'Description_subscription_canceled';
            break;
          case StateType.UNPAID:
            this.stateSubscription = 'Unpaid';
            this.descriptionPlan = 'Description_subscription_past_due_and_unpaid';
            break;
          case StateType.PAST_DUE:
            this.stateSubscription = 'Past due';
            this.descriptionPlan = 'Description_subscription_past_due_and_unpaid';
            break;
          default:
            this.stateSubscription = 'Expired';
            this.descriptionPlan = 'Description_subscription_expired_date';
            break;
        }
      } else if (subscription.cancelAtPeriodEnd) {
        this.subscriptionActive = false;
        this.stateSubscription = 'Canceled';
        this.descriptionPlan = 'Description_subscription_canceled_date';
        this.paramDate = { date: subscription.periodEnd.toLocaleDateString() };
      } else if (subscription.isIncomplete) {
        this.subscriptionActive = false;
        this.stateSubscription = 'Pending';
        this.descriptionPlan = 'Description_subscription_pending';
      } else if (subscription.isValid) {
        this.subscriptionActive = true;
        this.stateSubscription = 'Active';
        this.descriptionPlan = '';
      }
    } else {
      this.subscriptionActive = false;
      this.stateSubscription = subscription.status;
      this.descriptionPlan = 'Description_subscription_unknown';
      // console.error('this error should not occur');
    }
  }

  ngOnInit(): void {
    const isAnnual = this.paymentSubscriptionType && this.paymentSubscriptionType === PeriodType.ANNUAL;
    this.planPerYear = new FormControl(isAnnual, [Validators.required.bind(this)]);
    this.form = new FormGroup({
      planPerYear: this.planPerYear,
    });
    if (this.typePlan) {
      this.chooseSubscription = false;
    }

    this.planSelected.emit(this.planPerYear.value ? PeriodType.ANNUAL : PeriodType.MONTHLY);

    this.planPerYear.valueChanges.subscribe((value: boolean) =>
      this.planSelected.emit(value ? PeriodType.ANNUAL : PeriodType.MONTHLY),
    );
  }

  showSubscription(typePlan: PeriodType): boolean {
    if (
      (this.chooseSubscription && typePlan !== PeriodType.CUSTOM) ||
      (!this.chooseSubscription && this.typePlan === typePlan)
    ) {
      return true;
    }
    return false;
  }
}
