import { PlanSpendingPlan } from './spendingPlan';
import { PaymentAccount } from './subscription';
import { User } from './user';

export class Session {
  token: string;
  user: User;
  subscription: PaymentAccount;
  admin?: User;
  adminToken?: string;
  planSpendingPlan?: PlanSpendingPlan;
}
