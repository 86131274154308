import { Serializable } from 'app/core/interfaces';
import { Expectation } from './expectation';
import { SubCategory } from './subCategory';

export interface PeriodicExpectationDTO {
  id?: string;
  year: number;
  categoryId: string;
  description?: string;
}

export enum TypePeriodic {
  INCOME = 'Income',
  EXPENSE = 'Expense',
}

export class RepeatExpectation implements Serializable<RepeatExpectation> {
  type: TypePeriodic;
  expectations?: Expectation[];
  months?: { month: Date; amount: number }[] = [];
  totalMonths?: number;
  category: string;
  subCategoryId?: string;
  subCategory: SubCategory;
  oldSubCategoryId: string;
  id?: string;
  year: number;
  spendingPlanId?: string;
  isPeriodicChecked?: boolean;
  isFixedChecked?: boolean;

  constructor(repeatExpectation?: RepeatExpectation) {
    if (!repeatExpectation) {
      return this;
    }

    this.id = repeatExpectation.id;
    this.type = repeatExpectation.type;
    this.expectations = repeatExpectation.expectations;
    this.months = repeatExpectation.months;
    this.totalMonths = repeatExpectation.totalMonths;
    this.category = repeatExpectation.category;
    this.subCategoryId = repeatExpectation.subCategoryId;
    this.subCategory = repeatExpectation.subCategory;
    this.oldSubCategoryId = this.subCategory?.id;
    this.year = repeatExpectation.year;
    this.spendingPlanId = repeatExpectation.spendingPlanId;
    this.isPeriodicChecked = repeatExpectation.isPeriodicChecked;
    this.isFixedChecked = repeatExpectation.isFixedChecked;
  }

  deserialize(input: PeriodicExpectationDTO): RepeatExpectation {
    if (!input) {
      return this;
    }

    this.id = input.id || '';
    this.year = input.year;
    this.subCategoryId = input.categoryId;

    return this;
  }

  deserializeArray(inputArray: PeriodicExpectationDTO[]): RepeatExpectation[] {
    const expectations: Array<RepeatExpectation> = inputArray.map((input) =>
      new RepeatExpectation().deserialize(input),
    );

    return expectations;
  }

  get isSubCategoryChanged(): boolean {
    return this.subCategory.id !== this.oldSubCategoryId;
  }
}
