import { AuthInfo, UserType } from 'app/modules/auth/models';
import { ClientRoleType, UserDTO } from '../models';

export const USER_MOCK: UserDTO = {
  auth: new AuthInfo().deserialize({
    id: 'neao987utr4b879240rmaoe',
    email: 'rtarre@nakima.es',
    username: 'rtarre',
    isEmailValidated: true,
    role: UserType.CLIENT,
  }),
  profile: {
    authId: 'neao987utr4b879240rmaoe',
    username: 'rtarre',
    userPlan: ClientRoleType.PERSONAL,

    avatar: '',
    localeLanguage: 'en',
    firstName: 'Robert',
    lastName: 'Tarre',
    telephone: '123123123',

    country: 'spain',
    state: 'Catalunya',
    city: 'Barcelona',
    zipCode: '08029',
  },
};
