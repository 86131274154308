import { Serializable } from 'app/core/interfaces';

export enum ClientRoleType {
  PERSONAL = 'personal',
  BUSINESS = 'business',
}

export class ProfileDTO {
  authId?: string;
  username: string;
  userPlan: ClientRoleType;

  avatar?: string;
  localeLanguage: string;
  firstName: string;
  lastName: string;
  telephone: string;

  country: string;
  state: string;
  city: string;
  zipCode: string;
}

export class Profile implements Serializable<Profile> {
  id: string;
  username: string;
  accountPlan: ClientRoleType;

  avatar: string;
  localeLanguage: string;
  firstName: string;
  lastName: string;
  telephone: string;

  country: string;
  state: string;
  city: string;
  zipCode: string;

  serialize(): ProfileDTO {
    return {
      authId: this.id,
      username: this.username,
      userPlan: this.accountPlan,

      avatar: this.avatar,
      localeLanguage: this.localeLanguage,
      firstName: this.firstName,
      lastName: this.lastName,
      telephone: this.telephone,

      country: this.country,
      state: this.state,
      city: this.city,
      zipCode: this.zipCode,
    };
  }

  deserialize(input: ProfileDTO): Profile {
    if (!input) {
      return this;
    }
    this.id = input.authId || '';
    this.username = input.username || '';

    this.avatar = input.avatar || '';
    this.localeLanguage = input.localeLanguage || '';
    this.firstName = input.firstName || '';
    this.lastName = input.lastName || '';
    this.telephone = input.telephone || '';

    this.country = input.country || '';
    this.state = input.state || '';
    this.city = input.city || '';
    this.zipCode = input.zipCode || '';

    this.accountPlan = input.userPlan;

    return this;
  }

  deserializeArray(inputArray: Array<ProfileDTO>): Array<Profile> {
    const profilesArray: Array<Profile> = inputArray.map((input) => new Profile().deserialize(input));

    return profilesArray;
  }
}
