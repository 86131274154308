import { CategoryType, SubCategoryDTO } from 'app/shared/models';

export const CATEGORIES_ALL_MOCK: SubCategoryDTO[] = [
  {
    id: '1',
    name: 'Personal / Spiritual Growth',
    description: '',
    section: '',
    type: CategoryType.MONEY_IN,
    hiddenPivotYear: 2021,
  },
  {
    id: '2',
    name: 'Home',
    description: '',
    section: '',
    type: CategoryType.MONEY_IN,
    hiddenPivotYear: 2021,
  },
  {
    id: '3',
    name: 'Food',
    description: '',
    section: '',
    type: CategoryType.MONEY_IN,
    hiddenPivotYear: 2021,
  },
];

export const SUBCATEGORY_MOCK = CATEGORIES_ALL_MOCK[0];
