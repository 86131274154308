import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';

import { BehaviorSubject } from 'rxjs';
import {
  HttpClient,
  //  HttpHeaders,
} from '@angular/common/http';
import { Account, AccountDTO } from '../models';
import {
  AccountResponseI,
  // AccountResponseI,
  AccountsResponseI,
} from '../interfaces';
import { TIED_ACCOUNTS } from '../mocks/accounts-mocks';

// const httpOptions = {
//   headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
// };

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  private readonly BANK_ACCTS_LIMIT = 25;
  private readonly apiUrl: string = environment.apiUrl;

  private readonly accountSubject = new BehaviorSubject<Account>(null);
  readonly currentAccount$ = this.accountSubject.asObservable();

  private readonly reloadAccountsSubject = new BehaviorSubject<boolean>(false);
  readonly reloadAccounts$ = this.reloadAccountsSubject.asObservable();

  get reloadAccounts(): boolean {
    return this.reloadAccountsSubject.getValue();
  }

  set reloadAccounts(account: boolean) {
    this.reloadAccountsSubject.next(account);
  }

  get currentAccount(): Account {
    return this.accountSubject.getValue();
  }

  set currentAccount(account: Account) {
    this.accountSubject.next(account);
  }

  constructor(private http: HttpClient) {}

  async getAccounts(): Promise<Array<Account>> {
    let accounts: Array<Account> = [];
    if (environment.useMocks) {
      accounts = new Account().deserializeArray(TIED_ACCOUNTS);
    } else {
      const url = `${this.apiUrl}/client/accounting/me/bankAccts/`;
      const response: AccountsResponseI = await this.http.get<AccountsResponseI>(url).toPromise();
      accounts = new Account().deserializeArray(response.bankAccts);
    }

    return accounts;
  }

  async getAccountsByIds(bankAccts: string[]): Promise<Array<Account>> {
    let accounts: Array<Account> = [];
    if (environment.useMocks) {
      accounts = new Account().deserializeArray(TIED_ACCOUNTS);
    } else {
      const url = `${this.apiUrl}/client/accounting/me/bankAccts/getByArray`;
      const calls = Math.ceil(bankAccts.length / this.BANK_ACCTS_LIMIT);
      const newArray = [];
      for (let i = 0; i < calls; i += 1) {
        newArray.push(bankAccts.slice(i * this.BANK_ACCTS_LIMIT, (i + 1) * this.BANK_ACCTS_LIMIT));
      }
      await Promise.all(
        newArray.map(async (arrayAccts) => {
          const response: AccountsResponseI = await this.http
            .post<AccountsResponseI>(url, { bankAccts: arrayAccts })
            .toPromise();
          accounts.push(...new Account().deserializeArray(response.bankAccts));
        }),
      );
    }
    return accounts;
  }

  async getAccount(accountId: string): Promise<Account> {
    let account: Account = null;

    try {
      const accounts = await this.getAccountsByIds([accountId]);
      [account] = accounts;
    } catch (error) {
      // TODO: Handle error
    }

    return account;
  }

  async createNewAccount(newAccountData: Account): Promise<Account> {
    let newAccountFetched: Account = null;
    if (environment.useMocks) {
      newAccountFetched = new Account().deserialize(TIED_ACCOUNTS[0]);
    } else {
      const accountDTO = newAccountData.serialize();

      const url = `${this.apiUrl}/client/accounting/me/bankAccts/`;
      const response: AccountResponseI = await this.http.post<AccountResponseI>(url, accountDTO).toPromise();
      newAccountFetched = new Account().deserialize(response.bankAcct);
      // this.currentAccount = newAccountFetched;
    }
    return newAccountFetched;
  }

  async saveAccountSettings(account: Account): Promise<Account> {
    // console.log('saveAccountSettings', { account });
    const url = `${this.apiUrl}/client/accounting/me/bankAccts/${account.id}`;
    // console.log('saveAccountSettings :: 1');
    const accountDTO = account.serialize();
    // console.log('saveAccountSettings :: 2');
    const response: AccountResponseI = await this.http.put<AccountResponseI>(url, accountDTO).toPromise();
    // console.log('saveAccountSettings :: 3');
    const accountEdited = new Account().deserialize(response.bankAcct);
    // console.log('saveAccountSettings :: 4');
    // this.currentAccount = accountEdited;
    // console.log('saveAccountSettings :: 5');

    // console.log('saveAccountSettings :: finished');

    return accountEdited;
  }

  async refreshPostedBalance(account: Account): Promise<void> {
    if (!environment.useMocks) {
      const url = `${this.apiUrl}/client/accounting/me/bankAccts/${account.id}/refreshBalance/`;
      await this.http.post<{ bankAcct: AccountDTO }>(url, {}).toPromise();
    }
  }

  async tieAccount(accountId: string, spendingPlanId: string): Promise<Account> {
    let accountEdited: Account = null;
    if (!environment.useMocks) {
      const url = `${this.apiUrl}/client/spendingPlans/${spendingPlanId}/tieAccnt/`;
      const response: AccountResponseI = await this.http
        .post<AccountResponseI>(url, { accountId })
        .toPromise();
      accountEdited = new Account().deserialize(response.bankAcct);
    }
    return accountEdited;
  }

  async untieAccount(accountId: string, spendingPlanId: string): Promise<Account> {
    let accountEdited: Account = null;

    if (!environment.useMocks) {
      const url = `${this.apiUrl}/client/spendingPlans/${spendingPlanId}/untieAccnt/`;
      const response: AccountResponseI = await this.http
        .post<AccountResponseI>(url, { accountId })
        .toPromise();
      accountEdited = new Account().deserialize(response.bankAcct);
    }

    return accountEdited;
  }

  async closeAccount(account: Account): Promise<Account> {
    let accountClose: Account = null;

    if (!environment.useMocks) {
      const url = `${this.apiUrl}/client/accounting/me/bankAccts/${account.id}/lock/`;
      const response: AccountResponseI = await this.http.post<AccountResponseI>(url, account).toPromise();

      accountClose = new Account().deserialize(response.bankAcct);
    }

    return accountClose;
  }

  async deleteAccount(account: Account): Promise<void> {
    const url = `${this.apiUrl}/client/accounting/me/bankAccts/${account.id}/`;
    return this.http.delete<void>(url).toPromise();
  }

  async uncloseAccount(account: Account): Promise<Account> {
    let accountClose: Account = null;

    if (!environment.useMocks) {
      const url = `${this.apiUrl}/client/accounting/me/bankAccts/${account.id}/unlock/`;
      const response: AccountResponseI = await this.http.post<AccountResponseI>(url, account).toPromise();

      accountClose = new Account().deserialize(response.bankAcct);
    }

    return accountClose;
  }
}
