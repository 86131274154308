import { FrequencyTypes, TransactionDTO, TransactionStatusType } from 'app/shared/models';
import { TransactionResponseAPII, ScheduledTransactionResponseAPII } from '../interfaces/transaction.interfaces';

export const TRANSACTIONS_FROM_API: TransactionDTO[] = [
  {
    id: '1-1',
    accountName: 'Bank of america',
    date: '2020-12-03',
    payee: '',
    action: 'Money In',
    draftState: TransactionStatusType.ACCEPTED,
    in: 3000,
    out: 0,
    balance: 3000,
  },
  {
    id: '1-2',
    accountName: 'Bank of america',
    date: '2020-12-03',
    payee: 'Wholefoods',
    action: 'Money Out',
    draftState: TransactionStatusType.ACCEPTED,
    in: 0,
    out: 30,
    balance: 2970,
  },
  {
    id: '1-3',
    accountName: 'Bank of america',
    date: '2020-12-03',
    payee: 'Wholefoods',
    action: 'Money Out',
    draftState: TransactionStatusType.ACCEPTED,
    in: 0,
    out: 30,
    balance: 2940,
  },
  {
    id: '1-4',
    date: '2020-12-03',
    accountName: 'Bank of america',
    payee: 'Zara',
    action: 'Money Out',
    draftState: TransactionStatusType.ACCEPTED,
    in: 0,
    out: 300,
    balance: 2640,
  },
  {
    id: '1-5',
    date: '2020-12-03',
    accountName: 'Bank of america',
    payee: 'Glossier',
    action: 'Money Out',
    draftState: TransactionStatusType.ACCEPTED,
    in: 0,
    out: 300,
    balance: 2340,
  },
  {
    id: '1-6',
    date: '2020-12-03',
    accountName: 'Bank of america',
    payee: 'Salary',
    action: 'Money Out',
    draftState: TransactionStatusType.ACCEPTED,
    in: 0,
    out: 200,
    balance: 2140,
  },
  {
    id: '1-7',
    date: '2020-12-03',
    accountName: 'Bank of america',
    payee: 'Wholefoods',
    action: 'Money Out',
    draftState: TransactionStatusType.ACCEPTED,
    in: 0,
    out: 50,
    balance: 2090,
  },
  {
    id: '1-8',
    date: '2020-12-03',
    accountName: 'Bank of america',
    payee: 'Wholefoods',
    action: 'Money Out',
    draftState: TransactionStatusType.ACCEPTED,
    in: 0,
    out: 100,
    balance: 1990,
  },
  {
    id: '1-9',
    date: '2020-12-03',
    accountName: 'Bank of america',
    payee: 'Zara',
    action: 'Money Out',
    draftState: TransactionStatusType.ACCEPTED,
    in: 0,
    out: 150,
    balance: 1840,
  },
  {
    id: '1-10',
    date: '2020-12-03',
    accountName: 'Bank of america',
    payee: 'Glossier',
    action: 'Money Out',
    draftState: TransactionStatusType.ACCEPTED,
    in: 0,
    out: 40,
    balance: 1800,
  },
  {
    id: '1-11',
    date: '2020-12-03',
    accountName: 'Bank of america',
    payee: 'Salary',
    action: 'Money Out',
    draftState: TransactionStatusType.ACCEPTED,
    in: 0,
    out: 50,
    balance: 1750,
  },
  {
    id: '1-12',
    date: '2020-12-03',
    accountName: 'Bank of america',
    payee: 'Wholefoods',
    action: 'Money Out',
    draftState: TransactionStatusType.ACCEPTED,
    in: 0,
    out: 50,
    balance: 1700,
  },
  {
    id: '1-13',
    date: '2020-12-03',
    accountName: 'Bank of america',
    payee: 'Wholefoods',
    action: 'Money Out',
    draftState: TransactionStatusType.ACCEPTED,
    in: 0,
    out: 40,
    balance: 1660,
  },
  {
    id: '1-14',
    date: '2020-12-03',
    accountName: 'Bank of america',
    payee: 'Zara',
    action: 'Money Out',
    draftState: TransactionStatusType.ACCEPTED,
    in: 0,
    out: 60,
    balance: 1600,
  },
  {
    id: '1-15',
    date: '2020-12-03',
    accountName: 'Bank of america',
    payee: 'Glossier',
    action: 'Money Out',
    draftState: TransactionStatusType.ACCEPTED,
    in: 0,
    out: 100,
    balance: 1500,
  },
  {
    id: '1-16',
    date: '2020-12-03',
    accountName: 'Bank of america',
    payee: 'Salary',
    action: 'Money Out',
    draftState: TransactionStatusType.ACCEPTED,
    in: 0,
    out: 15,
    balance: 1485,
  },
  {
    id: '1-17',
    date: '2020-12-03',
    accountName: 'Bank of america',
    payee: 'Wholefoods',
    action: 'Money Out',
    draftState: TransactionStatusType.ACCEPTED,
    in: 0,
    out: 100,
    balance: 1385,
  },
  {
    id: '1-18',
    date: '2020-12-03',
    accountName: 'Bank of america',
    payee: 'Glossier',
    action: 'Money Out',
    draftState: TransactionStatusType.ACCEPTED,
    in: 0,
    out: 100,
    balance: 1385,
  },
  {
    id: '1-19',
    date: '2020-12-03',
    accountName: 'Bank of america',
    payee: 'Glossier',
    action: 'Money Out',
    draftState: TransactionStatusType.ACCEPTED,
    in: 0,
    out: 100,
    balance: 1385,
  },
  {
    id: '1-20',
    date: '2020-12-03',
    accountName: 'Bank of america',
    payee: 'Glossier',
    action: 'Money Out',
    draftState: TransactionStatusType.ACCEPTED,
    in: 0,
    out: 100,
    balance: 1385,
  },
  {
    id: '1-21',
    date: '2020-12-03',
    accountName: 'Bank of america',
    payee: 'Glossier',
    action: 'Money Out',
    draftState: TransactionStatusType.ACCEPTED,
    in: 0,
    out: 100,
    balance: 1385,
  },
  {
    id: '1-22',
    date: '2020-12-03',
    accountName: 'Bank of america',
    payee: 'Glossier',
    action: 'Money Out',
    draftState: TransactionStatusType.ACCEPTED,
    in: 0,
    out: 100,
    balance: 1385,
  },
  {
    id: '1-23',
    date: '2020-12-03',
    accountName: 'Bank of america',
    payee: 'Glossier',
    action: 'Money Out',
    draftState: TransactionStatusType.PENDING,
    in: 0,
    out: 100,
    balance: 1385,
  },
  {
    id: '1-24',
    date: '2020-12-03',
    accountName: 'Bank of america',
    payee: 'Glossier',
    action: 'Money Out',
    draftState: TransactionStatusType.PENDING,
    in: 0,
    out: 100,
    balance: 1385,
  },
  {
    id: '1-25',
    date: '2020-12-03',
    accountName: 'Bank of america',
    payee: 'Apple',
    action: 'Money Out',
    draftState: TransactionStatusType.PENDING,
    in: 0,
    out: 100,
    balance: 1385,
  },
  {
    id: '1-26',
    date: '2020-12-03',
    accountName: 'Bank of america',
    payee: 'Google',
    action: 'Money Out',
    draftState: TransactionStatusType.PENDING,
    in: 0,
    out: 100,
    balance: 1385,
  },
  {
    id: '1-27',
    date: '2020-12-03',
    accountName: 'Bank of america',
    payee: 'Microsoft',
    action: 'Money Out',
    draftState: TransactionStatusType.PENDING,
    in: 0,
    out: 100,
    balance: 1385,
  },
  {
    id: '1-28',
    date: '2020-12-03',
    accountName: 'Bank of america',
    payee: 'Zara',
    action: 'Money Out',
    draftState: TransactionStatusType.PENDING,
    in: 0,
    out: 100,
    balance: 1385,
  },
  {
    id: '1-29',
    date: '2020-12-03',
    accountName: 'Bank of america',
    payee: 'Wholefoods',
    action: 'Money Out',
    draftState: TransactionStatusType.PENDING,
    in: 0,
    out: 100,
    balance: 1385,
  },
];

export const TRANSACTIONS_ALL: TransactionResponseAPII = {
  transactions: TRANSACTIONS_FROM_API,
  info: {
    count: TRANSACTIONS_FROM_API.length,
    offset: '0',
    limit: '9999',
  },
};

export const SCHEDULED_TRANSACTIONS_FROM_API: TransactionDTO[] = [
  {
    id: '1-1',
    date: '2020-12-03',
    payee: '',
    action: 'Money Out',
    draftState: TransactionStatusType.ACCEPTED,
    in: 3000,
    out: 0,
    frequency: FrequencyTypes.DAILY,
  },
  {
    id: '1-2',
    date: '2020-12-03',
    payee: 'Wholefoods',
    action: 'Money Out',
    draftState: TransactionStatusType.ACCEPTED,
    in: 0,
    out: 30,
    frequency: FrequencyTypes.DAILY,
  },
  {
    id: '1-3',
    date: '2020-12-03',
    payee: 'Wholefoods',
    action: 'Money Out',
    draftState: TransactionStatusType.ACCEPTED,
    in: 0,
    out: 30,
    frequency: FrequencyTypes.DAILY,
  },
  {
    id: '1-4',
    date: '2020-12-03',
    payee: 'Zara',
    action: 'Money Out',
    draftState: TransactionStatusType.ACCEPTED,
    in: 0,
    out: 300,
    frequency: FrequencyTypes.DAILY,
  },
  {
    id: '1-5',
    date: '2020-12-03',
    payee: 'Glossier',
    action: 'Money Out',
    draftState: TransactionStatusType.ACCEPTED,
    in: 0,
    out: 300,
    frequency: FrequencyTypes.DAILY,
  },
  // {
  //   id: '1-6',
  //   date: '2020-12-03',
  //   payee: 'Salary',
  // action: 'Money Out',

  //   draftState: TransactionStatusType.ACCEPTED,
  //   in: 0,
  //   out: 200,
  //   balance: 2140,
  //
  // },
  // {
  //   id: '1-7',
  //   date: '2020-12-03',
  //   payee: 'Wholefoods',
  // action: 'Money Out',

  //   draftState: TransactionStatusType.ACCEPTED,
  //   in: 0,
  //   out: 50,
  //   balance: 2090,
  //
  // },
  // {
  //   id: '1-8',
  //   date: '2020-12-03',
  //   payee: 'Wholefoods',
  // action: 'Money Out',

  //   draftState: TransactionStatusType.ACCEPTED,
  //   in: 0,
  //   out: 100,
  //   balance: 1990,
  //
  // },
  // {
  //   id: '1-9',
  //   date: '2020-12-03',
  //   payee: 'Zara',
  // action: 'Money Out',

  //   draftState: TransactionStatusType.ACCEPTED,
  //   in: 0,
  //   out: 150,
  //   balance: 1840,
  //
  // },
  // {
  //   id: '1-10',
  //   date: '2020-12-03',
  //   payee: 'Glossier',
  // action: 'Money Out',

  //   draftState: TransactionStatusType.ACCEPTED,
  //   in: 0,
  //   out: 40,
  //   balance: 1800,
  //
  // },
  // {
  //   id: '1-11',
  //   date: '2020-12-03',
  //   payee: 'Salary',
  // action: 'Money Out',

  //   draftState: TransactionStatusType.ACCEPTED,
  //   in: 0,
  //   out: 50,
  //   balance: 1750,
  //
  // },
  // {
  //   id: '1-12',
  //   date: '2020-12-03',
  //   payee: 'Wholefoods',
  // action: 'Money Out',

  //   draftState: TransactionStatusType.ACCEPTED,
  //   in: 0,
  //   out: 50,
  //   balance: 1700,
  //
  // },
  // {
  //   id: '1-13',
  //   date: '2020-12-03',
  //   payee: 'Wholefoods',
  // action: 'Money Out',

  //   draftState: TransactionStatusType.ACCEPTED,
  //   in: 0,
  //   out: 40,
  //   balance: 1660,
  //
  // },
  // {
  //   id: '1-14',
  //   date: '2020-12-03',
  //   payee: 'Zara',
  // action: 'Money Out',

  //   draftState: TransactionStatusType.ACCEPTED,
  //   in: 0,
  //   out: 60,
  //   balance: 1600,
  //
  // },
  // {
  //   id: '1-15',
  //   date: '2020-12-03',
  //   payee: 'Glossier',
  // action: 'Money Out',

  //   draftState: TransactionStatusType.ACCEPTED,
  //   in: 0,
  //   out: 100,
  //   balance: 1500,
  //
  // },
  // {
  //   id: '1-16',
  //   date: '2020-12-03',
  //   payee: 'Salary',
  // action: 'Money Out',

  //   draftState: TransactionStatusType.ACCEPTED,
  //   in: 0,
  //   out: 15,
  //   balance: 1485,
  //
  // },
  // {
  //   id: '1-17',
  //   date: '2020-12-03',
  //   payee: 'Wholefoods',
  // action: 'Money Out',

  //   draftState: TransactionStatusType.ACCEPTED,
  //   in: 0,
  //   out: 100,
  //   balance: 1385,
  //
  // },
  // {
  //   id: '1-18',
  //   date: '2020-12-03',
  //   payee: 'Glossier',
  // action: 'Money Out',

  //   draftState: TransactionStatusType.ACCEPTED,
  //   in: 0,
  //   out: 100,
  //   balance: 1385,
  //
  // },
  // {
  //   id: '1-19',
  //   date: '2020-12-03',
  //   payee: 'Glossier',
  // action: 'Money Out',

  //   draftState: TransactionStatusType.ACCEPTED,
  //   in: 0,
  //   out: 100,
  //   balance: 1385,
  //
  // },
  // {
  //   id: '1-20',
  //   date: '2020-12-03',
  //   payee: 'Glossier',
  // action: 'Money Out',

  //   draftState: TransactionStatusType.ACCEPTED,
  //   in: 0,
  //   out: 100,
  //   balance: 1385,
  //
  // },
  // {
  //   id: '1-21',
  //   date: '2020-12-03',
  //   payee: 'Glossier',
  // action: 'Money Out',

  //   draftState: TransactionStatusType.ACCEPTED,
  //   in: 0,
  //   out: 100,
  //   balance: 1385,
  //
  // },
  // {
  //   id: '1-22',
  //   date: '2020-12-03',
  //   payee: 'Glossier',
  // action: 'Money Out',

  //   draftState: TransactionStatusType.ACCEPTED,
  //   in: 0,
  //   out: 100,
  //   balance: 1385,
  //
  // },
  // {
  //   id: '1-23',
  //   date: '2020-12-03',
  //   payee: 'Glossier',
  // action: 'Money Out',

  //   draftState: TransactionStatusType.PENDING,
  //   in: 0,
  //   out: 100,
  //   balance: 1385,
  //
  // },
  // {
  //   id: '1-24',
  //   date: '2020-12-03',
  //   payee: 'Glossier',
  // action: 'Money Out',

  //   draftState: TransactionStatusType.PENDING,
  //   in: 0,
  //   out: 100,
  //   balance: 1385,
  //
  // },
  // {
  //   id: '1-25',
  //   date: '2020-12-03',
  //   payee: 'Apple',
  // action: 'Money Out',

  //   draftState: TransactionStatusType.PENDING,
  //   in: 0,
  //   out: 100,
  //   balance: 1385,
  //
  // },
  // {
  //   id: '1-26',
  //   date: '2020-12-03',
  //   payee: 'Google',
  // action: 'Money Out',

  //   draftState: TransactionStatusType.PENDING,
  //   in: 0,
  //   out: 100,
  //   balance: 1385,
  //
  // },
  // {
  //   id: '1-27',
  //   date: '2020-12-03',
  //   payee: 'Microsoft',
  // action: 'Money Out',

  //   draftState: TransactionStatusType.PENDING,
  //   in: 0,
  //   out: 100,
  //   balance: 1385,
  //
  // },
  // {
  //   id: '1-28',
  //   date: '2020-12-03',
  //   payee: 'Zara',
  // action: 'Money Out',

  //   draftState: TransactionStatusType.PENDING,
  //   in: 0,
  //   out: 100,
  //   balance: 1385,
  //
  // },
  // {
  //   id: '1-29',
  //   date: '2020-12-03',
  //   payee: 'Wholefoods',
  // action: 'Money Out',

  //   draftState: TransactionStatusType.PENDING,
  //   in: 0,
  //   out: 100,
  //   balance: 1385,
  //
  // },
];

export const SCHEDULED_TRANSACTIONS_ALL: ScheduledTransactionResponseAPII = {
  scheduledTransactions: SCHEDULED_TRANSACTIONS_FROM_API,
  info: {
    count: SCHEDULED_TRANSACTIONS_FROM_API.length,
    offset: '0',
    limit: '9999',
  },
};
