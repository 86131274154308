import { Serializable } from 'app/core/interfaces';

export type HiddenMapType = { [categoryId: string]: boolean };
export type CategoryHiddenMapType = { [year: number]: HiddenMapType };

export enum CategoryType {
  MONEY_IN = 'IN',
  MONEY_OUT = 'OUT',
}
export interface SubCategoryDTO {
  id: string;
  name: string;
  section: string;
  type: CategoryType;
  description?: string;
  hiddenPivotYear: number;
}

export class SubCategory implements Serializable<SubCategory> {
  id: string;
  name: string;
  section: string;
  type: CategoryType;
  description?: string;
  hiddenPivotYear: number;

  constructor() {
    this.id = '';
    this.name = '';
    this.section = '';
    this.type = null;
    this.description = '';
    this.hiddenPivotYear = null;
  }

  deserialize(input: SubCategoryDTO): SubCategory {
    if (!input) {
      return this;
    }

    this.id = input.id || '';
    this.name = input.name || '';
    this.section = input.section || '';
    this.type = input.type;
    this.description = input.description || '';
    this.hiddenPivotYear = input.hiddenPivotYear;

    return this;
  }

  deserializeArray(inputArray: Array<SubCategoryDTO>): Array<SubCategory> {
    const subCategories: Array<SubCategory> = inputArray.map((input) => new SubCategory().deserialize(input));

    return subCategories;
  }

  formatDate(date: string): string {
    return date.substring(0, 10);
  }

  clone(): SubCategory {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const clonedInstance: SubCategory = Object.assign(Object.create(Object.getPrototypeOf(this)), this);

    return clonedInstance;
  }
}
