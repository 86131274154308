import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { environment } from 'environments/environment';
import { Profile, ProfileDTO, User, Session, PlanSpendingPlan } from 'app/shared/models';
import { HttpClient } from '@angular/common/http';
import { USER_MOCK } from 'app/shared/mocks/index';
import { PaymentAccountDTO, PaymentAccount, Subscription } from 'app/shared/models/subscription';
import { AuthInfo, AuthInfoDTO, UserType } from '../models';

interface LoginResponse {
  auth: AuthInfoDTO;
  token: string;
}

interface SignUpResponse {
  message: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly sessionSubject = new BehaviorSubject<Session>(null);
  readonly session$ = this.sessionSubject.asObservable();

  private readonly sessionKey = environment.sessionKey;
  private readonly apiUrl = environment.apiUrl;

  private localStorageService: Storage;

  constructor(private http: HttpClient) {
    this.localStorageService = localStorage;
    this.session = this.loadSessionData();
  }

  getCurrentSession(): Session {
    return this.sessionSubject.getValue();
  }

  getCurrentSubscription(): Subscription | null {
    const currentSession = this.getCurrentSession();

    if (!currentSession || !currentSession.subscription) {
      return null;
    }

    const lastSubscription = new Subscription(currentSession.subscription.lastSubscription);

    return lastSubscription;
  }

  private set session(session: Session) {
    this.localStorageService.setItem(this.sessionKey, JSON.stringify(session));
    this.sessionSubject.next(session);
  }

  updateUserInfo(profile: Profile): void {
    const session = this.getCurrentSession();

    session.user.username = profile.username;
    session.user.firstName = profile.firstName;
    session.user.lastName = profile.lastName;
    session.user.telephone = profile.telephone;
    session.user.country = profile.country;
    session.user.state = profile.state;
    session.user.city = profile.city;
    session.user.zipCode = profile.zipCode;
    session.user.avatar = profile.avatar;

    this.session = session;
  }

  updateUserSubscription(subscription: Subscription): void {
    const session = this.getCurrentSession();

    session.subscription.lastSubscription = subscription;

    this.session = session;
  }

  updateUserPlanSpendingPlan(plan: PlanSpendingPlan): void {
    const session = this.getCurrentSession();
    if (!session) {
      return;
    }
    session.planSpendingPlan = plan;

    this.session = session;
  }

  updateUserAvatar(avatar: string): void {
    const session = this.getCurrentSession();

    session.user.avatar = avatar;

    this.session = session;
  }

  loadSessionData(): Session | null {
    const sessionStr = this.localStorageService.getItem(this.sessionKey);
    return sessionStr ? (JSON.parse(sessionStr) as Session) : null;
  }

  getCurrentUser(): User | null {
    const session: Session = this.getCurrentSession();
    return session && session.user ? session.user : null;
  }

  // getCurrentToken(): string | null {
  //   const session = this.getCurrentSession();
  //   return session && session.token ? session.token : null;
  // }

  getCurrentToken(): string | null {
    const session = this.getCurrentSession();

    if (!session) {
      return null;
    }

    if (session.token) {
      return session.token;
    }

    if (session.adminToken) {
      return session.adminToken;
    }

    return null;
  }

  isAuthenticated(): boolean {
    return this.getCurrentToken() !== null;
  }

  removeSession(): void {
    this.session = null;
  }

  // private async createUserAuth(auth: AuthInfoDTO): Promise<AuthInfo> {
  //   let authInfo : AuthInfo = null;
  //   const url = `${this.apiUrl}/admin/auth/`;
  //   const userAuthBody = {
  //     email: auth.email,
  //     username: auth.username,
  //     password: auth.password,
  //     userRole: auth.role,
  //   };
  //   const { auth: newAuthDTO } = await this.http.post<{auth: AuthInfoDTO}>(url, userAuthBody).toPromise();
  //   authInfo = new AuthInfo().deserialize(newAuthDTO);
  //   return authInfo;
  // }

  // private async createUserProfile(profile: ProfileDTO, userId: string): Promise<Profile> {
  //   const url = `${this.apiUrl}/admin/users/`;
  //   const response = await this.http.post<UserRespDTOI>(url, { ...profile, authId: userId }).toPromise();
  //   const profileInfo = new Profile().deserialize(response.user);
  //   return profileInfo;
  // }

  // async createUser(userToCreate: User): Promise<User> {
  //   const userDTO = userToCreate.serialize();
  //   let newUser: User;
  //   let newAuth: AuthInfo;
  //   let newProfile: Profile;
  //   if (environment.useMocks) {
  //     newUser = userToCreate;
  //   } else {
  //     newAuth = await this.createUserAuth(userDTO.auth);
  //     if (newAuth) {
  //       newProfile = await this.createUserProfile(userDTO.profile, newAuth.id);
  //     }
  //     newUser = new User().deserialize({ auth: newAuth, profile: newProfile });
  //   }
  //   return newUser;
  // }

  private async getUserProfileWithToken(token: string): Promise<ProfileDTO> {
    const url = `${this.apiUrl}/client/users/me/`;
    const { user: profile } = await this.http
      .get<{ user: ProfileDTO; message: string }>(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .toPromise();
    return profile;
  }

  private async getUserSubscriptionWithToken(token: string): Promise<PaymentAccount | null> {
    const subsUrl = `${this.apiUrl}/client/payments/accounts/me/`;

    try {
      const { paymentAccount: subsResponse } = await this.http
        .get<{ paymentAccount: PaymentAccountDTO }>(subsUrl, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        .toPromise();
      return new PaymentAccount().deserialize(subsResponse);
    } catch (error) {
      return null;
    }
  }

  async refreshUser(): Promise<void> {
    const { token } = this.getCurrentSession();
    if (!token) {
      return;
    }
    const profile: ProfileDTO = await this.getUserProfileWithToken(token);
    this.updateUserInfo(new Profile().deserialize(profile));
  }

  async refreshUserSubscription(): Promise<void> {
    const { token } = this.getCurrentSession();
    if (!token) {
      return;
    }
    const { lastSubscription } = await this.getUserSubscriptionWithToken(token);
    this.updateUserSubscription(lastSubscription);
  }

  async login(email: string, password: string): Promise<Session> {
    let session: Session = null;

    if (environment.useMocks) {
      session = {
        user: new User().deserialize(USER_MOCK),
        token: 'asdasdasdasd',
        subscription: null,
      };
    } else {
      const url = `${this.apiUrl}/public/auth/login/`;

      const response: LoginResponse = await this.http
        .post<LoginResponse>(url, { email, password })
        .toPromise();

      const { auth, token } = response;

      const profile = await this.getUserProfileWithToken(token);
      // const localeLanguage = 'eu';
      // console.log('signUp', { localeLanguage });
      const subscription = await this.getUserSubscriptionWithToken(token);
      session = {
        user: new User().deserialize({
          auth: new AuthInfo().deserialize(auth),
          profile,
          // profile: { ...profile, localeLanguage },
        }),
        token,
        subscription,
      };
    }

    this.session = session;

    return session;
  }

  async signup(newUserInfo: User): Promise<SignUpResponse> {
    let response: SignUpResponse = null;
    if (environment.useMocks) {
      response = {
        message: '',
      };
    } else {
      const url = `${this.apiUrl}/public/auth/signup/`;

      response = await this.http.post<SignUpResponse>(url, newUserInfo).toPromise();
    }

    return response;
  }

  async changePassword(oldPassword: string, newPassword: string): Promise<SignUpResponse> {
    let response: SignUpResponse = null;

    if (environment.useMocks) {
      response = {
        message: '',
      };
    } else {
      const url = `${this.apiUrl}/client/auth/me/psw/`;

      response = await this.http
        .put<SignUpResponse>(url, {
          oldPassword,
          newPassword,
        })
        .toPromise();
    }

    return response;
  }

  async recoverPassword(email: string): Promise<SignUpResponse> {
    let response: SignUpResponse = null;

    if (environment.useMocks) {
      response = {
        message: '',
      };
    } else {
      const url = `${this.apiUrl}/public/auth/psw/recover/`;

      response = await this.http
        .post<SignUpResponse>(url, {
          email,
        })
        .toPromise();
    }

    return response;
  }

  async resetPassword(newPassword: string, recoverToken: string): Promise<SignUpResponse> {
    let response: SignUpResponse = null;

    if (environment.useMocks) {
      response = {
        message: '',
      };
    } else {
      const url = `${this.apiUrl}/public/auth/psw/reset/`;

      response = await this.http
        .post<SignUpResponse>(url, {
          newPassword,
          recoverToken,
        })
        .toPromise();
    }

    return response;
  }

  // #### LOGIN AS ADMIN #######################################################

  async loginAdmin(email: string, password: string): Promise<User> {
    const url = `${this.apiUrl}/public/auth/login/`;

    const { auth, token }: LoginResponse = await this.http
      .post<LoginResponse>(url, { email, password })
      .toPromise();

    const user = new User().deserialize({
      auth: new AuthInfo().deserialize(auth),
      profile: undefined,
    });

    let session = this.getCurrentSession();

    if (session) {
      session.adminToken = token;
      session.admin = user;
    } else {
      session = {
        user: null,
        subscription: null,
        token: null,
        adminToken: token,
        admin: user,
      };
    }

    this.session = session;

    return user;
  }

  async adminSelectUser(user: User): Promise<void> {
    const url = `${this.apiUrl}/admin/auth/${user.id}/login`;
    const { auth, token }: LoginResponse = await this.http.post<LoginResponse>(url, {}).toPromise();

    const session = this.getCurrentSession();
    session.token = token;
    this.session = session;

    const profile = await this.getUserProfileWithToken(token);
    const subscription = await this.getUserSubscriptionWithToken(token);

    const userToLoginWith = new User().deserialize({
      auth: new AuthInfo().deserialize(auth),
      profile,
    });

    session.user = userToLoginWith;
    session.token = token;
    session.subscription = subscription;

    this.session = session;
  }

  async getUsers(offset: number, limit: number, text?: string): Promise<AuthInfo[]> {
    const url = `${this.apiUrl}/admin/auth/`;
    const { auths } = await this.http.get<{ auths: AuthInfoDTO[] }>(url).toPromise();

    const usersFetched: Array<AuthInfoDTO> = auths
      .filter((a) => a.role === UserType.CLIENT)
      .filter((a) => a.email.includes(text));

    return new AuthInfo().deserializeArray(usersFetched);
    // const allUsers: Array<AuthInfo> = new AuthInfo().deserializeArray(response.auths)
    //   .filter((auth: AuthInfo) => {
    //     if (!filters.text) {
    //       return true;
    //     }

    //     return auth.email.includes(filters.text) || auth.username.includes(filters.text);
    //   });

    // usersFetched = allUsers.slice(offset, offset + limit);

    //   infoResponse = {
    //     count: allUsers.length,
    //     offset: offset.toString(),
    //     limit: limit.toString(),
    //   };
    // }

    // return {
    //   users: usersFetched,
    //   info: infoResponse,
    // };
  }

  isAdminLogged(): boolean {
    const session = this.getCurrentSession();

    return session && session.adminToken && session.adminToken !== null && session.adminToken !== '';
  }

  removeSelectedUser(): void {
    const session = this.getCurrentSession();

    if (session) {
      session.user = null;
      session.subscription = null;
      session.token = null;
    }

    this.session = session;
  }

  logout(): void {
    this.removeSession();
  }
}
