import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { AuthService } from 'app/modules/auth/services';

@Injectable({
  providedIn: 'root',
})
export class DateFormatService {
  constructor(private authService: AuthService) {}

  get locale(): string {
    return this.authService.getCurrentSession().user.localeLanguage;
  }

  get formatString(): string {
    const { locale } = this;
    let format = 'MM/dd/yyyy';

    if (locale === 'eu') {
      format = 'dd/MM/yyyy';
    }

    return format;
  }

  transformDate(value: string): string | null {
    const { locale, formatString: format } = this;

    const dateFormated = formatDate(value, format, locale);
    return dateFormated;
  }

  transformDateToInputValue(value: string): string | null {
    // The HTML5 date input specification refers to the RFC 3339 specification,
    // which specifies a full-date format equal to: yyyy-mm-dd.
    const format = 'yyyy-MM-dd';

    const dateFormated = formatDate(value, format, this.locale);
    return dateFormated;
  }
}
