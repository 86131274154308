import { formatCurrency, getCurrencySymbol } from '@angular/common';
import { Injectable } from '@angular/core';
import CurrencyCodes, { CurrencyCodeRecord } from 'currency-codes';

@Injectable({
  providedIn: 'root',
})
export class CurrencyService {
  USDCurrency = 'USD';
  // 'USD' is an available currency, but we want to put it at first of the list
  currenciesAvailable = ['CAD', 'NZD', 'AUD', 'GBP', 'EUR', 'SEK', 'SGD', 'CHF', 'ILS', 'TRY', 'COP', 'ALL'];
  currencySymbol = {
    CHF: '₣', // Fr
    ALL: 'L',
  };

  currenciesLocaleEU = ['EUR', 'SEK', 'CHF', 'ALL']; // to add symbol currency after number

  constructor() {}

  get getAllCurrencies(): CurrencyCodeRecord[] {
    const currencies = CurrencyCodes.data.filter((value: CurrencyCodeRecord) =>
      this.currenciesAvailable.includes(value.code),
    );
    const findUSDCurrency = CurrencyCodes.code(this.USDCurrency);
    currencies.sort(({ currency: currencyA }, { currency: currencyB }) => {
      if (currencyA < currencyB) {
        return -1;
      }
      if (currencyA > currencyB) {
        return 1;
      }
      return 0;
    });
    return [findUSDCurrency, ...currencies];
  }

  getCode(code: string): string {
    return CurrencyCodes.code(code).code;
  }

  getSymbol(code: string, format: 'wide' | 'narrow' = 'narrow', locale?: string): string {
    return this.currencySymbol[code] || getCurrencySymbol(code, format, locale);
  }

  getFormat(value: number, locale: string, currency: string = '', currencyCode?: string): string {
    const currencyLocale = this.currenciesLocaleEU.includes(currencyCode) ? 'eu' : locale;
    return formatCurrency(value, currencyLocale, currency, currencyCode);
  }
}
