import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appSidebarToggler]',
})
export class SidebarToggleDirective {
  constructor() {}

  @HostListener('click', ['$event'])
  toggleOpen($event: Event): void {
    $event.preventDefault();

    document.querySelector('.layout').classList.toggle('show-sidebar');
    // document.querySelector('.sidebar-toggler-open').classList.toggle('show');
    // document.querySelector('.sidebar-toggler-close').classList.toggle('show');
  }
}
