import { Serializable } from 'app/core/interfaces';

export enum CurrencyPlacementTypes {
  BEFORE = 'before',
  AFTER = 'after',
}

export enum NumericFormatTypes {
  '1.000,00' = 'eu',
  '1,000.00' = 'us',
}

export enum DateFormatTypes {
  'mm/dd/yyyy' = 'us',
  'dd/mm/yyyy' = 'eu',
}

export class FormatTypesDTO {
  dateFormat: DateFormatTypes;
  numericFormat: NumericFormatTypes;
  currencyPlacement: CurrencyPlacementTypes;
}

export class FormatTypes implements Serializable<FormatTypes> {
  dateFormat: DateFormatTypes;
  numericFormat: NumericFormatTypes;
  currencyPlacement: CurrencyPlacementTypes;

  deserialize(input: FormatTypesDTO): FormatTypes {
    if (!input) {
      return this;
    }

    this.currencyPlacement = input.currencyPlacement || CurrencyPlacementTypes.BEFORE;
    this.dateFormat = input.dateFormat || DateFormatTypes['mm/dd/yyyy'];
    this.numericFormat = input.numericFormat || NumericFormatTypes['1,000.00'];

    return this;
  }

  deserializeArray(inputArray: FormatTypesDTO[]): FormatTypes[] {
    const formatTypes: Array<FormatTypes> = inputArray.map((input) => new FormatTypes().deserialize(input));

    return formatTypes;
  }
}
